import { User } from "../models/user";
import { VitovaActivityStep, VitovaStepType } from "../models/vitovaPlan";
import { getPaceFromRpe, getPaceFromStep, getThresholdPace, metersPerSecondToMinPerKmString } from "./StressLoadFunctions";

export function buildStringFromStep(user:User, step: VitovaActivityStep): string {
    let parts: string[] = [];

    switch (step.type) {
        case VitovaStepType.STRENGTH:
            if (!step.strength) return "";
    
            const { exercise, sets, reps, rpe } = step.strength;
    
            parts = []; // Reset parts array for each case
    
            if (sets) parts.push(`${sets} sets`);
            if (reps) parts.push(`${reps} reps`);
            if (exercise) parts.push(`of ${exercise.name}`);
            if (rpe) parts.push(`at ${rpe}/10 effort`);
    
            return parts.join(" ");
    
        case VitovaStepType.CARDIO:
            if (!step.cardio) return "";
    
            const { exercise: cardioExercise, duration, distance, rpe: cardioRPE, power } = step.cardio;
    
            parts = []; // Reset parts array for each case
    
            // Prioritize duration, otherwise use distance
            if (duration) {
                parts.push(`${duration} minutes`);
            } else if (distance) {
                parts.push(`${distance} km`);
            }
    
            if (cardioExercise) parts.push(`of ${cardioExercise.name}`);
            if (cardioRPE) parts.push(`at ${cardioRPE}/10 effort (${metersPerSecondToMinPerKmString(getPaceFromRpe(user, cardioExercise.name, cardioRPE))})`);
            if (power) parts.push(`at ${power}W`);
    
            return parts.join(" ");
        case VitovaStepType.FLEXIBILITY:
            if (!step.flexibility) return "";

            const { exercise: flexibilityExercise, sets: flexibilitySets, holdDuration, rpe:flexibilityRPE } = step.flexibility;
            
            parts = []; // Reset parts array for each case
            
            if (flexibilitySets) parts.push(`${flexibilitySets} sets`);
            if (holdDuration) parts.push(`${holdDuration} second holds`);
            if (flexibilityExercise) parts.push(`of ${flexibilityExercise.name}`);
            if (flexibilityRPE) parts.push(`at ${flexibilityRPE}/10 effort`);
            
            return parts.join(" ");
        case VitovaStepType.CHOICE:
            if (!step.choiceday) return "";

            const { duration: choicedayDuration, rpe:choicedayRPE, notes} = step.choiceday;
            
            parts = []; // Reset parts array for each case
            
            if (choicedayDuration) parts.push(`Around ${choicedayDuration} minutes`);
            if (choicedayRPE) parts.push(`at ${choicedayRPE} / 10 effort - `);
            if (notes) parts.push(`${notes}`);
            
            return parts.join(" ");
        case VitovaStepType.REST:
            return "No Workout Today - Take It Easy!"
        default:
            return step.label;
    }
    

}
