import { Container } from "react-bootstrap";
import PlansPageLoggedInView from "../components/PlansPageLoggedInView";
import { User } from "../models/user";
import PlansPageLoggedOutView from "../components/PlansPageLoggedOutView";
import VitovaPlansPageLoggedInView from "../components/VitovaPlansPageLoggedInView";

interface PlansPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const PlansPage = ({ loggedInUser, updateUser }: PlansPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <VitovaPlansPageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        onPlanUpdated={(user)=>updateUser(user)}
                        />
                    : <PlansPageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default PlansPage;