import { Exercise, VitovaExerciseCategory, VitovaExerciseEquipmentNeeded, VitovaExerciseForceType, VitovaExerciseLevel, VitovaExerciseMechanic } from "../../models/exercise";
import { VitovaStepType } from "../../models/vitovaPlan";

export const EMPTY_EXERCISE: Readonly<Exercise> = Object.freeze({
    id: '',
    name: 'New Exercise',
    stepType: VitovaStepType.STRENGTH,
    force: VitovaExerciseForceType.PULL,
    level: VitovaExerciseLevel.BEGINNER,
    mechanic: VitovaExerciseMechanic.COMPOUND,
    equipment: VitovaExerciseEquipmentNeeded.HOME,
    primaryMuscles: [],
    secondaryMuscles: [],
    instructions: [],
    category: VitovaExerciseCategory.STRENGTH,
    images: [],
    video: '',
    joints: [],
    homeSuitable: false,
    barbell: false,
    dumbbell: false,
    bands: false,
    kettlebell: false,
    machine: false,
    progression: [],
    regression: [],
    isolation: false,
    compound: false,
    homeGymSwap: [],
    overhead: false,
    floor: false,
    isometric: false,
    axialLoad: false,
    abdominalStrain: false,
    highImpact: false
});