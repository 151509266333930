import React, { useState } from "react";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { User } from "../models/user";
import { updateUser } from "../network/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBicycle, faCircle, faCircleCheck, faDumbbell, faRunning, faSwimmer } from "@fortawesome/free-solid-svg-icons";

const PreferencePageLoggedInView = ({ user }: { user: User }) => {
  const [currentUser, setCurrentUser] = useState<User>(user);

  const handleInputChange = (field: keyof User, value: any) => {
    setCurrentUser({ ...currentUser, [field]: value });
  };

  const handleNestedInputChange = (field: keyof User, subField: string, value: any) => {
    const nestedObject = currentUser[field] as Record<string, any>;
    setCurrentUser({
      ...currentUser,
      [field]: {
        ...nestedObject,
        [subField]: value,
      },
    });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const selectedValue = e.target.value;
    setCurrentUser({
      ...currentUser,
      expectedSurgery: selectedValue,
    });
  };

  const saveUser = async () => {
    try {
      await updateUser(currentUser._id, currentUser);
      alert("Changes saved successfully!");
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  const handleActivityClick = (id: number) => {
    const updatedPreferences = currentUser.activityPreferences.includes(id)
      ? currentUser.activityPreferences.filter((pref) => pref !== id)
      : [...currentUser.activityPreferences, id];

    setCurrentUser({
      ...currentUser,
      activityPreferences: updatedPreferences,
    });
  };

  return (
    <div style={{ padding: "30px", maxWidth: "95%", margin: "auto" }}>
      <h2 style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '20px' }}>MANAGE YOUR PROFILE</h2>
      
      <TextField
        label="Full Name"
        fullWidth
        variant="outlined"
        value={currentUser.fullname}
        onChange={(e) => handleInputChange("fullname", e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <TextField
        label="Email"
        fullWidth
        variant="outlined"
        value={currentUser.email}
        onChange={(e) => handleInputChange("email", e.target.value)}
        style={{ marginBottom: "20px" }}
      />

      <h3 style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '20px', marginTop: '10px' }}>INFO</h3>
      <TextField
        label="Height (cm)"
        fullWidth
        variant="outlined"
        value={currentUser.info.height}
        onChange={(e) => handleNestedInputChange("info", "height", Number(e.target.value))}
        style={{ marginBottom: "20px" }}
      />
      <TextField
        label="Weight (kg)"
        fullWidth
        variant="outlined"
        value={currentUser.info.weight}
        onChange={(e) => handleNestedInputChange("info", "weight", Number(e.target.value))}
        style={{ marginBottom: "20px" }}
      />

      <h3 style={{ fontStyle: "italic", fontWeight: "bold", marginBottom: "20px", marginTop: '10px' }}>PLAN AND SURGERY INFO</h3>
      <div style={{ marginBottom: "20px" }}>
        <FormControl
          key="Surgery"
          fullWidth
          variant="outlined"
        >
          <InputLabel shrink={!!currentUser.expectedSurgery}>Surgery</InputLabel>
          <Select
            value={currentUser.expectedSurgery}
            onChange={handleSelectChange}
            label="Surgery"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  overflowY: 'auto',
                },
              },
              disableScrollLock: true,
            }}
          >
            <MenuItem disabled value="">
              Select A Surgery
            </MenuItem>
            <MenuItem value="Complex Abdominal Wall Surgery">
              Complex Abdominal Wall Surgery
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    
      <div style={{ marginBottom: "20px" }}>
            <TextField
            label="Surgery Date"
            type="date"
            fullWidth
            variant="outlined"
            defaultValue={
                currentUser.surgeryDate
                    ? currentUser.surgeryDate.toString().split('T')[0]
                    : ''
            }
            onChange={(e) => {
                const dateValue = e.target.value ? new Date(e.target.value) : currentUser.surgeryDate;
                setCurrentUser({
                    ...currentUser,
                    surgeryDate: dateValue,
                });
            }}
            InputLabelProps={{ shrink: true }}
            style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
            />
        </div>
        <div style={{ marginBottom: "20px" }}>
        <TextField
            label="Current Plan Start Date"
            type="date"
            fullWidth
            variant="outlined"
            defaultValue={
                currentUser.userPlanStartDate
                    ? currentUser.userPlanStartDate.toString().split('T')[0]
                    : ''
            }
            onChange={(e) => {
                const dateValue = e.target.value ? new Date(e.target.value) : currentUser.userPlanStartDate;
                setCurrentUser({
                    ...currentUser,
                    userPlanStartDate: dateValue,
                });
            }}
            InputLabelProps={{ shrink: true }}
            style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
        />
    </div>

      <h3 style={{ fontStyle: "italic", fontWeight: "bold", marginBottom: "10px", marginTop: '30px' }}>ACTIVITY PREFERENCES</h3>
      <ul className="activity-grid-container" style={{ marginBottom: '30px', display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "20px" }}>
        {[
          { id: 0, title: "Running/Walking", icon: faRunning },
          { id: 1, title: "Cycling", icon: faBicycle },
          { id: 2, title: "Swimming", icon: faSwimmer },
          { id: 3, title: "Strength and Conditioning", icon: faDumbbell },
        ].map((activity) => (
          <li
            key={activity.id}
            className="activity-grid-item"
            style={{ listStyle: "none", cursor: "pointer" }}
            onClick={() => handleActivityClick(activity.id)}
          >
            <div
              className="activity-card"
              style={{
                borderRadius: "5px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                padding: "15px",
                textAlign: "center",
                transition: "transform 0.2s",
                backgroundColor: currentUser.activityPreferences.includes(activity.id) ? "#f0f8ff" : "#fff",
                border: "1px solid #ddd",
              }}
            >
              {currentUser.activityPreferences.includes(activity.id) ? (
                <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{ color: "steelblue", fontSize: "1.5em", marginTop: 0, marginRight: '5px' }} />
              ) : (
                <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{ color: "#676767", fontSize: "1.5em", marginTop: 0, marginRight: '5px' }} />
              )}

              <FontAwesomeIcon className="activity-card-img" icon={activity.icon} style={{ fontSize: "3em", margin: "10px 0" }} />
              <h2 className="activity-card-title" style={{ color: "#676767", fontSize: "1.2em", margin: "10px 0" }}>{activity.title}</h2>
            </div>
          </li>
        ))}
      </ul>

      <Button
        variant="contained"
        onClick={saveUser}
        style={{ marginTop: "20px", backgroundColor: 'steelblue' }}
      >
        SAVE CHANGES
      </Button>
    </div>
  );
};

export default PreferencePageLoggedInView;
