import * as Api from "../network/api";
import { CheckedCondition, CheckedMedication, User } from '../models/user';
import '../styles/HomePage.css';
import '../styles/SessionReview.css'
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControl, InputLabel, MenuItem, Select, Slider, TextField } from "@mui/material";
import { fa0, fa1, fa2, fa3, fa4, fa5, faArrowLeft, faBarsProgress, faBicycle, faBone, faBorderNone, faCalendarCheck, faCalendarDay, faCalendarDays, faCheck, faCircle, faCircleCheck, faCross, faDumbbell, faHeart, faHeartPulse, faNotdef, faPlus, faRemove, faRotateRight, faRunning, faSwimmer } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import colorPalette from "../utils/colors";

import conditionsList from "../localdata/conditionswithmedications";
import { secondsToTimeString } from "../utility";
import Spinner from "./Spinner";
import { Plan } from "../models/plan";
import ChartComponent from "./ChartComponent";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";
import { VitovaDay, VitovaPlan } from "../models/vitovaPlan";
import GeneralChartComponent from "./GeneralChartComponent";
import { getDayStressScore } from "../utils/StressLoadFunctions";

const UserTypeMap = ['ffs','gpr', 'wah']

interface HomePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onInfoSet: (user: User) => void,

}

const enableEmailVerification = false;

const HomePageLoggedInView = ({user, onLogoutSuccessful, onInfoSet}:  HomePageLoggedInProps) => {
    const [questionStage, setQuestionStage] = useState<number>(0);

    const [currentUser, setCurrentUser] = useState<User>(user);

    const [surgeryDate, setSurgeryDate] = useState('')
    const [dob, setDOB] = useState('');
    const [height, setHeight] = useState(180);
    const [weight, setWeight] = useState(80);
    const [waist, setWaist] = useState(34);
    const [smoking, setSmoking] = useState(2);
    const [drinking, setDrinking] = useState(2);
    const [exerciseHistory, setExerciseHistory] = useState(2);
    const [expectedSurgery, setExpectedSurgery] = useState('');
    const [gender, setGender] = useState('m');
    const [expectedSurgeryError, setExpectedSurgeryError] = useState(false);
    // const [conditions, setConditions] = useState<Array<DBCondition>>([]);
    // const [medications, setMedications] = useState<Array<DBMedication>>([]);

    const [checkedConditions, setCheckedConditions] = useState<Array<CheckedCondition>>(
        []
    );  
    const [customCondition, setCustomCondition] = useState<string>('');
    const [customMedication, setCustomMedication] = useState<string>('');

    const [checkedMedications, setCheckedMedications] = useState<Array<CheckedMedication>>(
        []
    );

    const [selectedGP, setSelectedGP] = useState<User | undefined>(undefined);

    const [selectedCondition, setSelectedCondition] = useState('');
    const [selectedMedication, setSelectedMedication] = useState('');

    const [emailSent, setEmailSent] = useState(false);
      
    const [checkedActivities, setCheckedActivities] = useState<Array<number>>([]);
    const [checkedAvailability, setCheckedAvailability] = useState<Array<number>>([1,2,3,4,5]);
    const [checkedLevel, setCheckedLevel] = useState<number>(1);
    const [checkedUserType, setCheckedUserType] = useState<number>(0);
    const [gpReferralCodeInput, setGPReferralCodeInput] = useState('');

    const [bestEfforts5k, setBestEffort5k] = useState(-1);
    const [bestEfforts40k, setBestEffort40k] = useState(-1);
    const [bestEfforts100m, setBestEffort100m] = useState(-1);

    const [generatingPlan, setGeneratingPlan]= useState(false);
    const [generatedPlan, setGeneratedPlan]= useState(false);

    const [plans, setPlans] = useState<Array<VitovaPlan>>([]);

    // ERRORS
    const [error, setError] = useState(false);

    const handleNextStage = () => {
        if (!surgeryDate || surgeryDate.length !== 10) {
            setError(true); // Show error message
        } else if (!expectedSurgery){
            setExpectedSurgeryError(true);
        }
        else {
            setError(false); // Clear error if valid
            setExpectedSurgeryError(false)
            console.log('Proceeding to next stage with date:', surgeryDate);
            nextStage(); // Call your nextStage function
        }
    };


    const handleActivityClick = (id: number) => {
        setCheckedActivities((prevCheckedActivities) => {
            if (prevCheckedActivities.includes(id)) {
                // Remove id if it exists
                return prevCheckedActivities.filter(activityId => activityId !== id);
            } else {
                // Add id if it doesn't exist
                return [...prevCheckedActivities, id];
            }
        });
    };
    
    useEffect(() => {
        getPlans();
        getConditions();
        getMedications();
        // Calculate 3 months from the current date
        const today = new Date();
        today.setMonth(today.getMonth() + 3);
        
        // Format the date to YYYY-MM-DD
        const formattedDate = today.toISOString().split("T")[0];
        
        // Set the default surgery date to 3 months from today
        setSurgeryDate(formattedDate);
        setDOB(formattedDate)
    }, []);

    const handleCheckboxChange = (name: string) => {
        setCheckedConditions((prevCheckedConditions) =>
          prevCheckedConditions.map((item) =>
            item.condition.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };

      const handleMedicationCheckboxChange = (name: string) => {
        setCheckedMedications((prevCheckedMedications) =>
            prevCheckedMedications.map((item) =>
            item.medication.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };
    // State variables to store the answers and form stage
    const [formData, setFormData] = useState({
        heartCondition: '',
        chestPainActivity: '',
        chestPainRest: '',
        dizziness: '',
        boneJointProblem: '',
        bloodPressureMedication: '',
        dob: '',
        address: '',
        signature: '',
    });

    const disclaimerText = "I hereby declare that by signing this form I accept sole responsibility for my health throughout any training plan or coaching undertaken. Training undertaken from this plan is done so solely at my own risk. I declare that I will not train beyond my ability and I understand that any information or advice given does not substitute, replace, or change any professional medical advice, diagnosis, or treatment. If you believe you are injured or require health-related advice, you should contact a health-care professional.I hereby fully and forever release and discharge the online personal trainer (seller), its assigns and agents from all claims, demands, damages, rights of action, present and future therein. I understand and warrant, release and agree that I am in good physical condition and that I have no disability, impairment or ailment preventing me from engaging in active or passive exercise that will be detrimental to overall health, safety, or comfort, or physical condition if I engage or participate (other than those items fully discussed on the health history form). All client information received will be destroyed upon finishing or termination of a training plan or package. Any information stored will be done so securely and safely in line with GDPR guidelines.  Any information given shall not be shared with any 3rd party companies or otherwise. All information given is tailored and private to the purchaser of this plan and by signing this you declare that any information shall not be distributed outside of it’s intended users. I understand that within 14 days of the first month purchase, the client is entitled to a full refund. After this point fees are non-refundable for the month purchased."

    const nextStage = () => setQuestionStage(prevStage => prevStage + 1);
    const prevStage = () => setQuestionStage(prevStage => Math.max(prevStage - 1, 0));

    async function logout() {
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setQuestionStage(0);
    };
    async function getPlans(){
        const response = await Api.getVitovaPlans().then(res=>{
            setPlans(res);
        });
    }

    async function getConditions() {
        try {
            const response = await Api.getConditions();
            setCheckedConditions(response.map((condition: DBCondition) => ({ condition:condition, checked: false })));
        } catch (error) {
            console.error("Failed to fetch medications:", error);
        }
    }

    async function getMedications() {
        try {
            const response = await Api.getMedications();
            setCheckedMedications(response.map((medication: DBMedication) => ({ medication:medication, checked: false })));
        } catch (error) {
            console.error("Failed to fetch medications:", error);
        }
    }
    

    const finishReview = () => {
        setQuestionStage(0);
    }

    const getNextMonday = () => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const daysUntilNextMonday = (8 - dayOfWeek) % 7;
        const nextMonday = new Date(today);
        nextMonday.setDate(today.getDate() + daysUntilNextMonday);
        nextMonday.setHours(0, 0, 0, 0);
        return nextMonday;
    };

    const getActivityTypeFromIndex = (i:number)=>{
        switch (i){
            case 0:
                return 'Run';
            case 1:
                return 'Cycle';
            case 2:
                return 'Swim';
            case 3:
                return 'Strength';
            default:
                return 'Run'
        }
    }

    async function handleSubmitClick() {  
        setGeneratingPlan(true)
        // if (checkedConditions.filter(c=>c.checked === true).length <= 0){
        //     try {
        //         let newUser = { ...user };
        
        //         // Update user information
        //         newUser.info = {
        //             height: height,
        //             weight: weight,
        //             waistCircumference: waist,
        //             conditions: checkedConditions,
        //             medications: checkedMedications,
        //             parq: formData,
        //             complete: true,
        //             dob:new Date(dob),
        //             gender:gender
        //         };
        
        //         newUser.bestEfforts[0].time = secondsToTimeString(bestEfforts5k);
        //         newUser.bestEfforts[4].time = bestEfforts40k.toString();
        //         newUser.bestEfforts[5].time = secondsToTimeString(bestEfforts100m);

        //         newUser.accountType = UserTypeMap[checkedUserType]
        //         if (checkedUserType === 0 || checkedUserType === 1){
        //             newUser.gpReferralCode = gpReferralCodeInput;
        //         }

        //         const today = new Date();
        //         newUser.userPlanStartDate = today;
            
        //         // Use await to handle asynchronous plan generation
        //         let newPlan: Plan = await generateAIPlanAsync(newUser, checkedLevel, checkedActivities.map(i => getActivityTypeFromIndex(i)), 8, newUser.info.conditions, newUser.info.medications);
        //         newUser.currentPlan = newPlan;
        //         await Api.updateUser(newUser._id, newUser);
        //         setCurrentUser(newUser);
        //         setGeneratingPlan(false);
        //         setGeneratedPlan(true);
        //     } catch (error) {
        //         console.error('Error updating user:', error);
        //         alert('Failed to save changes');
        //     }
        // }else{
        if (plans){
            try {
                let newUser = { ...user };
        
                // INFO OBJECT
                newUser.info = {
                    height: height,
                    weight: weight,
                    waistCircumference: waist,
                    conditions: checkedConditions,
                    medications: checkedMedications,
                    parq: formData,
                    dob:new Date(dob),
                    gender:gender,
                    complete: true,
                };

                // 
                const today = new Date();

                // BASIC INFORMATION
                newUser.surgeryDate = new Date(surgeryDate);
                newUser.smoker = smoking === 0 ? false : true;
                newUser.drinker = drinking === 0 ? false : true;
                newUser.expectedSurgery = expectedSurgery;
                newUser.smokingHabit = smoking;
                newUser.drinkingHabit = drinking;
                newUser.exerciseHistory = exerciseHistory;
                newUser.activityPreferences = checkedActivities;
                newUser.availability = checkedAvailability;
                newUser.weightData = [...(newUser.weightData || []), { value: weight, date: today }];

                // ACCOUNT TYPE
                newUser.accountType = UserTypeMap[checkedUserType]
                if (checkedUserType === 0 || checkedUserType === 1){
                    newUser.gpReferralCode = gpReferralCodeInput;
                }

                // PLAN START DATE
                newUser.userPlanStartDate = today;
            
                // Find first condition
                // const firstCondition = checkedConditions.filter(c=>c.checked)[0].condition.name.toLowerCase()
                const foundPlan = plans.find(p=>p.title.toLowerCase().includes('surgery'));
                if (foundPlan){
                    try {
                        // Use await to handle asynchronous plan generation
                        let newPlan: VitovaPlan = foundPlan;
                        newUser.currentVitovaPlan = newPlan;
                    
                        // First, update the user
                        await Api.updateUser(newUser._id, newUser);
                    
                        // Then, adapt the plan length
                        const updatedUser = await Api.adaptPlanLength(newUser._id, {
                            currentVitovaPlan: newUser.currentVitovaPlan,
                            surgeryDate: newUser.surgeryDate
                        });

                        await Api.updateUser(newUser._id, updatedUser);

                        onInfoSet(updatedUser); // Call onInfoSet with currentUser
                        setCurrentUser(updatedUser);
                        setGeneratingPlan(false);
                        setGeneratedPlan(true);
                    } catch (error) {
                        console.error("Error updating user or adapting plan:", error);
                        setGeneratingPlan(false); // Ensure state is reset even on failure
                    }
                    

                }else{
                    onInfoSet(newUser); // Call onInfoSet with currentUser
                    setCurrentUser(newUser);
                    setGeneratingPlan(false);
                    setGeneratingPlan(true)
                    await Api.updateUser(newUser._id, newUser);
                }

            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }

    }
    
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to an API or display it
        console.log(formData);
    };

    const checkGpReferralCode = async () => {
        const body:Api.GetPatientsBody = {gpReferralCode:gpReferralCodeInput}
        try{
            const response = await Api.getPatients(body);

            if (response.length > 0){
                const GP = response.filter((u:User) => u.GPAccount === true)[0];
                if (GP) {
                    const userConfirmed = window.confirm(`We've found your Health Care Professional: ${GP.fullname}`);
                    if (userConfirmed) {
                        setSelectedGP(GP);
                        nextStage();
                    } else {
                        // Handle the "No" response, if necessary
                        console.log("User indicated the HCP is not correct.");
                    }
                } else {
                    console.log("No HCP found.");
                }
                
            }else{
                alert("Couldn't find that HCP in our records")
            }

        }catch{
            alert("Couldn't find that HCP in our records")
        }
    }


    const handleAddCustomCondition = async () => {
        const findCondition = checkedConditions.find(c=>c.condition.name.toLowerCase()===customCondition.toLowerCase());

        if (findCondition){
            alert("We've Already Got That One!")
            return;
        }
        if (customCondition.trim() !== "") {
            const newCondition:DBCondition = {
                id:'',
                name:customCondition,
                description:'',
                symptoms:[],
                commonMedications:[],
                cardioConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2
                },
                resistanceConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2,
                    setsMin:1,
                    setsMax:3,
                    repsMin:10,
                    repsMax:15,
                    weeklyExercisesMin:5,
                    weeklyExercisesMax:12,
                },
                flexibilityConstraints:{
                    rpeMin:1,
                    rpeMax:5,
                    durationMin:30,
                    durationMax:60,
                    weeklyFreqMax:3,
                    weeklyFreqMin:2
                },
                verified:false,
            }
            setCheckedConditions((prev) => [...prev, {condition:newCondition, checked:true}]);
            setCustomCondition("");
            await Api.createCondition(newCondition);
        }
    };

    const handleAddCustomMedication = async () => {
        const findMedication= checkedMedications.find(m=>m.medication.name.toLowerCase()===customMedication.toLowerCase());

        if (findMedication){
            alert("We've Already Got That One!")
            return;
        }
        if (customMedication.trim() !== "") {
            const newMedication:DBMedication = {
                _id:'',
                name:customMedication,
                medId:'',
                verified:false,
            }
            setCheckedMedications((prev) => [...prev, {medication:newMedication, checked:true}]);
            setCustomMedication("");
            await Api.createMedication(newMedication);
        }
    };

    async function sendVerificationEmail () {
        // Send email verification
        await Api.sendVerificationEmail({
            userId:user._id,
            email:user.email
        });

        setEmailSent(true);
    }

    const marks = generateMarks();

    const renderPathwayOptions = () => {
        return (
            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
            <div style={{width:'auto', display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh'}}>
                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Which platform are you looking to access?</h2>
                <label style={{textAlign:'center', color:'#454545'}}>Let us know so we can best help you!</label>
                <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
            <ul className="option-grid-container" style={{width:'auto'}}>
                {[
                    { id: 0, title: "Fitness For Surgery", icon: faHeartPulse, disabled:false},
                    { id: 1, title: "HCP Exercise Referral", icon: faRunning, disabled:true},
                    { id: 2, title: "Health And Wellbeing", icon: faHeart, disabled:true},
                ].map((option, index) => (
                    <li key={option.id} style={{pointerEvents:option.disabled?'none':'all'}} className="activity-grid-item" onClick={()=>setCheckedUserType(option.id)}>
                        
                        <div className="activity-card" style={{height:'100%', justifyContent:'flex-start',padding:'0 20px', borderRadius:'5px', boxShadow:'none'}}>
                            {checkedUserType === option.id ?
                                <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                :
                                <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                            }
                            <FontAwesomeIcon className="activity-card-img" icon={option.icon} />
                            <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1.5vw'}}>{option.title}</h2>
                            {(index === 0) &&
                            <input value={gpReferralCodeInput} onChange={(e)=>setGPReferralCodeInput(e.currentTarget.value)} placeholder="Enter Your HCP Referral Code" className="activity-card-title" style={{color:'#676767', fontSize:'1vw', borderRadius:'5px', outline:'none', boxShadow:'none', border:'1px solid #dddddd', marginBottom:'15px'}}></input>
                            }
                        </div>
                    </li>
                ))}
            </ul>
            </div>
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                <Button className="form-next-button" onClick={()=>{((checkedUserType === 0 || checkedUserType === 1) ? gpReferralCodeInput.length <= 0 ? alert('Please enter a HCP referral code') : checkGpReferralCode() : nextStage())}}>Next Step</Button>
            </div>
        </div>
        )
    }

    const renderGpReferralPathway = () => {
        return <></>
    }

    const renderFitnessForSurgeryPathway = () => {
        switch (questionStage){
            case 1:
                return (
                <div className="form-stage">
                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Terms and Conditions</h2>
                    
                    <label style={{fontSize:'1vw', textAlign:'center', padding:'30px 20px', backgroundColor:`white`, borderRadius:'5px', color:'#454545'}} >{disclaimerText}</label>

                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={logout}>Logout</Button>

                        <Button className="form-next-button" onClick={nextStage}>Accept Conditions</Button>
                    </div>
                </div>
                )
            case 2:
                return renderPathwayOptions()
            case 3:
                return(
                <div className="form-stage" style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll' }}>
                    {selectedGP ?
                        <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', height: '70vh', width: '90%', color:'black'}}>
                            <h2 style={{ fontSize: '2vw', alignSelf: 'center', marginBottom: '1vh', marginTop: '2vh', color: '#454545' }} className="main-title">
                            A Little Bit About Your Health Care Professional
                            </h2>
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                            {`Here's Some Information About Dr. ${selectedGP.fullname}`}
                            </label>
                            
                            <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            {/* Display GP's Full Name */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Name:</strong> {selectedGP.fullname}
                            </label>
                            
                            {/* Display GP's Email */}
                            <label style={{ textAlign: 'center', color: '#454545' }}>
                                <strong>Email:</strong> {selectedGP.email}
                            </label>
                            
                            {/* Display GP's Image */}
                            {selectedGP.image && (
                                <img 
                                src={selectedGP.image} 
                                alt={`${selectedGP.fullname}`} 
                                style={{ width: '150px', height: '150px', borderRadius: '50%', marginTop: '20px' }} 
                                />
                            )}
                            </div>
                        </div>
                        :
                        <>No HCP Selected</>                
                    }

                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', position: 'absolute', bottom: 0 }}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>

                    )
            case 4:
                // Please can you confirm your healthcare details for us:​
                // Date of surgery.​
                // Expected surgery.​
                // Health conditions / past medical history (drop down bar?).​
                // Medications (drop down bar).​
                // Have you been signed off to complete this exercise referral pathway by your doctor?
                return(
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                    <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh', width:'90%'}}>
                        <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Basic Information</h2>
                        <label style={{textAlign:'center', color:'#454545'}}>Please can you confirm your healthcare details for us</label>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label className="question-label">
                                When is your surgery?
                            </label>
                            <TextField
                                label="Surgery Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={surgeryDate ? surgeryDate : ""}  // Default to empty string if surgeryDate is not set
                                onChange={(e) => {
                                    setSurgeryDate(e.currentTarget.value);
                                    setError(false);
                                }}
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                            />

                            {!surgeryDate ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Please select a date for your surgery.
                                </p>
                            ) : isNaN(new Date(surgeryDate).getTime()) ? (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid date.
                                </p>
                            ) : 
                            isNaN(new Date(surgeryDate).getTime()) || new Date(surgeryDate) <= new Date() ? (
                                <p style={{ color: 'steelblue', fontWeight: 'bold', fontStyle: 'italic', marginTop: '0.5em' }}>
                                    Invalid date selected. Please choose a valid future date.
                                </p>
                            ) : (
                                <p style={{ color: 'steelblue', fontWeight:'bold', fontStyle:'italic',marginTop: '0.5em' }}>
                                    {`YOUR SURGERY IS IN ${Math.ceil(
                                        (new Date(surgeryDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
                                    )} DAYS!`}
                                </p>
                            )}
 
                            {error && <p style={{ color: 'red', marginTop: '0.5em' }}>A valid date is required</p>}

                        </div>
                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'20px'}} className="question-label">
                                Which surgery will you be undergoing?
                            </label>
                            <FormControl 
                                key="Surgery" 
                                fullWidth 
                                variant="outlined" 
                                style={{ marginBottom: "20px" }}
                                >
                                <InputLabel shrink={!!expectedSurgery}>Surgery</InputLabel>
                                <Select
                                    value={expectedSurgery}
                                    onChange={(e) => {
                                    const target = e.target as HTMLInputElement; // Explicitly cast to HTMLInputElement
                                    if (target && target.value) {
                                        setExpectedSurgery(target.value);
                                    }
                                    }}
                                    label="Surgery"
                                >
                                    <MenuItem disabled value="">
                                    Select A Surgery
                                    </MenuItem>
                                    <MenuItem value="Complex Abdominal Wall Surgery">
                                    Complex Abdominal Wall Surgery
                                    </MenuItem>
                                </Select>
                                </FormControl>

                                {expectedSurgeryError && <p style={{ color: 'red', marginTop: '0.5em' }}>Please select a surgery</p>}

                        </div>
                        <div className="dob-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <label style={{ marginTop: '20px' }} className="question-label">
                Which conditions do you have?
            </label>
            <FormControl fullWidth variant="outlined" style={{ marginBottom: "10px"}}>
                <InputLabel shrink={!!selectedCondition}>Conditions</InputLabel>
                <Select
                    value={selectedCondition}
                    onChange={(e) => {
                        const selectedName = e.target.value;
                        handleCheckboxChange(selectedName);
                        setSelectedCondition(selectedName);
                    }}
                    label="Conditions"
                >
                    <MenuItem disabled value="">Select A Condition</MenuItem>
                    {checkedConditions.map((condition, index) => (
                        <MenuItem key={index} value={condition.condition.name}>
                            {condition.condition.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <TextField
                    variant="outlined"
                    value={customCondition}
                    onChange={(e) => setCustomCondition(e.target.value)}
                    style={{ marginBottom: "10px", width: '100%'}}
                    placeholder="Have a condition that is not listed? Enter it here"
                />
                <Button 
                    variant="contained" 
                    onClick={handleAddCustomCondition}
                    style={{ marginLeft: '10px', padding: '10px' }}
                >
                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2vw'}}/>
                </Button>
            </div>

            
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                {checkedConditions.filter(c => c.checked).map((condition, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
                        <Checkbox
                            checked={condition.checked}
                            onChange={() => handleCheckboxChange(condition.condition.name)}
                            style={{ color: '#454545' }}
                        />
                        <label style={{ color: 'steelblue' }}>{condition.condition.name}</label>
                    </div>
                ))}
            </div>
        </div>

                        <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <label style={{marginTop:'20px'}} className="question-label">
                                Which medications do you take?
                            </label>
                            <FormControl 
                            key="Medications" 
                            fullWidth 
                            variant="outlined" 
                            style={{ marginBottom: "10px" }}
                            >
                            <InputLabel shrink={!!selectedMedication}>Medications</InputLabel>
                            <Select
                                value={selectedMedication} // Make sure there's an initial value for the select
                                onChange={(e) => {
                                const selectedName = e.target.value as string;
                                handleMedicationCheckboxChange(selectedName);
                                setSelectedMedication(selectedName)
                                }}
                                label="Medications" // Link the label to the Select component
                            >
                                <MenuItem disabled value="">
                                Select A Medicaiton
                                </MenuItem>
                                {checkedMedications.map((medication: CheckedMedication) => (
                                <MenuItem 
                                    style={{ width: '100%' }}
                                    key={medication.medication.name} 
                                    value={medication.medication.name}
                                >
                                    {medication.medication.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'center', alignItems:'center'}}>
                                <TextField
                                    variant="outlined"
                                    value={customMedication}
                                    onChange={(e) => setCustomMedication(e.target.value)}
                                    style={{ marginBottom: "10px", width: '100%'}}
                                    placeholder="Have a medication that is not listed? Enter it here"
                                />
                                <Button 
                                    variant="contained" 
                                    onClick={handleAddCustomMedication}
                                    style={{ marginLeft: '10px', padding: '10px' }}
                                >
                                    <FontAwesomeIcon icon={faPlus} style={{color:colorPalette.steelBlue, fontSize:'2vw'}}/>
                                </Button>
                            </div>
                            <div style={{ display: 'flex',}}>
                            {checkedMedications.filter(m=>m.checked).map((medication:CheckedMedication, index) => (
                                    <div key={index} style={{ width: '25%', marginLeft:'2vw', marginRight:'2vw',display: 'flex', alignItems: 'center'}}>
                                    <Checkbox 
                                        checked={medication.checked}
                                        onChange={() => handleMedicationCheckboxChange(medication.medication.name)}
                                        style={{color:'#454545'}}/>
                                    <label style={{color:colorPalette.steelBlue}}>{medication.medication.name}</label>
                                    </div>
                            ))}
                            </div>
                        </div>
                        <label style={{marginTop:'20px', color:'red'}} className="question-label">
                            By continuing you agree that you have been signed off to complete this exercise referral pathway by your doctor
                        </label>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={handleNextStage}>Next Step</Button>
                    </div>
                </div>
                )
            case 5:
                return (
                    // Now we need to know a little more about you:​
                    // Height, Weight, age, DOB, sex.​
                    // Alcohol history, smoking history.​
                    // Diet, fluid and caffeine intake.​
                    // Normal daily activity.​
                    // Exercise history – range from 1-10 explaining what each increment is.​
                    // Current fitness level (how do we ask this?)
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545', maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Basic Information</h2>
                <label >Let's learn about you</label>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                            <label className="question-label">
                                What is your date of birth?
                            </label>
                            {/* <input className="question-input"
                                style={{fontSize: '1.2em', padding: '10px 15px', width: '96%', marginTop:'1vh',textAlign: 'center', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}} 
                                type="date" 
                                name="dob" 
                                value={dob} 
                                onChange={(e)=>setDOB(e.currentTarget.value)} /> */}
                            <TextField
                                label="Date Of Birth"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={dob} 
                                onChange={(e)=>setDOB(e.currentTarget.value)} 
                                InputLabelProps={{ shrink: true }}
                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                />
                </div>
                <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'75%'}}>
                    <label style={{marginTop:'20px'}} className="question-label">
                        What is your gender?
                    </label>
                    {/* <select required className="question-input"
                            style={{
                                fontSize: '1.2em', 
                                padding: '10px 15px', 
                                width: '96%', 
                                marginTop: '1vh',
                                height:'50px', 
                                backgroundColor:'#fff',
                                textAlign: 'center', 
                                border: '1px solid #ccc', 
                                borderRadius: '5px', 
                                boxShadow: '0',

                            }}
                            //name="surgery" 
                            value={gender} 
                            onChange={(e)=>setGender(e.currentTarget.value)}
                        >
                            <option value="" disabled selected>Select A Gender</option>
                            <option value="m">Male</option>
                            <option value="f">Female</option>
                            <option value="o">Other</option>
                            <option value="n">Prefer Not To Say</option>



                    </select> */}
                    <FormControl 
                            key="Gender" 
                            fullWidth 
                            variant="outlined" 
                            >
                            <InputLabel shrink={!!gender}>Gender</InputLabel>
                            <Select
                                value={gender} 
                                onChange={(e)=>{
                                    const selectedValue = e.target.value as string;
                                    setGender(selectedValue)
                                }}
                                label="Gender" // Link the label to the Select component
                            >
                                <MenuItem disabled selected value="">
                                Select A Gender
                                </MenuItem>
                                <MenuItem value="m">Male</MenuItem>
                                <MenuItem value="f">Female</MenuItem>
                                <MenuItem value="o">Other</MenuItem>
                                <MenuItem value="p">Prefer Not To Say</MenuItem>


                            </Select>
                    </FormControl>
                </div>
                <label className="question-label" style={{color:'#454545'}}>What Is Your Height? (cm)</label>
                {/* <Slider 
                    style={{marginTop:'0'}}
                    defaultValue={180}
                    value={height}
                    onChange={(e: Event, value: number | number[]) => setHeight(value as number)}
                    step={1} 
                    min={150} 
                    max={220}
                    // marks
                    valueLabelDisplay="auto"
                    sx={sliderStyling}
                /> */}
                    <TextField
                        label="Height"
                        type="number"
                        variant="outlined"
                        value={height || ""}  // Default to empty string if surgeryDate is not set
                        onChange={(e) => setHeight(Number(e.target.value))}
                        InputLabelProps={{ shrink: true }}
                        style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                        />
                <label className="question-label"style={{color:'#454545'}}>What Is Your Weight? (kg)</label>
                {/* <Slider
                    style={{marginTop:'0'}}
                    value={weight}
                    defaultValue={80}
                    onChange={(e: Event, value: number | number[]) => setWeight(value as number)}
                    step={1}
                    min={40}
                    max={200}
                    // marks={marks}
                    valueLabelDisplay="auto"
                    sx={sliderStyling}
                /> */}
                <TextField
                    label="Weight"
                    type="number"
                    fullWidth
                    variant="outlined"
                    value={weight || ""}  // Default to empty string if surgeryDate is not set
                    onChange={(e) => setWeight(Number(e.target.value))}
                    InputLabelProps={{ shrink: true }}
                    style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'75%'}}
                    />
                 {/* <label className="question-label"style={{color:'#454545'}}>What Is Your Waist Circumference? (in)</label>
                <Slider
                    style={{marginTop:'0'}}
                    defaultValue={34}
                    value={waist}
                    onChange={(e: Event, value: number | number[]) => setWaist(value as number)}
                    step={1}
                    min={20}
                    max={70}
                    // marks={marks}
                    valueLabelDisplay="auto"
                    sx={sliderStyling}
                /> */}
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" type="submit" onClick={nextStage}>Next Step</Button>
                </div>
                </div>
                )
            case 6:
                return (
                    // Now we need to know a little more about you:​
                    // Height, Weight, age, DOB, sex.​
                    // Alcohol history, smoking history.​
                    // Diet, fluid and caffeine intake.​
                    // Normal daily activity.​
                    // Exercise history – range from 1-10 explaining what each increment is.​
                    // Current fitness level (how do we ask this?)
                <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545',  minHeight:'70vh',maxHeight:'70vh'}}>
                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Health Questions</h2>
                <label >Let's learn about your health</label>
                
                <div style={{paddingLeft:'30px',paddingRight:'30px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Smoke?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={smoking}
                        onChange={(e: Event, value: number | number[]) => setSmoking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't smoke" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I smoke every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How Often Do You Drink?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={drinking}
                        onChange={(e: Event, value: number | number[]) => setDrinking(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "I don't drink" },
                            { value: 1, label: "Rarely" },
                            { value: 2, label: "Occasionally" },
                            { value: 3, label: "Sometimes" },
                            { value: 4, label: "Often" },
                            { value: 5, label: "I drink every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{paddingLeft:'30px',paddingRight:'30px', marginTop:'20px',display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                    <label className="question-label" style={{color: '#454545'}}>
                        How would you describe your exercise history?
                    </label>
                    <Slider
                        defaultValue={0}
                        value={exerciseHistory}
                        onChange={(e: Event, value: number | number[]) => setExerciseHistory(value as number)}
                        step={1}
                        min={0}
                        max={5}
                        marks={[
                            { value: 0, label: "Very rarely exercise" },
                            { value: 1, label: "Rarely exercise" },
                            { value: 2, label: "Occasionally exercise" },
                            { value: 3, label: "Sometimes exercise" },
                            { value: 4, label: "Exercise often" },
                            { value: 5, label: "Exercise every day" },
                        ]}
                        valueLabelDisplay="off" // Hides numeric labels
                        sx={{
                            ...sliderStyling,
                            '& .MuiSlider-markLabel': {
                                marginTop: '40px', // Adds spacing below the slider for labels
                                fontSize: '0.85em', // Optional: Adjusts font size of labels
                                color: '#454545', // Optional: Matches label text color
                            },
                        }}
                    />
                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                </div>
                </div>
                )
            case 7:
                return (
                    <div className="form-stage" style={{color:'#454545'}}>
                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Activity Preferences</h2>
                    {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                    <label style={{marginBottom:'1vh'}}>Let us know what activities you enjoy so that we can create a suitable plan for you!</label>
                    <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                    <ul className="activity-grid-container">
                        {[
                            { id: 0, title: "Running", icon: faRunning },
                            { id: 1, title: "Cycling", icon: faBicycle },
                            { id: 2, title: "Swimming", icon: faSwimmer },
                            { id: 3, title: "Strength and Conditioning", icon: faDumbbell },
                        ].map((activity) => (
                            <li key={activity.id} className="activity-grid-item" onClick={()=>handleActivityClick(activity.id)}>
                                
                                <div className="activity-card" style={{borderRadius:'5px', boxShadow:'none'}}>
                                    {checkedActivities.includes(activity.id) ?
                                        <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                        :
                                        <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                                    }
    
    
                                    <FontAwesomeIcon className="activity-card-img" icon={activity.icon} />
                                    <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1.5vw'}}>{activity.title.replace('Running', 'Running/Walking')}</h2>
                                </div>
                            </li>
                        ))}
                    </ul>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={()=>checkedActivities.length > 0 ? nextStage() : alert('Please Select At Least One Activity')}>Next Step</Button>
                    </div>
                </div>
                )
            case 8:
                return (
                    <div className="form-stage" style={{color:'#454545'}}>
                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Current Availability</h2>
                    {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                    <label style={{marginBottom:'1vh'}}>Let's learn about your current training availability! Which days can you workout?</label>
                    {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}
    
                    <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                    <ul className="calendar-grid-container">
                        {[
                            { id: 0, title: "Monday", icon: faCalendarDay },
                            { id: 1, title: "Tuesday", icon: faCalendarDay },
                            { id: 2, title: "Wednesday", icon: faCalendarDay },
                            { id: 3, title: "Thursday", icon: faCalendarDay },
                            { id: 4, title: "Friday", icon: faCalendarDay },
                            { id: 5, title: "Saturday", icon: faCalendarDay },
                            { id: 6, title: "Sunday", icon: faCalendarDay },
                            // { id: 7, title: "Toggle", icon: faCalendarDay },


                        ].map((option) => (
                            <li key={option.id} className="activity-grid-item"                             
                            onClick={() => 
                                checkedAvailability.includes(option.id) 
                                    ? checkedAvailability.length === 5 ? alert('Minimum of 5 days is required') : setCheckedAvailability(checkedAvailability.filter(o => o !== option.id)) 
                                    : setCheckedAvailability([...checkedAvailability, option.id])
                            }>
                                
                                <div className="activity-card" style={{borderRadius:'5px', boxShadow:'none'}}>
                                    {checkedAvailability.includes(option.id) ?
                                        <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                        :
                                        <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                                    }
    
    
                                    <FontAwesomeIcon className="activity-card-img" icon={checkedAvailability.includes(option.id) ? faCalendarCheck : faCalendarDay} />
                                    <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1.5vw'}}>{option.title}</h2>
                                </div>
                            </li>
                        ))}
                    </ul>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                    </div>
                </div>
                )
            // case 8:
            //     return (
            //         <div className="form-stage" style={{color:'#454545'}}>
            //         <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Current Level</h2>
            //         {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
            //         <label style={{marginBottom:'1vh'}}>Let's learn about your current training level! Which best describes you?</label>
            //         {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}
    
            //         <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
            //         <ul className="activity-grid-container">
            //             {[
            //                 { id: 0, title: "I'm a beginner looking to build consistency", icon: fa0 },
            //                 { id: 1, title: "I'm an intermediate looking to improve my fitness", icon: fa1 },
            //                 { id: 2, title: "I'm an advanced athlete looking to take it to the next level", icon: fa2 },
            //                 { id: 3, title: "I'm an elite athlete looking to optimise my training", icon: fa3 },
            //             ].map((option) => (
            //                 <li key={option.id} className="activity-grid-item" onClick={()=>setCheckedLevel(option.id)}>
                                
            //                     <div className="activity-card">
            //                         {checkedLevel === option.id ?
            //                             <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
            //                             :
            //                             <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
            //                         }
    
    
            //                         <FontAwesomeIcon className="activity-card-img" icon={option.icon} />
            //                         <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1vw'}}>{option.title}</h2>
            //                     </div>
            //                 </li>
            //             ))}
            //         </ul>
            //         </div>
            //         <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
            //             <Button className="form-back-button" onClick={prevStage}>Previous</Button>
            //             <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
            //         </div>
            //     </div>
            //     )
            // case 9:
            //     return (
            //         <div className="form-stage" style={{color:'#454545'}}>
            //         <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Best Efforts</h2>
            //         {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
            //         <label style={{marginBottom:'1vh'}}>Let's learn about your best efforts!</label>
            //         {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}
    
            //         <div style={{display:'flex', flexDirection:'column',overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
            //         {checkedActivities.includes(0) &&
            //         <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
            //         <label className="question-label" style={{color:'#454545'}}>What Is Your 5K Run Personal Best?</label>
            //             <Slider 
            //                 style={{marginTop:'0'}}
            //                 defaultValue={25 * 60}
            //                 value={bestEfforts5k}
            //                 onChange={(e: Event, value: number | number[]) => setBestEffort5k(value as number)}
            //                 step={2} 
            //                 min={12 * 60} 
            //                 max={60 * 60}
            //                 valueLabelFormat={(l)=>secondsToTimeString(l)}
            //                 valueLabelDisplay="auto"
            //                 sx={sliderStyling}
            //             />
            //             </div>
            //         }
            //         {checkedActivities.includes(1) &&
            //         <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
            //         <label className="question-label" style={{color:'#454545'}}>What Is Average Power 40K Cycle Personal Best? (Watts)</label>
            //             <Slider 
            //                 style={{marginTop:'0'}}
            //                 defaultValue={150}
            //                 value={bestEfforts40k}
            //                 onChange={(e: Event, value: number | number[]) => setBestEffort40k(value as number)}
            //                 step={10} 
            //                 min={50} 
            //                 max={600}
            //                 // valueLabelFormat={(l)=>secondsToTimeString(l)}
            //                 valueLabelDisplay="auto"
            //                 sx={sliderStyling}
            //             />
            //             </div>
            //         }
            //         {checkedActivities.includes(2) &&
            //         <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
            //         <label className="question-label" style={{color:'#454545'}}>What Is 100M Swim Personal Best?</label>
            //             <Slider 
            //                 style={{marginTop:'0'}}
            //                 defaultValue={120}
            //                 value={bestEfforts100m}
            //                 onChange={(e: Event, value: number | number[]) => setBestEffort100m(value as number)}
            //                 step={1} 
            //                 min={45} 
            //                 max={60 * 4}
            //                 valueLabelFormat={(l)=>secondsToTimeString(l)}
            //                 valueLabelDisplay="auto"
            //                 sx={sliderStyling}
            //             />
            //             </div>
            //             }
            //         </div>
            //         <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
            //             <Button className="form-back-button" onClick={prevStage}>Previous</Button>
            //             <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
            //         </div>
            //     </div>
            //     )
            case 9:
                    if (generatingPlan){
                        return (
                            <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                            <Spinner text="Generating Your Custom Plan!"/>
                        </div>
                        )
                    }else{
                        if (generatedPlan){
                            return (
                                <div style={{width:'86vw',height:'74vh',backgroundColor:'#fefefe'}}>        
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                    <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                        {/* <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={()=>regeneratePlan()} icon={faRotateRight}></FontAwesomeIcon> */}
                                        {/* <label style={{fontWeight:'bold', fontSize:'1.3vw', marginLeft:'8px'}}>Generate A New Plan</label> */}
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
                
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                            <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.currentVitovaPlan.title.toUpperCase()}</label>
                                            <label style={{color:'#565656', fontSize:'1.125vw', textAlign:'justify', padding:'0px 20px',}}>{currentUser.currentVitovaPlan.description.slice(0, 400)}</label>
                
                                            <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '3px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentVitovaPlan.content.length} WEEKS`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    fontStyle:'italic',
                                                    padding: '10px 15px', 
                                                    borderRadius: '3px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                }}
                                                onClick={() => {
                                                    onInfoSet(currentUser); // Call onInfoSet with currentUser
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `steelblue`}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.steelBlue}
                                        >
                                            {`GO TO DASHBOARD`}
                                        </button>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>WEEK 1 TRAINING LOAD OVERVIEW</label>
                                            <GeneralChartComponent mh={80} mw={100} 
                                            chartData={currentUser.currentVitovaPlan.content[0].days.map(day=>getDayStressScore(day, user, false))} 
                                            labelData={currentUser.currentVitovaPlan.content[0].days.map((day:VitovaDay, index:number)=>`Day ${index+1}`)}

                                            user={currentUser}></GeneralChartComponent>
                                        </div>
                                    </div>
                            </div>
                            )
                        }else{
                            if (checkedConditions.filter(c=>c.checked === true).length <= 0){
                                return (
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish And Generate My Plan!</Button>
                                </div>
                                </div>
                                )
                            }else{
                                return (
                                    <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                    <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                        <thead>
                                            <tr>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                                <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr  key={1}>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                                {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                        <ul>
                                                            {checkedConditions.map((c, index) => (
                                                                c.checked &&
                                                                <li key={index}>{c.condition.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                        <ul>
                                                            {checkedMedications.map((m, index) => (
                                                                m.checked &&
                                                                <li key={index}>{m.medication.name}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                }
                                                {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                    <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                        <Button className="form-next-button" onClick={handleSubmitClick}>Find Me My Plan!</Button>
                                    </div>
                                    </div>
                                )
                            }
                        }
                    }
            default:
                return <></>
        }   

    }

    const renderHealthAndWellbeingPathway = () => {
        return <></>

    }

    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '50vw',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:'steelblue'

            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:'steelblue'
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }
    return (
        user.info ? 
        user.GPAccount ?
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
            <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Hi ${user.fullname.split(' ')[0]}`}</h2>
            <p style={{color:'#454545'}}>{`Taking on another patient? Your code is ${user.gpReferralCode}`}</p>
            <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                <Link to={'/dashboard'} >Go To My Patients</Link>
            </div>
        </div>
        :
        <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
            <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Welcome back ${user.fullname.split(' ')[0]}!`}</h2>
            <p style={{color:'#454545'}}>Ready to log another activity?</p>
            <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                <Link to={'/dashboard'} >Go To Dashboard</Link>
            </div>
        </div>
            :
            (
            user.GPAccount ?
                !user.isEmailVerified && enableEmailVerification ?
                <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'2.5em', color:'#454545'}} className="main-title">{`EMAIL VERIFICATION`}</h2>
                {emailSent && 
                <p style={{color:'#454545'}}>{`Email Sent! Check your email and follow the link`}</p>
                
                }
                <div className="review-button" style={{backgroundColor:'transparent',outline:'none', border:'none', padding:'10px',display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <Button style={{backgroundColor:colorPalette.steelBlue, border:'none',borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>sendVerificationEmail()}>{emailSent? 'SEND ANOTHER EMAIL' : 'SEND EMAIL VERIFICATION'}</Button>
                </div>
            </div>
                :
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Hi ${user.fullname.split(' ')[0]}`}</h2>
                <p style={{color:'#454545'}}>{`Taking on another patient? Your code is ${user.gpReferralCode}`}</p>
                <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <Link to={'/gpdashboard'} >Go To My Patients</Link>
                </div>
            </div>
            :
            questionStage === 0 ?
            !user.isEmailVerified && enableEmailVerification  ?
            <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
                <h2 style={{fontSize:'2.5em', color:'#454545'}} className="main-title">{`EMAIL VERIFICATION`}</h2>
                {emailSent && 
                    <p style={{color:'#454545'}}>{`Email Sent! Check your email and follow the link`}</p>
                }
                <div className="review-button" style={{backgroundColor:'transparent',outline:'none', border:'none', padding:'10px',display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                    <Button style={{backgroundColor:colorPalette.steelBlue, border:'none',borderRadius:'5px', padding:'8px 12px', fontWeight:'bold'}} onClick={()=>sendVerificationEmail()}>{emailSent? 'SEND ANOTHER EMAIL' : 'SEND EMAIL VERIFICATION'}</Button>
                </div>
            </div>
            :   
            <div className="home-page" style={{display:'flex', height:'70vh', flex:1,justifyContent:'center', alignItems:'center'}}>
                    <h2 style={{fontSize:'3.5em', color:'#454545', fontWeight:'bold', fontStyle:'italic'}} className="main-title">Hi There!</h2>
                    <p style={{color:'#454545'}}>Fill out some basic information to get started</p>
                    <label className="review-button" style={{marginTop:'0'}} onClick={()=>setQuestionStage(1)}>Start Your Journey With Vitova!</label>
                </div>
            :
            (
            <body style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', height:'80vh'}}>
                <div 
                style={{
                    backgroundColor: '#ffffff55', 
                    height: '80vh', 
                    width: '90vw', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start', 
                    borderRadius:'5px',
                    alignItems: 'center',
                    color:'white',
                    paddingTop:'20px'
                }}>

                    {questionStage === 1 && 
                    <div style={{width:'100%'}}>
                        <FontAwesomeIcon className="back-button" style={{marginLeft:'1.5vw'}} onClick={()=>setQuestionStage(0)} icon={faArrowLeft}></FontAwesomeIcon>

                    </div>
                    }
                    <div>
                    {checkedUserType === 0 
                    ? renderFitnessForSurgeryPathway() : checkedUserType === 1 ? renderGpReferralPathway() : renderHealthAndWellbeingPathway()
                    }
                    </div>
                </div>
            </body>
            )
        )
        );

}

export default HomePageLoggedInView;