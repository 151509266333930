export function minutesToTimeString(minutes: number | string){
    if (Number(minutes)){
        const totalMinutes = Math.floor(Number(minutes));
        const hours = Math.floor(totalMinutes / 60);
        const mins = totalMinutes % 60;
        const secs = Math.round((Number(minutes) - totalMinutes) * 60);
        return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }else{
        return '--:--:--';
    }
}