import { VitovaStepType } from "./vitovaPlan";

export enum VitovaExerciseForceType{
    PULL = 'pull',
    PUSH = 'push',
    STATIC = 'static',
}

export enum VitovaExerciseLevel{
    BEGINNER = 'beginner',
    INTERMEDIATE = 'intermediate',
    ADVANCED = 'advanced',
}

export enum VitovaExerciseMechanic{
    COMPOUND = 'compound',
    ISOLATION = 'isolation',
}

export enum VitovaExerciseCategory{
    STRENGTH = 'strength',
    FLEXIBILITY = 'flexibility',
}

export enum VitovaExerciseEquipmentNeeded{
    NONE = 'none',
    HOME = 'home',
    GYM = 'gym',
}

export interface Exercise {
    id: string;
    name: string;
    stepType: VitovaStepType;
    force: VitovaExerciseForceType;
    level: VitovaExerciseLevel;
    mechanic: VitovaExerciseMechanic;
    equipment: VitovaExerciseEquipmentNeeded;
    primaryMuscles: string[];
    secondaryMuscles: string[];
    instructions: string[];
    category: VitovaExerciseCategory;
    images: string[];
    video: string;
    joints: string[]; // List of joints involved in the exercise
    homeSuitable: boolean; // Whether the exercise can be done at home
    barbell: boolean; // If a barbell is required
    dumbbell: boolean; // If dumbbells can be used
    bands: boolean; // If resistance bands can be used
    kettlebell: boolean; // If kettlebells can be used
    machine: boolean; // If a machine is used
    progression: string[]; // More advanced variations of the exercise
    regression: string[]; // Easier variations of the exercise
    isolation: boolean; // Whether it is an isolation exercise
    compound: boolean; // Whether it is a compound exercise
    homeGymSwap: string[]; // Alternative home/gym versions
    overhead: boolean; // If it involves overhead movement
    floor: boolean; // If the exercise is performed on the floor
    isometric: boolean; // If it includes an isometric hold
    axialLoad: boolean; // If it applies axial load (spinal compression)
    abdominalStrain: boolean; // If it places strain on the abdominals
    highImpact: boolean; // If it is a high-impact movement    
 }