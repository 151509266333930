import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, WidthType } from "docx";
import { saveAs } from "file-saver";
import { CheckedCondition, User } from "../models/user";
import { VitovaStepType } from "../models/vitovaPlan";

export function getAverageWeeklyDurationInHours(user:User){
    const userReviews = user.vitovaReviews;
    let sum = 0;
    for (const review of userReviews){
        sum += Number(review.activityReviewData.plannedDuration);
    }
    return Math.round(sum/60/user.currentVitovaPlan.content.length * 100) / 100;
}

export function getNumberOfCardioSessions(user:User){
    const userReviews = user.vitovaReviews;
    let sum = 0;
    for (const review of userReviews){
        if (review.activityReviewData.steps[0].type === VitovaStepType.CARDIO){
            sum += 1;
        }
    }
    return sum;
}

export function getNumberOfStrengthSessions(user:User){
    const userReviews = user.vitovaReviews;
    let sum = 0;
    for (const review of userReviews){
        if (review.activityReviewData.steps[0].type === VitovaStepType.STRENGTH){
            sum += 1;
        }
    }
    return sum;
}

export function getWeeklyAverageAlcoholValue(user: User, weekIndex: number): number {
    const { drinkingData } = user;
    if (!drinkingData || drinkingData.length === 0) return -1;

    const startIndex = weekIndex * 7;
    const weeklyData = drinkingData.slice(startIndex, startIndex + 7);

    if (weeklyData.length === 0) return -1; // No data for the given week

    const total = weeklyData.reduce((sum, entry) => sum + Number(entry.value), 0);
    return total / weeklyData.length;
}

export function getWeeklyAverageSmokingValue(user: User, weekIndex: number): number {
    const { smokingData } = user;
    if (!smokingData || smokingData.length === 0) return -1;

    const startIndex = weekIndex * 7;
    const weeklyData = smokingData.slice(startIndex, startIndex + 7);

    if (weeklyData.length === 0) return -1; // No data for the given week

    const total = weeklyData.reduce((sum, entry) => sum + Number(entry.value), 0);
    return total / weeklyData.length;
}

export function getWeightValue(user: User, weekIndex: number): number {
    const { weightData } = user;
    if (!weightData || weightData.length === 0){
        if (weekIndex === 0){
            return user.info.weight;
        }
        else{
            return -1;
        }
    }

    return Number(weightData[weekIndex].value);
}

export function getNumberOfFlexibilitySessions(user:User){
    const userReviews = user.vitovaReviews;
    let sum = 0;
    for (const review of userReviews){
        if (review.activityReviewData.steps[0].type === VitovaStepType.FLEXIBILITY){
            sum += 1;
        }
    }
    return sum;
}

function formatConditions(user:User): string {
    const conditionNames = user.info.conditions.filter(c=>c.checked===true).map((condition:CheckedCondition) => condition.condition.name);
  
    if (conditionNames.length === 0) {
      return ''; // No conditions, return an empty string or a fallback message
    } else if (conditionNames.length === 1) {
      return `${conditionNames[0]}`;
    } else if (conditionNames.length === 2) {
      return `${conditionNames[0]} and ${conditionNames[1]}`;
    } else {
      const allButLast = conditionNames.slice(0, -1).join(', ');
      const last = conditionNames[conditionNames.length - 1];
      return `${allButLast}, and ${last}`;
    }
  }

function generateOutcomeLetter(user:User) {
    // Document content
    const doc = new Document({
        sections: [
            {
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({ text: `VITOVA LTD\n`, bold: true, size: 28 }),
                        ],
                        spacing: { after: 0 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("42 West Street,\nDevon"),
                        ],
                        spacing: { after: 0 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("info@vitovaltd.com"),
                        ],
                        spacing: { after: 0 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("+441234567890"),
                        ],
                        spacing: { after: 100 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: `Dear ${user.fullname},`, bold: true }),
                        ],
                        spacing: { after: 0 },
                    }),                    
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Congratulations on making it to your surgery date! We sincerely hope that all goes well, and now want to take a second to reflect on the positive developments you’ve made over the past ${user.currentVitovaPlan.content.length} weeks. VITOVA is designed to give you all the tools to exercise safely and effectively, benefitting your health conditions in the process.`,
                            }),
                        ],
                        spacing: { after: 100 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `On a further positive note, this does not need to be the end. VITOVA has much more functionality so you can continue to recover after your surgery, and maintain your health and wellbeing once back on your feet. If you’d like to continue then simply select from our rehabilitation, sports, or health and wellbeing plans.`,
                            }),
                        ],
                        spacing: { after: 100 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Congratulations once again on getting to this point, it takes a lot of dedication to have completed an average of ${getAverageWeeklyDurationInHours(user)} hours of activity per week, alongside your ${formatConditions(user)} health conditions. This comprised ${getNumberOfCardioSessions(user)} cardiovascular sessions, ${getNumberOfStrengthSessions(user)} strength sessions, and ${getNumberOfFlexibilitySessions(user)} flexibility sessions.`,
                            }),
                        ],
                        spacing: { after: 100 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Below are the outcomes of the program in a table to easily see just what you achieved in such a short time: `,
                            }),
                        ],
                        spacing: { after: 100 },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "Quantitative Outcomes:", bold: true, size: 24 }),
                        ],
                        spacing: { after: 50 },
                    }),
                    new Table({
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE, // Ensure the table uses the full width of the page
                        },
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph("Metric")],

                                    }),
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph("Baseline")],
                                    }),
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph("Pre-Surgery")],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph("Weight")],

                                    }),
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph(user.info.weight.toString())],
                                    }),
                                    new TableCell({
                                        width: { size: 100/3, type: WidthType.PERCENTAGE },
                                        children: [new Paragraph((user.info.weight*0.8).toString())],
                                    }),
                                ],
                            }),
                        ],
                    })
                    ,
                
                    new Paragraph({
                        children: [
                            new TextRun({ text: "Qualitative Outcomes:", bold: true, size: 24 }),
                        ],
                        spacing: { after: 0 , before: 100},
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Engagement in social activities: ${10}`,
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Engagement in social activities: ${10}`,
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Engagement in social activities: ${10}`,
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Engagement in social activities: ${10}`,
                            }),
                        ],
                    }),
                    // Add more paragraphs for other qualitative outcomes
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Hopefully these successes offer some lasting and continued benefits to yourself, and demonstrate the benefits of our exercise referral scheme. Please don’t hesitate to get in contact in future, and share your story with others. Thank you again for your support in the scheme. `,
                            }),
                        ],spacing:{after:100, before:100}
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Sincerely, `,
                            }),
                        ],spacing:{after:50}
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `VITOVA LTD `,
                            }),
                        ],spacing:{after:0}
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `Health and Exercise Solutions `,
                            }),
                        ],spacing:{after:0}
                    }),
                ],
            },
        ],
    });

    // Generate the Word document as a blob
    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "OutcomeLetter.docx"); // Trigger download
    });
}

export default generateOutcomeLetter