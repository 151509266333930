import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { User } from './models/user';
import * as Api from "./network/api";
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Header from './components/Header';
import Footer from './components/Footer';
import PlansPage from './pages/PlansPage';
import ProfilePage from './pages/ProfilePage';
import BackgroundWrapper from './BackgroundWrapper';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardPage from './pages/DashboardPage';
import BlogPage from './pages/BlogPage';
import WorkoutBuilderPage from './pages/WorkoutBuilderPage';
import ConnectPage from './pages/ConnectPage';
import StoryPage from './pages/StoryPage';
import ContactPage from './pages/ContactPage';
import DatabasePage from './pages/DatabasePage';
import GPDashboardPage from './pages/GPDashboardPage';
import HospitalTrialSplashPage from './pages/HospitalTrialSplashPage';
import VerifyEmail from './pages/VerifyEmail';
import PreferencesPage from './pages/PreferencesPage';

function App() {
    const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        async function fetchLoggedInUser() {
            try {
                const user = await Api.getLoggedInUser();
                setLoggedInUser(user);
            } catch (error) {
                console.error(error);
            }
        }
        fetchLoggedInUser();
    }, []);

    useEffect(() => {
        const checkMobile = () => {
            const userAgent = navigator.userAgent.toLowerCase();
            const mobileKeywords = ['android', 'iphone', 'ipad', 'ipod', 'windows phone'];
            setIsMobile(mobileKeywords.some(keyword => userAgent.includes(keyword)));
        };
        checkMobile();
    }, []);

    if (isMobile) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                fontSize: '1.5rem',
                padding: '20px',
                backgroundColor: '#f8f9fa'
            }}>
                Please use a desktop for the best viewing experience.
            </div>
        );
    }

    return (
        <Router>
        <BackgroundWrapper>
            <div className="app">
            <Header user={loggedInUser} onLogoutSuccessful={() => setLoggedInUser(null)} />
            <main className="content">
                <Routes>
                {/* Always accessible routes */}
                <Route path="/profile/:id" element={<ProfilePage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/story" element={<StoryPage />} />
                <Route path="/contact" element={<ContactPage />} />

                {/* Routes for logged-in users */}
                {loggedInUser ? (
                    <>
                    <Route path="/" element={loggedInUser.info ? <DashboardPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} /> : <HomePage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/home" element={<HomePage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/dashboard" element={<DashboardPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/plans" element={<PlansPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/workoutbuilder" element={<WorkoutBuilderPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/database" element={<DatabasePage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/gpdashboard" element={<GPDashboardPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/connect" element={<ConnectPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/verify-email" element={<VerifyEmail />} />
                    <Route path="/preferences" element={<PreferencesPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    </>
                ) : (
                    <>
                    {/* Routes for non-logged-in users */}
                    <Route path="/" element={<HospitalTrialSplashPage />} />
                    <Route path="/home" element={<HomePage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/dashboard" element={<DashboardPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/gpdashboard" element={<GPDashboardPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    <Route path="/connect" element={<ConnectPage loggedInUser={loggedInUser} updateUser={setLoggedInUser} />} />
                    </>
                )}
                </Routes>
            </main>
            <Footer />
            </div>
        </BackgroundWrapper>
        </Router>

    );
}

export default App;
