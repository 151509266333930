import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";

const DisclaimerPopup: React.FC = () => {
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", textAlign: "center" }}>
                Important Disclaimer
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: 2 }}>
                    <Typography variant="body1" gutterBottom>
                        <strong>Please ensure that you are physically and medically fit to exercise.</strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Do not exercise if you are not in a medically fit state. Consult your GP or a registered 
                        healthcare professional to be medically cleared for physical activity.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <strong>VITOVA aims to minimize the risks of physical activity;</strong> however, 
                        participating in exercise is not a risk-free activity.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        By using VITOVA, you acknowledge that you participate at your own risk.
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        You agree to release and discharge VITOVA from any and all medico-legal claims 
                        or causes of action, known or unknown, arising from activities with VITOVA.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="primary" fullWidth>
                    Acknowledge
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisclaimerPopup;
