import React from 'react';
import colorPalette from '../utils/colors';
import generateOutcomeLetter, { getNumberOfCardioSessions, getNumberOfFlexibilitySessions, getNumberOfStrengthSessions, getWeeklyAverageAlcoholValue, getWeeklyAverageSmokingValue, getWeightValue } from '../utils/OutcomeLetterGenerator';
import { User } from '../models/user';

interface EndOfPlanReportProps {
    currentUser: User; // Explicitly type the currentUser prop
  }
  
  const EndOfPlanReport: React.FC<EndOfPlanReportProps> = ({ currentUser }) => {

  return (
    <div style={{ width: '100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif', padding: '2vh' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight: 'bold', fontStyle: 'italic', color:colorPalette.steelBlue

      }}>
        END OF PLAN REPORT
      </h2>

      {/* Mug Material Style Table */}
      <div style={{ overflowX: 'auto' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            backgroundColor: '#fff',
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: colorPalette.steelBlue,
                  color: 'white',
                  padding: '12px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderTopLeftRadius: '8px',
                  borderBottom: '2px solid #ddd',
                }}
              >
                Metric
              </th>
              <th
                style={{
                  backgroundColor: colorPalette.steelBlue,
                  color: 'white',
                  padding: '12px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderBottom: '2px solid #ddd',
                }}
              >
                Baseline
              </th>
              <th
                style={{
                  backgroundColor: colorPalette.steelBlue,
                  color: 'white',
                  padding: '12px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  borderTopRightRadius: '8px',
                  borderBottom: '2px solid #ddd',
                }}
              >
                Pre-Surgery
              </th>
            </tr>
          </thead>
          <tbody>
            {/* WEIGHT */}
            <tr>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Weight
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {getWeightValue(currentUser, 0).toString().replace('-1', 'No Data')}
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {getWeightValue(currentUser, currentUser.currentVitovaPlan.content.length-1).toString().replace('-1', 'No Data')}
              </td>
            </tr>
            {/* SMOKING */}
            <tr>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Smoking (Weekly Average)
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {Math.round(getWeeklyAverageSmokingValue(currentUser, 0)).toString().replace('-1', 'No Data')}
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {Math.round(getWeeklyAverageSmokingValue(currentUser, currentUser.currentVitovaPlan.content.length-1)).toString().replace('-1', 'No Data')}
                </td>
            </tr>
            {/* ALCOHOL */}
            <tr>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                Alcohol (Weekly Average)
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {Math.round(getWeeklyAverageAlcoholValue(currentUser, 0)).toString().replace('-1', 'No Data')}
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'center',
                  borderBottom: '1px solid #ddd',
                }}
              >
                {Math.round(getWeeklyAverageAlcoholValue(currentUser, currentUser.currentVitovaPlan.content.length-1)).toString().replace('-1', 'No Data')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Qualitative Outcomes */}
      <div style={{ marginTop: '3vh', display:'flex', justifyContent:'flex-start', flexDirection:'column', alignItems:'flex-start'}}>
        <h3 style={{ fontWeight: 'bold', fontSize: '20px', color:colorPalette.steelBlue, fontStyle:'italic'}}>QUALITATIVE OUTCOMES</h3>
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
          <li style={{ fontSize: '16px', marginBottom: '5px', textAlign:'left'}}>
            {`Completed ${getNumberOfCardioSessions(currentUser)} CARDIO sessions`}
          </li>
          <li style={{ fontSize: '16px', marginBottom: '5px' , textAlign:'left'}}>
          {`Completed ${getNumberOfStrengthSessions(currentUser)} STRENGTH sessions`}
          </li>
          <li style={{ fontSize: '16px', marginBottom: '5px' , textAlign:'left'}}>
          {`Completed ${getNumberOfFlexibilitySessions(currentUser)} FLEXIBILITY sessions`}
          </li>
        </ul>
      </div>
        {/* Button to trigger document generation */}
        <div style={{ textAlign: 'center', marginBottom: '2vh' }}>
        <button
          style={{
            backgroundColor: colorPalette.steelBlue,
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          onClick={() => generateOutcomeLetter(currentUser)}
        >
          DOWNLOAD DOCUMENT
        </button>
      </div>
    </div>
  );
};

export default EndOfPlanReport;
