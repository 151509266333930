import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/DatabasePage.css';
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import BodyHighlighterDiagram from "./BodyHighlighter";
import { Button } from "react-bootstrap";
import { IExerciseData } from "react-body-highlighter";
import Select from 'react-select';
import colorPalette from "../utils/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faHeartPulse, faMinusCircle, faPills, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Exercise, VitovaExerciseCategory, VitovaExerciseEquipmentNeeded, VitovaExerciseForceType, VitovaExerciseLevel, VitovaExerciseMechanic } from "../models/exercise";
import { VitovaStepType } from "../models/vitovaPlan";
import { EMPTY_EXERCISE } from "./templateObjects/ObjectTemplates";

interface DatabasePageLoggedInProps {
    user: User,
    onExerciseSelected: (exercise:Exercise) => void
}




const DatabaseComponentView = ({ user, onExerciseSelected }: DatabasePageLoggedInProps) => {
    const [loading, setLoading] = useState(true);
    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const [exercises, setExercises] = useState<Exercise[]>([]);

    const [isEditing, setIsEditing] = useState(false); // Flag to check if editing
    const [editIndex, setEditIndex] = useState<number | null>(null); // Store index of the exercise being edited
    const [currentStep, setCurrentStep] = useState(0); // Track current step in multi-step process
    const [newExercise, setNewExercise] = useState<Exercise>({...EMPTY_EXERCISE});


    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        getExercises();
        setTimeout(() => {
            setLoading(false);
            setFilteredExercises(exercises as Exercise[]); // Type assertion to cast exercises as Exercise[]
        }, 1000); // Simulating a network request delay
    }, []);


    async function getExercises(){
        const response = await Api.getExercises().then(res=>{
            setExercises(res);
            setFilteredExercises(res)
            console.log(res)
        });
        
    }
    const handleSearch = (value: string = searchTerm) => {
        // Use 'value' directly instead of 'searchTerm' since setSearchTerm is async
        setSearchTerm(value);
    
        const filtered = exercises.length > 0 ? exercises.filter((exercise: Exercise) =>
            exercise.name.toLowerCase().trim().includes(value.toLowerCase().trim())
        ) : [];

        setFilteredExercises(filtered);
    };
    return (
        <div className="database-page-container" style={{height:'100%', padding:'10px',overflowY:'scroll', backgroundColor:'transparent',maxHeight:'67vh'}}>
            {loading ? (
                <Spinner text="Loading Database" />
            ) : 
            (
                <div style={{height:'auto', minHeight:'100vh', display:'flex', flexDirection:'column', }}>
                    {/* <h1 className="database-page-header" style={{fontWeight:'normal'}}><b>VITOVA</b> ADAPT</h1> */}
                        <div>
                        {/* Search Bar */}
                        <input
                            type="text"
                            placeholder="Search exercises..."
                            value={searchTerm}
                            className="database-page-search-bar"
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                        {/* Exercise List */}
                        <ul className="database-page-exercise-list" >
                            {filteredExercises.slice(0, 25).map((exercise, index) => (
                                // <li key={exercise.id} className="database-page-exercise-item" onClick={() => editExercise(index)}>
                                    <div className="exercise-card" onClick={() => onExerciseSelected(exercise)}>
                                        {/* Display the exercise image if available */}
                                        {exercise.images && exercise.images.length > 0 ?
                                            <div className="exercise-card-image">
                                                <img     
                                                    src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${exercise.images[0]}`} 
                                                    alt={exercise.name} 
                                                    />
                                            </div>
                                            :                                            
                                            <div className="exercise-card-image">
                                            <img     
                                                src={`https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930`} 
                                                alt={exercise.name} 
                                                />
                                            </div>
                                        }
                                        <div className="exercise-card-content">
                                            <h3 style={{ fontWeight:'bold', fontStyle:'italic', fontSize:'1.25vw'}} className="exercise-card-title">{exercise.name.toUpperCase()}</h3>
                                            {/* <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Force:</strong> {exercise.force.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Level:</strong> {exercise.level.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Equipment:</strong> {exercise.equipment.toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Primary Muscles:</strong> {exercise.primaryMuscles.join(', ').toUpperCase()}</p>
                                            <p style={{ fontWeight:'bold', fontStyle:'italic'}}><strong>Category:</strong> {exercise.category.toUpperCase()}</p> */}
                                        </div>
                                    </div>
                                // </li>
                            ))}
                        </ul>

                    </div>
                </div>

            )}
        </div>
    );
};

export default DatabaseComponentView;
