import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from "@mui/material";
import { Exercise } from "../models/exercise";
import { VitovaActivityStep } from "../models/vitovaPlan";
import colorPalette from "../utils/colors";

interface ExercisePopupProps {
    exercises: Array<Exercise>;
    exercise: Exercise | undefined;
    isOpen: boolean;
    handleClose: () => void;
    step: VitovaActivityStep | undefined;
    index: number;
    setExercise: (exercise: Exercise) => void;
}

const ExercisePopup: React.FC<ExercisePopupProps> = ({ exercises, exercise, isOpen, handleClose, step, index, setExercise }) => {

    // Function to find the exercise by ID
    const findExerciseById = (id: string) => {
        const foundExercise = exercises.find(e => e.id === id);
        if (foundExercise) {
            setViewingExercise(foundExercise);
            console.log("Finding exercise with ID:", id);
        }
    };

    // State for viewing exercise
    const [viewingExercise, setViewingExercise] = useState<Exercise | undefined>(exercise);

    // UseEffect to update viewingExercise when exercise changes
    useEffect(() => {
        if (exercise) {
            findExerciseById(exercise.id.toString());
        }
    }, [exercise]); // Dependency on exercise

    // Return early if viewingExercise is undefined
    if (!viewingExercise) return <></>;

    // Handle Regression logic (loop until a valid exercise is found)
    const handleRegression = () => {
        if (viewingExercise?.regression && viewingExercise.regression.length > 0) {
            // Loop through regression exercises
            for (let i = 0; i < viewingExercise.regression.length; i++) {
                const exerciseId = viewingExercise.regression[i];
                const foundExercise = exercises.find(e => e.id === exerciseId);

                // If a valid exercise is found, set it as the current exercise and exit the loop
                if (foundExercise) {
                    setViewingExercise(foundExercise);
                    break; // Stop the loop once we find the first valid exercise
                }
            }
        }
    };

    // Handle Progression logic (loop until a valid exercise is found)
    const handleProgression = () => {
        if (viewingExercise?.progression && viewingExercise.progression.length > 0) {
            // Loop through progression exercises
            for (let i = 0; i < viewingExercise.progression.length; i++) {
                const exerciseId = viewingExercise.progression[i];
                const foundExercise = exercises.find(e => e.id === exerciseId);

                // If a valid exercise is found, set it as the current exercise and exit the loop
                if (foundExercise) {
                    setViewingExercise(foundExercise);
                    break; // Stop the loop once we find the first valid exercise
                }
            }
        }
    };


    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle sx={{ fontWeight: "bold", color: colorPalette.steelBlue, fontStyle: 'italic', textAlign: "center" }}>
                {viewingExercise.name.toUpperCase()}
            </DialogTitle>
            <DialogContent>
                <Box>
                    {viewingExercise.instructions && viewingExercise.instructions.map((i, idx) => (
                        <Typography key={idx} variant="body1" sx={{ mb: 2 }}>
                            {`${idx + 1}. ${i}`}
                        </Typography>
                    ))}
                    
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 1, overflow: "hidden", width: "100%" }}>
                        <Button onClick={handleRegression} disabled={!viewingExercise?.regression?.length} variant="contained" sx={{ flexShrink: 0 }}>
                            Something Easier
                        </Button>
                        
                        {viewingExercise?.images && viewingExercise.images.length > 0 && (
                            <Box sx={{ display: "flex", flexWrap: "nowrap", overflowX: "auto", maxWidth: "70%", justifyContent: "center" }}>
                                {viewingExercise.images.map((image, idx) => (
                                    <img key={idx} 
                                         src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${image}`} 
                                         alt={`Exercise step ${idx + 1}`} 
                                         style={{ maxWidth: "60%", overflow: 'hidden', height: "auto", borderRadius: 8, margin: "0 5px", objectFit: 'cover' }} />
                                ))}
                            </Box>
                        )}

                        <Button onClick={handleProgression} disabled={!viewingExercise?.progression?.length} variant="contained" sx={{ flexShrink: 0 }}>
                            Something Harder
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {exercise?.video && (
                    <button style={{ marginRight: '5px', alignSelf: 'center' }} className="review-button" 
                            onClick={() => window.open(viewingExercise.video, "_blank")}>
                        <span style={{ color: 'steelblue', fontStyle: 'italic', fontSize: '1vw', marginTop: 0, paddingTop: 0 }}>
                            WATCH ON YOUTUBE
                        </span>
                    </button>
                )}
                <Button onClick={handleClose} variant="contained" color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExercisePopup;
