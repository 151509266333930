import { useState, useEffect } from "react";
import {
    Table, TableRow, TableCell, Button, TableBody, TableHead, TableContainer, Paper, TextField
} from "@mui/material";
import { fetchYouTubeData } from "../network/api"; // Adjust path as needed
import Spinner from "./Spinner";
import { Search } from "@mui/icons-material";

interface Workout {
    'NAME': string,
    'VIDEO': string,
    'Workout Duration': string,
    "Session ISS": string,
    "Intensity Factor": string,
    "Tags": string,
    "Home Workout Swap?": string
}

interface YouTubeWorkout extends Workout {
    thumbnail: string;
    duration: string;
    views: string;
    likes: string;
    channelTitle: string;
}

const YouTubeWorkoutsTable = ({ workouts }: { workouts: Workout[] }) => {
    const [youtubeWorkouts, setYoutubeWorkouts] = useState<YouTubeWorkout[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchWorkouts = async () => {
            try {
                const workoutData = await Promise.all(
                    workouts.map(async (workout) => {
                        try {
                            const youtubeData = await fetchYouTubeData(workout.VIDEO);
                            return { ...workout, ...youtubeData }; // Merge workout & YouTube data
                        } catch (error) {
                            console.error(`Error fetching video data for ${workout.VIDEO}`, error);
                            return null;
                        }
                    })
                );

                setYoutubeWorkouts(workoutData.filter((w) => w !== null) as YouTubeWorkout[]);
            } catch (error) {
                console.error("Error fetching YouTube workouts:", error);
            }
        };

        fetchWorkouts();
    }, [workouts]);

    const filteredWorkouts = youtubeWorkouts.filter((workout) =>
        workout.Tags.toLowerCase().includes(searchTerm.toLowerCase()) || workout.NAME.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        youtubeWorkouts.length > 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '20px' }}>
                <TextField
                    variant="outlined"
                    placeholder="Search by tags..."
                    value={searchTerm}
                    
                    style={{width:'100%'}}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <Search sx={{ marginRight: 1 }} />,
                    }}
                    sx={{ marginBottom: 2, width: '50%' }}
                />
                <TableContainer component={Paper} sx={{ width: '100%', boxShadow: 3, borderRadius: 2 }}>
                    <Table aria-label="youtube workouts table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Workout</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Channel</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Duration</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>ISS</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Video</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredWorkouts.map((workout) => (
                                <TableRow key={workout.VIDEO} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    <TableCell>
                                        <img
                                            src={workout.thumbnail}
                                            alt={workout.NAME}
                                            style={{ width: '120px', height: 'auto', borderRadius: '8px' }}
                                        />
                                    </TableCell>
                                    <TableCell>{workout.NAME}</TableCell>
                                    <TableCell>{workout.channelTitle}</TableCell>
                                    <TableCell>{workout["Workout Duration"]} mins</TableCell>
                                    <TableCell>{Math.round(Number(workout["Session ISS"]))}</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            href={workout.VIDEO}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                textTransform: 'none',
                                                '&:hover': { backgroundColor: '#1976d2', color: 'white' },
                                            }}
                                        >
                                            Watch Video
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        ) : (
            <div style={{ display: 'flex', width: '100%', padding: '20px', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner text="LOADING VIDEOS" />
            </div>
        )
    );
};

export default YouTubeWorkoutsTable;
