import { Container } from "react-bootstrap";
import { User } from "../models/user";
import HomePageLoggedOutView from "../components/HomePageLoggedOutView";
import VitovaDashboardPageLoggedInView from "../components/VitovaDashboardPageLoggedInView";

interface DashboardPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const DashboardPage = ({ loggedInUser, updateUser }: DashboardPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <VitovaDashboardPageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <HomePageLoggedOutView 
                    onSignUpSuccessful={(user) => {
                        updateUser(user);
                    }}
                    onLoginSuccessful={(user) => {
                        updateUser(user);
                    }}
                    />
                }
            </>
        </Container>
    );
}

export default DashboardPage;