import React, { useState } from 'react';
import Slider from "react-slick";
import '../styles/SplashPage.css'

import img1 from '../running.jpg'
import img2 from '../swimming.jpg'
import img3 from '../cycling.jpg'
import colorPalette from '../utils/colors';

import * as Api from '../network/api'
import { useNavigate } from 'react-router-dom';

const HospitalTrialSplashPage: React.FC = () => {

    const [emailInput, setEmailInput] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    const navigate = useNavigate();

    async function subscribe(){
        const credentials:Api.SubscribeCredentials = {email:emailInput};

        await Api.subscribe(credentials)
        setSubscribed(true);
    }

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            overflowY: 'scroll',
            scrollSnapType: 'y mandatory'
          }}>
            
            {/* FIRST VIEW */}
            <div style={{
              display: 'flex',
              width: '100%',
              backgroundColor:'#f3f3f300',
              height: '80vh',
              justifyContent: 'center',
              alignItems: 'center',
              scrollSnapAlign: 'start'
            }}>
              <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                backgroundColor: '#f3f3f300',
                flex: 1,
                overflow: 'visible',
                zIndex: 100
              }}>
                {/* <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#d1d1d100',
                  position: 'absolute',
                  marginLeft: '2vw',
                  marginTop: '2vw'
                }}>
                  <img alt='' src={''} width='100%' height='100%' style={{ zIndex: 1, objectFit: 'cover' }} />
                </div>
                <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#b1b1b100',
                  position: 'absolute',
                  top: '13vw',
                  left: '15vw',
                  marginLeft: '2vw',
                  marginTop: '2vw'
                }}>
                  <img alt='' src={''} width='100%' height='100%' style={{ zIndex: 2, objectFit: 'cover' }} />
                </div>
                <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f1f1f100',

                  position: 'absolute',
                  top: '22vw',
                  left: '28vw',
                  marginLeft: '2vw',
                  marginTop: '2vw'
                }}>
                  <img alt='' src={''} width='100%' height='100%' style={{ zIndex: 3, objectFit: 'cover' }} />
                </div> */}
              </div>
              <div 
                className='title-div'
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    marginBottom: '15vh',
                    marginLeft: '0',
                    background: 'linear-gradient(to right, #f3f3f300, #ffffff00)',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
                >
                {/* <label className='label-title' style={{
                    fontSize: '6vw',
                    fontStyle:'italic',
                    lineHeight:1,
                    color:'#f1f1f1',
                    padding:0,
                    margin:0
                }}>VITOVA</label> */}
                                <img 
                style={{
                    height: '5vw',
                    objectFit: 'cover',
                    marginBottom: '15px',
                    filter: 'brightness(0) saturate(100%) invert(100%)',

                }}
                src={"/png/headerlogo.png"}
                alt="Logo" 
                />
                <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    color:'#f1f1f1',
                    lineHeight:1,
                    padding:0,
                    margin:0
                }}>HEALTHCARE SOLUTIONS</label>
                <p style={{
                    fontSize: '1em',
                    color: '#f1f1f1',
                    padding:'20px',
                    fontStyle:'italic',
                    textAlign: 'center',
                    maxWidth:'500px',
                    width: '80%' // Adjust width as needed
                }}>Here to make <b>health</b> accessible. <b>Evidence based</b>, medically assured <b>adaptive</b> training plans for your health, fitness and wellbeing goals.
                </p>
                {/* <label 
                className='read-more-button'
                style={{
                    fontSize: '1em',
                    color: '#f1f1f1',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    padding:'10px 20px',
                    backgroundColor:'steelblue'
                }}>
                    Learn More About Us
                </label> */}
                {/* <label 
                style={{
                    fontSize: '1em',
                    color: '#454545',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    padding:'10px 20px',
                }}>
                    Or
                </label> */}
                <div className='subscribe-div' style={{backgroundColor:'transparent', display:'flex', width:'auto', zIndex:100, borderRadius:'5px',padding:'10px 0px 20px 0px',justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <label 
                style={{
                    fontSize: '1em',
                    color: '#f1f1f1',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    maxWidth:'500px',

                    padding:'10px 20px',
                }}>
                    We're Currently Conducting A Closed System Trial
                </label>
                <div style={{display:'flex', flex:1, flexDirection:'row', maxHeight:'5vh'}}>
                    {!subscribed && 
                    <input className='email-input' value={emailInput} onChange={(e)=>setEmailInput(e.currentTarget.value)} style={{padding:'8px 5px', color:'#454545', backgroundColor:'#ffffff99', borderTopLeftRadius:'5px', borderBottomLeftRadius:'5px'}} type='email' placeholder='Your Access Code'></input>
                    }
                    <label 
                    onClick={() => emailInput.toLowerCase() === 'mawc2025' ? navigate('/home') : alert('Access Denied: Wrong Code')}
                    className='subscribe-button'
                    style={{
                        pointerEvents:subscribed ? 'none':'all',
                        fontSize: '1em',
                        textAlign: 'center',
                        width: 'auto',
                        margin:0,
                    }}>
                        {`Access Vitova`}
                    </label>
                </div>
                </div>


                </div>

              
            </div>
        </div>
          

    );
}

export default HospitalTrialSplashPage;
