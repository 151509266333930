import { ConflictError, UnauthorizedError } from "../errors/http_errors";
import { ActivityStep, Plan } from "../models/plan";
import { Exercise } from "../models/exercise";
import { InboxMessage, User } from "../models/user";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";
import { VitovaPlan } from "../models/vitovaPlan";
import { DBMuscle } from "../models/muscle";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    const response = await fetch(input, init);
    if (response.ok) {
        return response;
    } else {
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        if (response.status === 401) {
            throw new UnauthorizedError(errorMessage);
        } else if (response.status === 409) {
            throw new ConflictError(errorMessage);
        } else {
            throw Error("Request failed with status: " + response.status + " message: " + errorMessage);
        }
    }
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/users", { method: "GET" });
    return response.json();
}

export async function getUserById(userId: string): Promise<User> {
    const response = await fetchData(`/api/users/${userId}`, { method: "GET" });
    if (!response.ok) {
        throw new Error(`Failed to fetch user with ID: ${userId}`);
    }
    return response.json();
}

export interface GetPatientsBody {
    gpReferralCode:string,
}

export async function getPatients(getPatientsBody: GetPatientsBody): Promise<Array<User>> {
    const response = await fetchData("/api/users/getReferredPatients", {
        method: "POST", // Use POST for sending the body
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(getPatientsBody), // Send the request body as JSON
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    return response.json();
}

export interface VerifyEmailBody {
    token:string, 
    userId:string,
}

export async function verifyEmail(verifyEmailBody:VerifyEmailBody) {
    const response = await fetch("/api/users/verify-email", {
        method: "POST", // Using POST for the email verification
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(verifyEmailBody), // Sending the token and userId in the body
    });

    // Check if the response status is OK (200)
    if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    // Return the response as JSON
    return response.json();
}

export interface SignUpCredentials {
    fullname:string,
    email: string,
    password: string,
    confirmPassword?:string,
    accountType:string,
    GPAccount:boolean,
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
    const response = await fetchData("/api/users/signup",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface SubscribeCredentials {
    email: string,
}

export async function subscribe(credentials: SubscribeCredentials): Promise<User> {
    const response = await fetchData("/api/users/subscribe",
        {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface LoginCredentials {
    username: string,
    password: string,
}

export async function login(credentials: LoginCredentials): Promise<User> {
    const response = await fetchData("/api/users/login",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        });
    return response.json();
}

export interface AuthorizeStravaCredentials {
    code:string
}

export interface AuthorizationStravaResponse{
    access_token:string
}

export async function authorizeStrava(body: AuthorizeStravaCredentials): Promise<AuthorizationStravaResponse> {
    const response = await fetchData("/api/users/authorizestrava",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to authorize with Strava: ${errorData.error || response.statusText}`);
    }
    return response.json();
}


export async function fetchStravaActivities(access_token: string): Promise<any> {
    const response = await fetchData("/api/users/fetchstravaactivities", {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
    });
    return response.json();
}


export async function logout() {
    await fetchData("/api/users/logout", { method: "POST" });
}

export interface Image {
    imageURL:string,
}

export async function uploadProfileImage(input: string, key:string, imageType:string): Promise<Image> {
    const body = {
        data:input,
        key:key,
        imageType:imageType,
    }
    const response = await fetchData("/api/interviews/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
    return response.json();
}

// interface Effort{
//     id:number,
//     type:string,
//     time:string,
//     avgHeartRate:number,
// }

export interface UserInput {
    user: User,
}


export async function updateUser(userId: string, user: User): Promise<User> {
    const response = await fetchData("/api/users/" + userId,
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(user),
        });
    return response.json();
}

interface AdaptPlanLengthBody{
    currentVitovaPlan:VitovaPlan,
    surgeryDate:Date,
}

export async function adaptPlanLength(userId: string, planData: AdaptPlanLengthBody): Promise<User> {
    const response = await fetch("/api/users/" + userId + "/adapt-plan", {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(planData),
    });

    if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Request failed: ${response.status} ${response.statusText} - ${errorData}`);
    }

    return response.json();
}


export async function updateMessage(
    gpReferralCode: string, 
    messageId: string, 
    message: InboxMessage
): Promise<InboxMessage> {
    const response = await fetchData(`/api/users/updatemessage/${gpReferralCode}/${messageId}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ message }), // Wrap the message object in the expected API structure
    });
    if (!response.ok) {
        throw new Error(`Failed to update message: ${response.statusText}`);
    }
    return response.json();
}


export async function sendMessage(gpReferralCode: string, message: InboxMessage): Promise<Array<InboxMessage>> {
    const response = await fetchData("/api/users/sendmessage/" + gpReferralCode,  // Added "/" before gpReferralCode
        {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                message:message
            }),
        });
    return response.json();
}

interface SendVerificationBody {
    userId:string,
    email:string
}

export async function sendVerificationEmail(body:SendVerificationBody): Promise<void> {
    await fetchData("/api/users/sendverification", // Endpoint for sending the verification email
        {
            method: "POST", // Typically, POST is used for sending email actions
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
}


export async function createPlan(planData: Plan): Promise<Plan> {
    const response = await fetchData("/api/plans/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(planData),
        });
    return response.json();
}

export async function createVitovaPlan(planData: VitovaPlan): Promise<VitovaPlan> {
    const response = await fetchData("/api/vitovaplans/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(planData),
        });
    return response.json();
}
export interface getActivityStepData {
    description:string,
}


    export async function generateActivityStep(input: getActivityStepData): Promise<ActivityStep> {
        const response = await fetchData("/api/users/getActivityStep", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(input), // Wrap inputString in an object and stringify it
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

export async function getPlans(): Promise<Array<Plan>> {
    const response = await fetchData("/api/plans", { method: "GET" });
    return response.json();
}
export async function getVitovaPlans(): Promise<Array<VitovaPlan>> {
    const response = await fetchData("/api/vitovaplans", { method: "GET" });
    return response.json();
}
export async function getExercises(): Promise<Array<Exercise>> {
    const response = await fetchData("/api/exercises", { method: "GET" });
    return response.json();
}

export async function createExercise(exerciseData: Exercise): Promise<Exercise> {
    const response = await fetchData("/api/exercises/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(exerciseData),
        });
    return response.json();
}

export async function getMedications(): Promise<Array<DBMedication>> {
    const response = await fetchData("/api/medications", { method: "GET" });
    return response.json();
}

export async function getNHSMedications(): Promise<Array<object>> {
    const response = await fetchData("/api/medications/nhs", { method: "GET" });
    return response.json();
}


export async function createMedication(medicationData: DBMedication): Promise<DBMedication> {
    const response = await fetchData("/api/medications/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(medicationData),
        });
    return response.json();
}

export async function getMuscles(): Promise<Array<DBMuscle>> {
    const response = await fetchData("/api/muscles", { method: "GET" });
    return response.json();
}

export async function createMuscle(muscleData: DBMuscle): Promise<DBMuscle> {
    const response = await fetchData("/api/muscles/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(muscleData),
        });
    return response.json();
}

export async function getConditions(): Promise<Array<DBCondition>> {
    const response = await fetchData("/api/conditions", { method: "GET" });
    return response.json();
}

export async function createCondition(conditionData: DBCondition): Promise<DBCondition> {
    const response = await fetchData("/api/conditions/upload",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(conditionData),
        });
    return response.json();
}
export interface YouTubeVideoResponse {
    title: string;
    description: string;
    thumbnail: string;
    duration: string;
    views: string;
    likes: string;
    channelTitle: string;
}

export interface YouTubeSearchVideoResponse {
    title: string;
    description: string;
    thumbnail: string;
    duration: string;
    views: string;
    likes: string;
    channelTitle: string;
    videoUrl: string;
}


export async function fetchYouTubeData(videoUrl: string): Promise<YouTubeVideoResponse> {
    const response = await fetch(`/api/youtube/video?url=${encodeURIComponent(videoUrl)}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching YouTube data: ${response.statusText}`);
    }

    return response.json();
}


export async function searchYouTubeVideo(query: string): Promise<YouTubeSearchVideoResponse> {
    const response = await fetch(`/api/youtube/search?query=${encodeURIComponent(query)}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error(`Error searching YouTube: ${response.statusText}`);
    }

    return response.json();
}

export async function searchExerciseImage(query: string): Promise<string> {
    const response = await fetch(`/api/images/search?query=${encodeURIComponent(query)}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
        throw new Error(`Error searching for an image: ${response.statusText}`);
    }

    const data = await response.json();
    return data.imageUrl;
}

export async function generateExerciseImage(exercise: string): Promise<string> {
    const response = await fetch(`/api/images/generate?exercise=${encodeURIComponent(exercise)}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });

    if (!response.ok) {
        throw new Error(`Error generating image: ${response.statusText}`);
    }

    const data = await response.json();
    return data.imageUrl;
}

export async function removeExercise(exerciseId: string): Promise<{ message: string }> {
    const response = await fetchData("/api/exercises/remove", {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: exerciseId }),
    });

    return response.json();
}
