import * as Api from "../network/api";
import { CheckboxDataObject, ChecklistDataObject, DataObject, Effort, Goal, InboxMessage, Review, StravaActivity, Test, User, VitovaReview } from '../models/user';
import '../styles/DashboardPage.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {fa1, fa2, fa3, faAdjust, faArrowLeft,faArrowRight,faArrowsTurnRight,faBan,faBanSmoking,faBarsProgress,faBed,faBeer,faBeerMugEmpty,faBicycle,faBorderNone,faBowlFood,faBullseye,faC,faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCalendarWeek, faCaretSquareRight, faChartLine, faCheck, faChevronLeft, faChevronRight, faClock, faCross, faDownload, faDumbbell, faEllipsis, faExclamationCircle, faExpandArrowsAlt, faFlag, faGripLinesVertical, faHeart, faHeartbeat, faHeartPulse, faHiking, faHouse, faInbox, faList, faListCheck, faLongArrowAltRight, faM, faMinus, faNeuter, faNotdef, faPerson, faPersonRunning, faPlay, faPlus, faRemove, faRightToBracket, faRobot, faRuler, faRunning, faSign, faSmile, faSmoking, faSmokingBan, faSpinner, faSwimmer, faTired, faUserDoctor, faW, faWalking, faWeight, faYen, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React, { act, useEffect, useState } from "react";
import { secondsToTimeString, timeStringToSeconds } from "../utility";
import Slider from '@mui/material/Slider';
import '../styles/SessionReview.css';
import { SliderProps } from '@mui/material/Slider'; // Ensure SliderProps is imported if necessary
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // Import uuid if not already imported
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import '../styles/CustomGrid.css'; // Make sure to include the custom CSS file
import { DayLong, Document, HeightRule, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import colorPalette from '../utils/colors';
import { Table } from "react-bootstrap";
import ChartComponent from "./ChartComponent";
import { getTotalActivityLength } from "../utils/ActivityStats";
import Spinner from "./Spinner";
import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle,FormControl, FormControlLabel, Grid, Hidden, Input, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AnimatedBarChart from "./AnimatedBarChart";
import { Bar, Line } from "react-chartjs-2";
import { LoadingIndicator } from "react-select/dist/declarations/src/components/indicators";
import DatabasePage from "../pages/DatabasePage";
import DatabaseComponentView from "./DatabaseComponentView";
import { Exercise } from "../models/exercise";
import youtubeWorkouts from '../localdata/youtubeWorkouts.json'
import generateOutcomeLetter, { getNumberOfStrengthSessions } from "../utils/OutcomeLetterGenerator";
import { getAlcoholScoreColor, getAverageAlcoholScoreColor, getAverageNutritionColor, getAverageSleepQualityColor, getAverageSmokingScoreColor, getNutritionDataColor, getSleepQualityColor, getSmokingScoreColor } from "../utils/GPFunctions";
import { VitovaActivity, VitovaActivityStep, VitovaActivityType, VitovaDay, VitovaPlan, VitovaStepType, VitovaWeek } from "../models/vitovaPlan";
import Popup from "./Popup";
import { minutesToTimeString } from "../utils/FormattingFunctions";
import { ObjectId } from 'bson'; // Import ObjectId
import GeneralChartComponent from "./GeneralChartComponent";
import { getDayStressScore, getWeekStressScore } from "../utils/StressLoadFunctions";
import WorkoutStepsDiagram from "./WorkoutStepsDiagram";
import { buildStringFromStep } from "../utils/StringBuilder";
import { getRpeColourFromStep } from "../utils/TSS";
import YouTubeWorkoutsTable from "./YoutubeWorkoutsTable";
import EndOfPlanReport from "./EndOfPlanReport";
import ComparisonChartComponent from "./ComparisonChartComponent";
import DisclaimerPopup from "./DisclaimerPopup";
import ExercisePopup from "./ExercisePopup";


interface VitovaDashboardPageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
}

const activityIcons: Record<VitovaStepType, IconDefinition> = {
    'cardio':faRunning,
    'strength': faDumbbell,
    'flexibility': faDumbbell,
    'rest': faBed,
    'choiceday': faHouse
};

const exerciseIcons: Record<string, IconDefinition> = {
    'Running':faRunning,
    'Walking': faWalking,
    'Cycling': faBicycle,
    'Swimming': faSwimmer,
    'Trail Running':faRunning
};

const VitovaDashboardPageLoggedInView = ({ user, onLogoutSuccessful }: VitovaDashboardPageLoggedInProps) => {
    const [currentUser, setCurrentUser] = useState<User>(user);
    const [selectedDay, setSelectedDay] = useState<VitovaDay |null>(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isYoutubeWorkoutPopupOpen, setIsYoutubeWorkoutPopupOpen] = useState(false);

    const handleDayClick = (day:VitovaDay) => {
            getActivityReview(day.activities[selectedActivityIndex]).then(()=>{
            setSelectedDay(day);
            setIsPopupOpen(true);
        })
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [reviewType, setReviewType] = useState('');
    const [userPlan, setUserPlan] = useState<VitovaPlan>(user.currentVitovaPlan);
    const [reviewingActivity, setReviewingActivity] = useState<VitovaActivity | null>(null);
    const [selectedReview, setSelectedReview] = useState<VitovaReview | undefined>(undefined)

    const [reviewStage, setReviewStage] = useState(0);
    const [duration, setDuration] = useState(-1);
    const [distance, setDistance] = useState(-1);

    const [pace, setPace] = useState(-1);
    const [power, setPower] = useState(-1);
    const [weight, setWeight] = useState('');
    const [waist, setWaistCircumference] = useState('');

    const [heartRate, setHeartRate] = useState(100);
    const [effort, setEffort] = useState(5);
    const [sleepQuality, setSleepQuality] = useState(5);
    const [isExercisePopupOpen, setIsExercisePopupOpen] = useState(false);
    const [popupExercise, setPopupExercise] = useState<Exercise | undefined>(undefined);
    const [popupStep, setPopupStep] = useState<VitovaActivityStep | undefined>(undefined);

    const [selectedActivityIndex, setSelectedActivityIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const [next7Days, setNext7Days] = useState<VitovaDay[]>([]);

    const [showCalendar, setShowCalendar] = useState(false);
    const [showRightPanel, setShowRightPanel] = useState(false);
    const [selectedTab, setSelectedTab] = useState('');

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [cigaretteInput, setCigaretteInput] = useState(0);
    const [loadingSmokerChange, setLoadingSmokerChange] = useState(false)

    const [alcoholInput, setAlcoholInput] = useState(0);
    const [loadingDrinkerChange, setLoadingDrinkerChange] = useState(false)

    const [sleepingInput, setSleepingInput] = useState(0);

    const [stravaActivities, setStravaActivities] = useState<Array<any>>([])

    const [nutritionChecklistQuestions, setNutritionChecklistQuestions] = useState<Array<CheckboxDataObject>>([
        {
            label: "Eaten breakfast",
            checked: false,
        },
        {
            label: "5 portions of fruit / veg per day.",
            checked: false,
        },
        {
            label: `Around ${Math.round(currentUser.info.weight * 1.2)}g (1.2 x bodyweight) of protein eaten.`,
            checked: false,
        },
        {
            label: "Wholegrain bread or brown rice and pasta used over white.",
            checked: false,
        },
        {
            label: "No more than 2 juice drinks, soft drinks, or energy drinks.",
            checked: false,
        },
    ]);

    const [nutritionInput, setNutritionInput] = useState<Array<CheckboxDataObject>>(nutritionChecklistQuestions)

    const [editingExercise, setEditingExercise] = useState<Exercise>();

    const [showFinalReport, setShowFinalReport] = useState(false);

    const [sendingMessage, setSendingMessage] = useState(false);

    const [exercises, setExercises] = useState<Exercise[]>([]);

    const [viewportSize, setViewportSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const [selectedWorkoutPart, setSelectedWorkoutPart] = useState(0)

    const [loadingAddGoal, setLoadingAddGoal] = useState(false);
    const [loadingRemoveGoal, setLoadingRemoveGoal] = useState<Goal | undefined>(undefined);

    const [tests, setTests] = useState<Array<Test>>([
        {
            name:'PHAM',
            type:'Anxiety',
            numberOfQuestions:15,
            minutesToCompleteEstimate:10,
        },
        {
            name:'Health',
            type:'Patient Health',
            numberOfQuestions:50,
            minutesToCompleteEstimate:30
        },
        {
            name:'GAD7',
            type:'Depression',
            numberOfQuestions:10,
            minutesToCompleteEstimate:15
        },
        {
            name:'IPAQ',
            type:'Physical Activity Levels',
            numberOfQuestions:30,
            minutesToCompleteEstimate:45
        },

    ])

    // Calculate today's index within the current week
    const startDate = new Date(currentUser.userPlanStartDate);
    const currentDate = new Date();
    const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);
    const todayIndex = diffInDays % 7;

    // Ensure todayIndex is within bounds
    const adjustedTodayIndex = Math.min(todayIndex, next7Days.length - 1);

    const recommendedUnits = currentUser.drinkingData.map((o: DataObject, index: number) => {
        let initialValue = Number(currentUser.drinkingData[0].value);
        let decreasedValue = initialValue * Math.pow((1 - 0.026), index);
        return decreasedValue > 2 ? decreasedValue : 2;
    });

    const [newGoal, setNewGoal] = useState({
        specific: '',
        measurable: '',
        achievable: '',
        relevant: '',
        timeBound: '',
      });

      const handleSubmitModalValue = () => {
        const newWeightValue = parseFloat(weight);
        const newWaistValue = parseFloat(waist);
        if (!isNaN(newWeightValue) && !isNaN(newWeightValue)) {
            submitWeightValue(newWeightValue);
            submitWaistCircumferenceValue(newWaistValue);
            setIsModalOpen(false);
            setWeight('');
            setWaistCircumference('');
        } else {
            alert('Please enter a valid number');
        }
    };

    const toggleCalendar = () => {
        getNext7Days();
        setShowCalendar(!showCalendar);
        setReviewingActivity(null);
        setSelectedDay(null);
        setReviewStage(0);
    };

    const toggleRightPanel = (tab?:string) => {
        if (tab){
            setSelectedTab(tab)
        }
        setShowRightPanel(!showRightPanel);
    };
    
    const handleInputChange = (field:string, value:string) => {
    setNewGoal({ ...newGoal, [field]: value });
    };

    const handleSubmit = async () => {
        setLoadingAddGoal(true);
        // Assuming there’s a function to handle adding goals
        if (
            newGoal.specific &&
            newGoal.measurable &&
            newGoal.achievable &&
            newGoal.relevant &&
            newGoal.timeBound
        ) {
            const newUser = { ...currentUser };
            const today = new Date();
            newUser.goals.push({
                date: today,
                content: { ...newGoal },
            })

            try {
                const response = await Api.updateUser(currentUser._id, newUser);
                setCurrentUser(response); // Update currentUser only after response is received
                setNewGoal({ specific: '', measurable: '', achievable: '', relevant: '', timeBound: '' });
                } catch (error) {
                console.error('Error updating user:', error);
                alert('An error occurred while updating your goals.');
                }
                

            // alert('Goal submitted for review!');
        } else {
            alert('Please fill out all fields.');
        }
        setLoadingAddGoal(false);
    };
    const handleRemoveGoal = async (goalToRemove: Goal) => {
    setLoadingRemoveGoal(goalToRemove);
    try {
        // Create a copy of the current user to avoid directly mutating state
        const updatedUser = { ...currentUser };
    
        // Filter out the goal to be removed
        updatedUser.goals = updatedUser.goals.filter(
        (goal) => goal !== goalToRemove
        );
    
        // Update the user's goals on the server
        const response = await Api.updateUser(currentUser._id, updatedUser);
    
        // Update the state with the response from the server
        setCurrentUser(response);
    
    } catch (error) {
        console.error('Error removing goal:', error);
        alert('An error occurred while removing the goal. Please try again.');
    }
    setLoadingRemoveGoal(undefined);
    };
      
    const handleSend = async () => {
        setSendingMessage(true);
        // Check if both subject and message are provided
        if (!subject || !message) {
            alert("Both subject and message are required.");
            setSendingMessage(false)
            return;
        }

        const now = new Date();
    
        const messageToSend: InboxMessage = {
            _id:uuidv4(),
            subject: subject,
            content: message,
            senderId: currentUser._id,
            dateSent: now,
            acknowledged: false
        };
    
        try {
            // Handle sending the message (e.g., API call)
            const response = await Api.sendMessage(currentUser.gpReferralCode, messageToSend);

            let newUser = {...currentUser}

            newUser.inbox = response;

            setCurrentUser(newUser);
    
            // Clear the form fields after sending the message
            setSubject('');
            setMessage('');
        } catch (error) {
            // Handle API call failure (e.g., network issue, server down)
            console.error("Error sending message:", error);
            alert("There was an issue sending the message. Please try again later.");
        }
        setSendingMessage(false);
    };

    async function submitSmokingValue() {
        if (isNaN(Number(cigaretteInput))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = { ...currentUser };
    
                // Get the user's smokingData and create a copy
                let newSmokingData = [...currentUser.smokingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(cigaretteInput),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newSmokingData, now)) {
                    newSmokingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort smokingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Default value for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newSmokingData = fillMissingDays(newSmokingData);
    
                // Update user information
                newUser.smokingData = newSmokingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }

    async function submitWeightValue(value:number) {
        try {
            let newUser = { ...currentUser };

            // Get the user's smokingData and create a copy
            let newWeightData = [...currentUser.weightData];
            const now = new Date();

            // Create new data entry for the current day
            const newValue: DataObject = {
                value: Number(value),
                date: now
            };

            // Helper to check if a date exists in the data
            const isDatePresent = (data: DataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };

            // Add today's data first
            if (!isDatePresent(newWeightData, now)) {
                newWeightData.push(newValue);
            }

            // Fill missing days if any
            const fillMissingDays = (data: DataObject[]): DataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();

                // Sort smokingData by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

                // Find the earliest recorded date or default to today
                const startDate = filledData.length
                    ? new Date(filledData[0].date)
                    : currentDate;

                // Loop through all dates from startDate to today
                const allDates = [];
                for (
                    let d = new Date(startDate);
                    d <= currentDate;
                    d.setDate(d.getDate() + 1)
                ) {
                    allDates.push(new Date(d));
                }

                // Add missing dates with default values (e.g., null)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: -1, // Default value for missing days
                            date: new Date(date)
                        });
                    }
                });

                // Sort again by date after adding missing days
                return filledData.sort(
                    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                );
            };

            // Ensure all days are accounted for
            newWeightData = fillMissingDays(newWeightData);

            // Update user information
            newUser.weightData = newWeightData;

            const response = await Api.updateUser(newUser._id, newUser);

            // Update the current user in the application state
            setCurrentUser(response);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    async function submitWaistCircumferenceValue(value:number) {
        try {
            let newUser = { ...currentUser };

            // Get the user's smokingData and create a copy
            let newWaistData = [...currentUser.waistData];
            const now = new Date();

            // Create new data entry for the current day
            const newValue: DataObject = {
                value: Number(value),
                date: now
            };

            // Helper to check if a date exists in the data
            const isDatePresent = (data: DataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };

            // Add today's data first
            if (!isDatePresent(newWaistData, now)) {
                newWaistData.push(newValue);
            }

            // Fill missing days if any
            const fillMissingDays = (data: DataObject[]): DataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();

                // Sort smokingData by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

                // Find the earliest recorded date or default to today
                const startDate = filledData.length
                    ? new Date(filledData[0].date)
                    : currentDate;

                // Loop through all dates from startDate to today
                const allDates = [];
                for (
                    let d = new Date(startDate);
                    d <= currentDate;
                    d.setDate(d.getDate() + 1)
                ) {
                    allDates.push(new Date(d));
                }

                // Add missing dates with default values (e.g., null)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: -1, // Default value for missing days
                            date: new Date(date)
                        });
                    }
                });

                // Sort again by date after adding missing days
                return filledData.sort(
                    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                );
            };

            // Ensure all days are accounted for
            newWaistData = fillMissingDays(newWaistData);

            // Update user information
            newUser.waistData = newWaistData;

            const response = await Api.updateUser(newUser._id, newUser);

            // Update the current user in the application state
            setCurrentUser(response);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }
    async function submitRetrospectiveSmokingValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedSmokingData: DataObject[] = [...updatedUser.smokingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedSmokingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedSmokingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedSmokingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.smokingData = updatedSmokingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }
    async function submitAlcoholValue() {
        if (isNaN(Number(alcoholInput))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = { ...currentUser };
    
                // Get the user's drinkingData and create a copy
                let newDrinkingData = [...currentUser.drinkingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(alcoholInput),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newDrinkingData, now)) {
                    newDrinkingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort drinkingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Default value for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newDrinkingData = fillMissingDays(newDrinkingData);
    
                // Update user information
                newUser.drinkingData = newDrinkingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }
    async function submitRetrospectiveAlcoholValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedDrinkingData: DataObject[] = [...updatedUser.drinkingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedDrinkingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedDrinkingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedDrinkingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.drinkingData = updatedDrinkingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }
    async function submitNutritionData() {
        try {
            let newUser = { ...currentUser };
    
            // Get the user's nutritionData and create a copy
            let newNutritionData = [...currentUser.nutritionData];
            const now = new Date();
    
            // Create new data entry for the current day
            const newValue: ChecklistDataObject = {
                value: nutritionInput,
                date: now
            };
    
            // Helper to check if a date exists in the data
            const isDatePresent = (data: ChecklistDataObject[], date: Date): boolean => {
                return data.some(entry => {
                    const entryDate = new Date(entry.date);
                    return (
                        entryDate.getFullYear() === date.getFullYear() &&
                        entryDate.getMonth() === date.getMonth() &&
                        entryDate.getDate() === date.getDate()
                    );
                });
            };
    
            // Add today's data first
            if (!isDatePresent(newNutritionData, now)) {
                newNutritionData.push(newValue);
            }
    
            // Fill missing days if any
            const fillMissingDays = (data: ChecklistDataObject[]): ChecklistDataObject[] => {
                const filledData = [...data];
                const currentDate = new Date();
    
                // Sort nutritionData by date (ascending)
                filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Find the earliest recorded date or default to today
                const startDate = filledData.length
                    ? new Date(filledData[0].date)
                    : currentDate;
    
                // Loop through all dates from startDate to today
                const allDates = [];
                for (
                    let d = new Date(startDate);
                    d <= currentDate;
                    d.setDate(d.getDate() + 1)
                ) {
                    allDates.push(new Date(d));
                }
    
                // Add missing dates with default values (e.g., empty string or null)
                allDates.forEach(date => {
                    if (!isDatePresent(filledData, date)) {
                        filledData.push({
                            value: [], // Default value for missing days (empty string)
                            date: new Date(date)
                        });
                    }
                });
    
                // Sort again by date after adding missing days
                return filledData.sort(
                    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                );
            };
    
            // Ensure all days are accounted for
            newNutritionData = fillMissingDays(newNutritionData);
    
            // Update user information
            newUser.nutritionData = newNutritionData;
    
            const response = await Api.updateUser(newUser._id, newUser);
    
            // Update the current user in the application state
            setCurrentUser(response);
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }
    async function submitSleepingValue() {
        if (isNaN(Number(sleepingInput))) {
            alert('Input must be a number');
        } else {
            try {
                let newUser = { ...currentUser };
    
                // Get the user's sleepingData and create a copy
                let newSleepingData = [...currentUser.sleepingData];
                const now = new Date();
    
                // Create new data entry for the current day
                const newValue: DataObject = {
                    value: Number(sleepingInput),
                    date: now
                };
    
                // Helper to check if a date exists in the data
                const isDatePresent = (data: DataObject[], date: Date): boolean => {
                    return data.some(entry => {
                        const entryDate = new Date(entry.date);
                        return (
                            entryDate.getFullYear() === date.getFullYear() &&
                            entryDate.getMonth() === date.getMonth() &&
                            entryDate.getDate() === date.getDate()
                        );
                    });
                };
    
                // Add today's data first
                if (!isDatePresent(newSleepingData, now)) {
                    newSleepingData.push(newValue);
                }
    
                // Fill missing days if any
                const fillMissingDays = (data: DataObject[]): DataObject[] => {
                    const filledData = [...data];
                    const currentDate = new Date();
    
                    // Sort sleepingData by date (ascending)
                    filledData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                    // Find the earliest recorded date or default to today
                    const startDate = filledData.length
                        ? new Date(filledData[0].date)
                        : currentDate;
    
                    // Loop through all dates from startDate to today
                    const allDates = [];
                    for (
                        let d = new Date(startDate);
                        d <= currentDate;
                        d.setDate(d.getDate() + 1)
                    ) {
                        allDates.push(new Date(d));
                    }
    
                    // Add missing dates with default values (e.g., null)
                    allDates.forEach(date => {
                        if (!isDatePresent(filledData, date)) {
                            filledData.push({
                                value: -1, // Or any default value you'd like for missing days
                                date: new Date(date)
                            });
                        }
                    });
    
                    // Sort again by date after adding missing days
                    return filledData.sort(
                        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                };
    
                // Ensure all days are accounted for
                newSleepingData = fillMissingDays(newSleepingData);
    
                // Update user information
                newUser.sleepingData = newSleepingData;
    
                const response = await Api.updateUser(newUser._id, newUser);
    
                // Update the current user in the application state
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }
    }
    async function submitRetrospectiveSleepingValue(newValue: string | number, date: Date | string): Promise<void> {
        if (isNaN(Number(newValue))) {
            alert('Input must be a number');
        } else {
            try {
                // Create a copy of the current user
                let updatedUser = { ...currentUser };
    
                // Ensure drinkingData has a proper type
                let updatedSleepingData: DataObject[] = [...updatedUser.sleepingData];
    
                // Helper to find the entry by date
                const findEntryByDate = (data: DataObject[], targetDate: Date | string): DataObject | undefined => {
                    return data.find(entry => {
                        const entryDate = new Date(entry.date);
                        const target = new Date(targetDate);
                        return (
                            entryDate.getFullYear() === target.getFullYear() &&
                            entryDate.getMonth() === target.getMonth() &&
                            entryDate.getDate() === target.getDate()
                        );
                    });
                };
    
                // Find the entry matching the provided date
                const entryToUpdate = findEntryByDate(updatedSleepingData, date);
    
                if (entryToUpdate) {
                    // Update the value for the matching entry
                    entryToUpdate.value = Number(newValue);
                } else {
                    // If no matching entry exists, add a new one
                    updatedSleepingData.push({
                        value: Number(newValue),
                        date: new Date(date) // Ensure date is stored in correct format
                    });
                }
    
                // Sort drinkingData by date (ascending)
                updatedSleepingData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    
                // Update the user's drinkingData
                updatedUser.sleepingData = updatedSleepingData;
    
                // Send the updated user data to the API
                const response = await Api.updateUser(updatedUser._id, updatedUser);
    
                // Update the application state with the updated user
                setCurrentUser(response);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save retrospective value');
            }
        }
    }
    
    async function handleSmokerChange() {  
        setLoadingSmokerChange(true)
        try {
            let newUser = { ...currentUser };

            newUser.smoker = !newUser.smoker

            const response = await Api.updateUser(newUser._id, newUser);

            setCurrentUser(response)
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setLoadingSmokerChange(false)
    }

    async function handleDrinkerChange() {  
        setLoadingDrinkerChange(true)
        try {
            let newUser = { ...currentUser };

            newUser.drinker = !newUser.drinker

            const response = await Api.updateUser(newUser._id, newUser);

            setCurrentUser(response)
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
        setLoadingDrinkerChange(false)
    }

    async function getExercises(){
        await Api.getExercises().then(res=>{
            setExercises(res);
        });
    }

    useEffect(() => {
        const handleResize = () => {
            setViewportSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        adaptPlanLength()

        if (currentUser.currentVitovaPlan){
            getNext7Days();
        }

        getExercises();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function adaptPlanLength() {
        console.log("Function called");
        await Api.adaptPlanLength(currentUser._id, {
            surgeryDate: currentUser.surgeryDate,
            currentVitovaPlan: currentUser.currentVitovaPlan
        }).then(res => {
            setCurrentUser(res);
            getNext7Days();
            console.log("Response received:", res);
        }).catch(error => {
            console.error("Error in API call:", error);
        });
    }
    

    const handlePaceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPace(value as number);
    };

    const handlePowerChange: SliderProps['onChange'] = (event:any, value:any) => {
        setPower(value as number);
    };

    const handleDistanceChange: SliderProps['onChange'] = (event:any, value:any) => {
        setDistance(value as number);
    };

    const nextStage = () => setReviewStage(prevStage => prevStage + 1);
    const prevStage = () => setReviewStage(prevStage => Math.max(prevStage - 1, 0));

    function getNext7Days():VitovaDay[] {
        if (showFinalReport){
            setNext7Days(currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days);
            return currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days; // Return default days for the first week
        }
        // Parse the start date string into a Date object
        const startDate = new Date(currentUser.userPlanStartDate);
        
        // Get the current date and time
        const currentDate = new Date();
        
        // Ensure both dates are valid Date objects
        if (isNaN(startDate.getTime()) || isNaN(currentDate.getTime())) {
            throw new Error("Invalid Date");
        }
    
        // Calculate the difference in milliseconds
        const diffInMilliseconds = currentDate.getTime() - startDate.getTime();
        
        // Convert milliseconds to days
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const diffInDays = Math.floor(diffInMilliseconds / millisecondsPerDay);


        // const diffInDays = 2
        // Calculate the current week and day within that week
        let weekIndex = Math.floor(diffInDays / 7);

        // Adjust for index bounds
        if (weekIndex < 0) {
            weekIndex = 0;
        }
        setLoading(false)
        
        if (weekIndex >= currentUser.currentVitovaPlan.content.length) {
            setNext7Days(currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days)
            setShowFinalReport(true);
            return currentUser.currentVitovaPlan.content[currentUser.currentVitovaPlan.content.length-1].days; // Return default days for the last week
        } else {
            setNext7Days(currentUser.currentVitovaPlan.content[weekIndex].days)
            return currentUser.currentVitovaPlan.content[weekIndex].days;
        }
    }

    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) return;
    
        // Step 1: Reorder next7Days (we'll swap the items directly)
        const reorderedItems = Array.from(next7Days);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
    
        // Step 2: Swap the `day.day` properties between the two swapped items
        const swappedItems = [...reorderedItems];
        const sourceItem = reorderedItems[result.destination.index];
        const destinationItem = reorderedItems[result.source.index];
    
        // Swap the `day.day` of the source and destination items
        const tempDay = sourceItem.id;
        sourceItem.id = destinationItem.id;
        destinationItem.id = tempDay;
    
        // Step 3: Update next7Days state
        setNext7Days(swappedItems);
    
        // Step 4: Update currentUser.currentVitovaPlan.content
        const weekIndex = getWeekNumber(reorderedItems[0]); // Assuming all items belong to the same week
    
        // We now correctly swap the `day.day` while updating the days in the content
        const updatedContent = currentUser.currentVitovaPlan.content.map((week: VitovaWeek, index: number) => {
            if (index === weekIndex) {
                return {
                    ...week,
                    days: swappedItems,  // Use the swapped items with their new `day.day`
                };
            }
            return week; // No change for other weeks
        });
    
        // Step 5: Set the updated currentUser.currentVitovaPlan.content
        const updatedUser = {
            ...currentUser,
            currentVitovaPlan: {
                ...currentUser.currentVitovaPlan,
                content: updatedContent,
            },
        };
    
        setCurrentUser(updatedUser);
    
        // Step 6: Call the API with the updated user
        try {
            await Api.updateUser(currentUser._id, updatedUser);
            console.log("User updated successfully!");
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };
    
    function getDateFromDay(start: Date, week: number, day: number): Date {
        // Clone the start date to avoid mutating the original date
        const resultDate = new Date(start);

        // Calculate the total number of days to add
        const totalDaysToAdd = week * 7 + day;
        
        // Add the total days to the new date
        resultDate.setDate(resultDate.getDate() + totalDaysToAdd);
        
        // Return the new date
        return resultDate;
    }
    
    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const marks = generateMarks();

    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '60vw',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:colorPalette.steelBlue
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }

    function msToMinPerKm(speed: number): string {
        if (speed > 0){
            // 1 KM = 1000 meters
            // Calculate time in seconds to run 1 KM
            const timeInSeconds = 1000 / speed;
            
            // Convert time to minutes and seconds
            const minutes = Math.floor(timeInSeconds / 60);
            const seconds = Math.round(timeInSeconds % 60);
            
            // Format minutes and seconds as MM:SS
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            
            return `${formattedMinutes}:${formattedSeconds}`;
        }else{
            return `00:00`
        }

    }

    function timeStringToSeconds(timeString:string) {
        // alert(timeString)
        const timeParts = timeString.split(':');
        const minutes = parseInt(timeParts[0], 10);
        const seconds = parseInt(timeParts[1], 10);
        return (minutes * 60) + seconds;
    }

    const paceStringToSeconds = (value: number): string => {
        const minutes = Math.floor(value / 60);
        const seconds = value % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    function secondsToPaceString(totalSeconds:number) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        const padWithZero = (num:number) => num.toString().padStart(2, '0');
    
        return `${padWithZero(hours)}:${padWithZero(minutes)}:${padWithZero(seconds)}`;
    }

    function getWeekNumber(day:VitovaDay){
        for (var i =0;i<userPlan.content.length;i++){
            if (userPlan.content[i].days.includes(day)){
                return i;

            }
        }

        return 0
    }

    function getDayNumber(day:VitovaDay){
        const findIndex = next7Days.findIndex(d=>d.id === day.id);
        if (findIndex && findIndex > 0){
            return findIndex

        }
        return 0
    }

    function getWeekDayName(dateString: string, week: number, day: number): string {
        // Parse the input dateString to create a Date object
        const date = new Date(dateString);
        
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date string");
        }
    
        // Calculate the total days to add
        const totalDaysToAdd = (week * 7) + day;
    
        // Add the total days to the date
        date.setDate(date.getDate() + totalDaysToAdd);
    
        // Get the day of the week as a number (0 for Sunday, 1 for Monday, ...)
        const dayOfWeek = date.getDay();
    
        // Map the day numbers to their names
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
        // Return the name of the day
        return dayNames[dayOfWeek];
    }

    function getCalendarDay(dateString: string, week: number, day: number) {
        // Convert initial date string to a Date object
        const startDate = new Date(dateString);
        // Number of days to add (corrected by removing +1)
        const daysToAdd = week * 7 + day;
    
        // Clone the initial date to avoid mutating it directly
        const newDate = new Date(startDate);
    
        // Add the correct number of days
        newDate.setDate(newDate.getDate() + daysToAdd);
    
        // Get the day of the month from the new date
        const dayOfMonth = newDate.getDate();
        
        // Format the day to include "th", "st", "nd", "rd" suffixes
        function getDayWithSuffix(day: number) {
            if (day >= 11 && day <= 13) {
                return `${day}th`;
            }
            switch (day % 10) {
                case 1:
                    return `${day}st`;
                case 2:
                    return `${day}nd`;
                case 3:
                    return `${day}rd`;
                default:
                    return `${day}th`;
            }
        }
        
        const formattedDay = getDayWithSuffix(dayOfMonth);
        return formattedDay;
    }

    function getDayOfWeek(dateString: string, week: number, day: number) {
        // Convert initial date string to a Date object
        const startDate = new Date(dateString);
        
        // Number of days to add (corrected by removing +1)
        const daysToAdd = week * 7 + day;
        
        // Clone the initial date to avoid mutating it directly
        const newDate = new Date(startDate);
        
        // Add the correct number of days
        newDate.setDate(newDate.getDate() + daysToAdd);
        
        // Get the day of the week from the new date (0 = Sunday, 1 = Monday, etc.)
        const dayOfWeek = newDate.getDay();
        
        // Array for day names
        const dayNames = [
            'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
    
        // Return the day name
        return dayNames[dayOfWeek];
    }
    
    function isDayComplete(day:VitovaDay){
        const compulsoryActivities = day.activities.filter(a=>a.compulsory).length;
        let completedActivities = 0;
        for (const activity of day.activities){
            const findReview = currentUser.vitovaReviews.find(review=> review.activityReviewData.id === activity.id && review.saved);

            if (findReview){
                completedActivities += 1;
            }
        }
        return completedActivities >= compulsoryActivities;
    }

    function getUserStats(){
        let stats = []

        // CONSISTENCY STAT
        stats.push({
            title:'Consistency',
            value:0.5
        })
        return stats;
    }

    function isDayReviewed(day: VitovaDay) {
        // Calculate the day of the week index from the day string
        const dayIndex = next7Days.findIndex(d=>d.id===day.id);
        
        // Calculate the date of the day based on the startDate and getWeekNumber(day)
        const dayDate = new Date(currentUser.userPlanStartDate.toString());
        dayDate.setDate(dayDate.getDate() + getWeekNumber(day) * 7 + dayIndex);
    
        // Get today's date without time for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const compulsoryActivities = day.activities.filter(a=>a.compulsory).length;
        let completedActivities = 0;

        // Check if all activities are reviewed
        for (const activity of day.activities) {
            const findReview = currentUser.vitovaReviews.find(review => review.activityReviewData.id === activity.id && review.saved === true);
            
            if (!findReview) {
                // If the day has passed and an activity is not reviewed, return false
                if (today > dayDate) {
                    return false;
                }
            }else{
                completedActivities += 1;
            }
        }

        if (completedActivities >= compulsoryActivities){
            return true;
        }
    
        return false;
    }

    function milliSecondsToDate(value: number): string {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if needed
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()]; // Get the month name
        const year = date.getFullYear(); // Get the year
    
        return `${day} ${month} ${year}`;
    }

    function formatMinutes(value:number){
        if (value >= 0) {
            // Calculate hours and remaining minutes
            let hours = Math.floor(value / 60);
            let minutes = Math.floor(value % 60);
            let seconds = Math.round((value % 1) * 60);
    
            // Format hours, minutes, and seconds
            let hoursStr = hours > 0 ? hours + " hour" + (hours !== 1 ? "s" : "") + " " : "";
            let minutesStr = minutes > 0 ? minutes + " minute" + (minutes !== 1 ? "s" : "") + " " : "";
            let secondsStr = seconds > 0 ? seconds + " second" + (seconds !== 1 ? "s" : "") : "";
    
            // Combine the formatted string
            return (hoursStr + minutesStr + secondsStr).trim();
        } else {
            return "Invalid value";
        }
    }

    async function getActivityReview(activity: VitovaActivity) {
        const findReview = currentUser.vitovaReviews.find(r => r.activityReviewData.id === activity.id);
    
        if (findReview) {
            // Return Found Review
            setSelectedReview(findReview);
        } else {
            if (selectedDay){
                // Update user by adding a new review
                const today = new Date();
                const newReview: VitovaReview = {
                    id: new ObjectId().toHexString(),
                    activityReviewData: activity,
                    dateOfActivity:getDateFromDay(currentUser.userPlanStartDate, getWeekNumber(selectedDay), getDayNumber(selectedDay)),
                    dateReviewed: today,
                    saved:false,
                    notes: ''
                };
                
                // Ensure currentUser.vitovaReviews is being updated correctly
                const updatedUser = { 
                    ...currentUser, 
                    vitovaReviews: [...currentUser.vitovaReviews, newReview] 
                };
        
                try {
                    const res = await Api.updateUser(updatedUser._id, updatedUser);
                    setCurrentUser(res);
                    setSelectedReview(newReview);
                } catch (error) {
                    console.error("Error updating user: ", error);
                    throw error;
                }
            }else{
                alert("Couldn't retrieve the day")
            }
        }
    }

    async function updateReviewField(activity: VitovaActivity, fieldPath: string, value: any) {
        const findReview = currentUser.vitovaReviews.find(r => r.activityReviewData.id === activity.id);
        
        if (!findReview) {
            console.error("Review not found for the given activity.");
            return;
        }
    
        // Helper function to update a nested field based on the provided fieldPath
        const updateNestedField = (obj: any, path: string, value: any): void => {
            const keys = path.split('.'); // Split path into an array of keys
            let currentObj = obj;
    
            // Traverse the object to the last key
            keys.slice(0, -1).forEach((key: string) => {
                if (!currentObj[key]) {
                    currentObj[key] = {}; // If the key doesn't exist, create an empty object
                }
                currentObj = currentObj[key];
            });
    
            // Set the value at the final key
            currentObj[keys[keys.length - 1]] = value;
        };
    
        // Update the specific field using the fieldPath
        const updatedReview = { 
            ...findReview,
        };
    
        updateNestedField(updatedReview, fieldPath, value);
    
        // Ensure currentUser.vitovaReviews is being updated correctly
        const updatedUser = {
            ...currentUser,
            vitovaReviews: currentUser.vitovaReviews.map(review => 
                review.id === updatedReview.id ? updatedReview : review
            )
        };
    

        setCurrentUser(updatedUser);
        setSelectedReview(updatedReview);

        // try {
        //     setCurrentUser(updatedUser);
        //     setSelectedReview(updatedReview);
        // } catch (error) {
        //     console.log("Error updating review field: ", error);
        //     throw error;
        // }
    }
    

    function getDayIndex() {
        const currentDay = new Date().getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
        return (currentDay === 0) ? 6 : currentDay - 1; // Adjust to make Monday 0, Tuesday 1, ..., Sunday 6
    }    

    async function onSaveReview(submit:boolean) {
        if (!selectedDay){
            return;
        }
        try {
            const userReviews = [...currentUser.vitovaReviews]; // Clone to avoid mutating state directly
    
            const findReview = userReviews.find(
                (r) => r.activityReviewData.id === selectedDay.activities[selectedActivityIndex].id
            );
    
            if (findReview) {
                if (submit){
                    findReview.saved = true; // Ensure the review is saved
                }
            } else {
                console.warn("No matching review found");
                return; // Exit if no review found
            }

            const testStep = findReview.activityReviewData.steps.find(s=>s.isTestEffort);
            const today = new Date();
            if (testStep){
                // ADD BEST EFFORT BASED ON TEST EFFORT
                const newBestEffort:Effort = {
                    id: new ObjectId().toHexString(),
                    date:today,
                    activity:findReview.activityReviewData,
                    step:testStep
                }
                const updatedBestEfforts = [...currentUser.bestEfforts, newBestEffort]
                // Update the user object with modified reviews
                const updatedUser = {
                    ...currentUser,
                    vitovaReviews: userReviews,
                    bestEfforts:updatedBestEfforts
                };

                await Api.updateUser(currentUser._id, updatedUser);
                setIsPopupOpen(false); // Close popup on success
                setReviewingActivity(null)
            }else{
                // Update the user object with modified reviews
                const updatedUser = {
                    ...currentUser,
                    vitovaReviews: userReviews,
                };

                await Api.updateUser(currentUser._id, updatedUser);
                setIsPopupOpen(false); // Close popup on success
                setReviewingActivity(null)
            }
        } catch (error) {
            console.error("Failed to save review changes:", error);
            alert("Failed to save review changes");
        }
    }
    

    function getActivityColor(activityType:string){
        switch (activityType){
            case 'Run':
                return colorPalette.steelBlue;  // Soft Red
            case 'Strength':
                return colorPalette.steelBlue;  // Light Pink
            case 'Swim':
                return colorPalette.steelBlue;  // Dark Turquoise
            case 'Bike':
                return colorPalette.steelBlue;  // Medium Purple
            case 'Other':
                return colorPalette.steelBlue;  // Gold
            default:
                return colorPalette.steelBlue;  // Steel Blue
        }
    }

    function hasSubmittedSmokingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.smokingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    function hasSubmittedDrinkingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.drinkingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    function hasSubmittedSleepingDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.sleepingData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }

    const addActivity = async () => {
        if (!selectedDay) {
            console.error('Missing required state: selectedDay');
            return;
        }
    
        // Define the activity to be added
        const activity: VitovaActivity = {
            id: `extra_activity_${Date.now()}`,
            type: VitovaActivityType.STANDARD,
            title: `Patient Added Activity`,
            description: "",
            plannedDuration: '60',
            plannedRPE: '5',
            steps: [],
            compulsory: false,
        };
    
        try {
            const currentPlan = currentUser.currentVitovaPlan;
            const weekIndex = getWeekNumber(selectedDay);
            const dayIndex = getDayNumber(selectedDay);
            const currentWeek = currentPlan.content[weekIndex];
            const currentDay = currentWeek.days[dayIndex];
    
            // Clone the current week and days to ensure immutability
            const updatedDays = [...currentWeek.days];
            const updatedDay = {
                ...currentDay,
                activities: [...(currentDay.activities || []), activity],
            };
            updatedDays[dayIndex] = updatedDay;
    
            const updatedWeek = {
                ...currentWeek,
                days: updatedDays,
            };
    
            // Update the overall plan content
            const updatedContent = [...currentPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            const updatedPlan = {
                ...currentPlan,
                content: updatedContent,
            };
    
            // Update user object
            const updatedUser = {
                ...currentUser,
                currentVitovaPlan: updatedPlan,
            };
    
            // Save updated user data
            const user = await Api.updateUser(currentUser._id, updatedUser);
            setCurrentUser(user);
            setNext7Days(updatedWeek.days);
            setSelectedDay(updatedDay);
        } catch (error) {
            console.error('Error updating activity:', error);
        }
    };
    

    const deleteActivity = async (activityId: string) => {
        if (!selectedDay) {
            console.error('Missing required state: selectedDay');
            return;
        }
    
        try {
            const currentPlan = currentUser.currentVitovaPlan;
            const weekIndex = getWeekNumber(selectedDay);
            const dayIndex = getDayNumber(selectedDay);
            const currentWeek = currentPlan.content[weekIndex];
            const currentDay = currentWeek.days[dayIndex];
    
            // Filter out the activity to delete
            const updatedActivities = currentDay.activities.filter(activity => activity.id !== activityId);
    
            // Clone and update the day
            const updatedDays = [...currentWeek.days];
            const updatedDay = {
                ...currentDay,
                activities: updatedActivities,
            };
            updatedDays[dayIndex] = updatedDay;
    
            const updatedWeek = {
                ...currentWeek,
                days: updatedDays,
            };
    
            // Update the overall plan content
            const updatedContent = [...currentPlan.content];
            updatedContent[weekIndex] = updatedWeek;
    
            const updatedPlan = {
                ...currentPlan,
                content: updatedContent,
            };
    
            // Update user object
            const updatedUser = {
                ...currentUser,
                currentVitovaPlan: updatedPlan,
            };
    
            // Save updated user data
            const user = await Api.updateUser(currentUser._id, updatedUser);
            setCurrentUser(user);
            setNext7Days(updatedWeek.days);
            setSelectedDay(updatedDay);
        } catch (error) {
            console.error('Error deleting activity:', error);
        }
    };
    

    function hasSubmittedNutritionDataToday (){
        const today = new Date();
        const isValueForToday = currentUser.nutritionData.some(data => {
        const dataDate = new Date(data.date);
        return (
            dataDate.getFullYear() === today.getFullYear() &&
            dataDate.getMonth() === today.getMonth() &&
            dataDate.getDate() === today.getDate()
        );
        });

        if (!isValueForToday) {
            return false;
        } else {
            return true;
        }

    }
    function isActivityReviewed (activity:VitovaActivity){
        const userReviews = currentUser.vitovaReviews;

        const findReview = userReviews.filter(r=>r.activityReviewData.id === activity.id && r.saved);

        if (findReview.length>0){
            return true;
        }else{
            return false;
        }
    }

    function isDayInThePast(day: VitovaDay) {
        // Assuming currentUser and next7Days are accessible in this scope
        const userPlanStartDate = new Date(currentUser.userPlanStartDate); // Convert start date to Date object
        const today = new Date(); // Get today's date
    
        // Get the number of weeks and convert it to days (1 week = 7 days)
        const weekNumber = getWeekNumber(day);
        const daysFromWeeks = (weekNumber * 7) + 1;
    
        // Find the index of the day in next7Days
        const dayIndex = next7Days.indexOf(day);
        
        if (dayIndex === -1) {
            throw new Error("The specified day is not in the next7Days array.");
        }
    
        // Calculate the final date by adding the days from weeks and dayIndex to the userPlanStartDate
        const finalDate = new Date(userPlanStartDate);
        finalDate.setDate(finalDate.getDate() + daysFromWeeks + dayIndex);
    
        // Check if the final date is before today
        return finalDate < today;
    }
    
    function isDayInTheFuture(day: VitovaDay) {
        const userPlanStartDate = new Date(currentUser.userPlanStartDate);
        const todaysDate = new Date();
    
        const weekNumber = getWeekNumber(day);
        if (isNaN(weekNumber)) {
            throw new Error("Invalid week number.");
        }
        const daysFromWeeks = weekNumber * 7;
    
        const dayIndex = next7Days.findIndex(d => d.id === day.id);
        if (dayIndex === -1) {
            throw new Error("The specified day is not in the next7Days array.");
        }
    
        // Calculate activity date
        const activityDate = new Date(userPlanStartDate);
        activityDate.setDate(activityDate.getDate() + daysFromWeeks + dayIndex);
        
        // Normalize activityDate to midnight
        activityDate.setHours(0, 0, 0, 0);
    
        // Debugging logs
        console.log("activityDate:", activityDate, "today:", todaysDate);
        console.log("activityDate timestamp:", activityDate.getTime(), "today timestamp:", todaysDate.getTime());
    
        return activityDate.getTime() >= todaysDate.getTime();
    }
    
    
    
    const formatAsShortDayName = (date:Date) => {
        const dayLabel = new Intl.DateTimeFormat("en-GB", {
            weekday: "short"
        }).format(new Date(date))
        return dayLabel
    }
    function formatDateWithOrdinal(input: Date | string): string {
        let date: Date;
    
        // Ensure the input is a valid Date object
        if (input instanceof Date) {
            date = input;
        } else {
            date = new Date(input); // Attempt to convert string to Date
            if (isNaN(date.getTime())) {
                throw new TypeError("Invalid date input");
            }
        }
    
        const day = date.getDate();
    
        // Determine the appropriate ordinal suffix
        const getOrdinalSuffix = (n: number): string => {
            if (n % 10 === 1 && n % 100 !== 11) return "st";
            if (n % 10 === 2 && n % 100 !== 12) return "nd";
            if (n % 10 === 3 && n % 100 !== 13) return "rd";
            return "th";
        };
    
        const ordinalSuffix = getOrdinalSuffix(day);
        return `${day}${ordinalSuffix}`;
    }

    function getUserReviewsGroupedIntoWeeks() {
        if (currentUser.currentVitovaPlan){
            const reviews = currentUser.vitovaReviews;
            const planStartDate = new Date(currentUser.userPlanStartDate).getTime(); // Convert to timestamp
            let weeks: VitovaDay[] = currentUser.currentVitovaPlan.content.map((w): VitovaDay => {
                return {
                    id: '',
                    activities: []
                };
            });
            
            for (const review of reviews.filter(r=>r.saved)) {
                const reviewDate = new Date(review.dateOfActivity).getTime(); // Convert to timestamp
                const weekIndex = Math.floor((reviewDate - planStartDate) / (7 * 24 * 60 * 60 * 1000)); // Calculate week index
                if (weekIndex>= 0 && weekIndex< weeks.length){
                    weeks[weekIndex].activities.push(review.activityReviewData);

                }
            }
        
            return weeks;
        }else{
            return []
        }

    }

    function getExercise(exercise: Exercise | undefined): Exercise | undefined {
        if (exercise === undefined){
            return undefined
        }
        const findExercise = exercises.find(e => e.id === exercise.id);
        return findExercise || undefined;
      }

    function getUserReviewsGroupedIntoDays() {
        if (currentUser.currentVitovaPlan) {
            const reviews = currentUser.vitovaReviews;
            const planStartDate = new Date(currentUser.userPlanStartDate).setHours(0, 0, 0, 0); // Normalize to midnight
    
            // Create a copy of next7Days with empty activities
            let week: Array<VitovaDay> = next7Days.map((d) => ({
                id: d.id, // Preserve ID from next7Days
                activities: []
            }));
    
            for (const review of reviews.filter(r=>r.saved)) {
                const reviewDate = new Date(review.dateOfActivity).setHours(0, 0, 0, 0); // Normalize to midnight
                const dayIndex = Math.floor((reviewDate - planStartDate) / (24 * 60 * 60 * 1000)); // Calculate day index
    
                // Ensure dayIndex is within bounds
                if (dayIndex >= 0 && dayIndex < week.length) {
                    week[dayIndex].activities.push(review.activityReviewData);
                }
            }
    
            return week;
        } else {
            return [];
        }
    }
    

    const handleCheckboxChange = (index: number) => {
        // Create a copy of the current state
        const updatedInput = [...nutritionInput];
        // Toggle the checked state for the selected checkbox
        updatedInput[index].checked = !updatedInput[index].checked;
        // Update the state
        setNutritionInput(updatedInput);
    };
    function getAverageSessionDuration(days:VitovaDay[]){
        let sum = 0;
        let numberOfActivities = 0;
        for (const day of days){
            for (const activity of day.activities){
                sum += Number(activity.plannedDuration)
                numberOfActivities += 1;
            }
        }
        return Math.round(sum/numberOfActivities);
    }
    function getAverageSessionRPE(days:VitovaDay[]){
        let sum = 0;
        let numberOfActivities = 0;
        for (const day of days){
            for (const activity of day.activities){
                sum += Number(activity.plannedRPE)
                numberOfActivities += 1;
            }
        }
        return Math.round(sum/numberOfActivities);
    }
    function getNumberOfSessions(type:VitovaStepType, days:VitovaDay[]){
        switch (type){
            case VitovaStepType.CARDIO:
                let cardioSum = 0;
                for (const day of days){
                    for (const activity of day.activities.filter(a=>a.compulsory)){
                        if (activity.steps.length > 0 && activity.steps[0].type === VitovaStepType.CARDIO){
                            cardioSum += 1
                        }
                    }
                }
                return cardioSum;
            case VitovaStepType.STRENGTH:
                let strengthSum = 0;
                for (const day of days){
                    for (const activity of day.activities.filter(a=>a.compulsory)){
                        if (activity.steps.length > 0 && (activity.steps[0].type === VitovaStepType.STRENGTH || activity.steps[0].type === VitovaStepType.FLEXIBILITY)){
                            strengthSum += 1
                        }
                    }
                }
                return strengthSum;
        }
    }
    if (currentUser.currentVitovaPlan){
        if (!loading){
            return (
                
                <div className="dashboard-page" style={{backgroundColor:'#f1f1f1'}}>
                    <DisclaimerPopup/>
                    <div className="dashboard-view-container" style={{backgroundColor:'#f1f1f1'}}>
                        {showCalendar && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={toggleCalendar}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showCalendar ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }
                        {showRightPanel && 
                            <div className="side-panel-container"> 
                                <div className="expand-arrow-container" onClick={()=>toggleRightPanel()}>
                                    <FontAwesomeIcon className="expand-arrow" icon={showRightPanel ? faChevronLeft : faGripLinesVertical}></FontAwesomeIcon>
                                </div>
                            </div>
                        }

                        {showCalendar ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                        :
                        showRightPanel ? 
                            (
                                <div className={`main-panel-container`} style={{backgroundColor:'#f1f1f1'}}>

                                </div>
                            )
                            :
                            (
                                <div className={`main-panel-container`} style={{flexDirection:'row',backgroundColor:'#f1f1f1',display:'flex', justifyContent:'flex-start', alignItems:'flex-start', overflow:'hidden'}}>
                                    <div style={{display:'flex',flex:14, flexDirection:'column', backgroundColor:'#f1f1f1'}}>
                                        <div className="top-panel" style={{paddingLeft:'20px', gap:'10px', maxHeight:'25vh'}}>
                                            <div className="top-panel-daysleft" style={{scale:'1', flex:1,}} >
                                                <FontAwesomeIcon className="streak-icon" icon={faClock}></FontAwesomeIcon>
                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px'}}>
                                                {(() => {
                                                    const surgeryDate = new Date(currentUser.surgeryDate);
                                                    const now = new Date();
                                                    const diffInMs = surgeryDate.getTime() - now.getTime(); // Use getTime() to get numeric timestamps
                                                    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // Convert to days
                                                    return `${diffInDays} DAYS TO GO`;
                                                })()}
                                                </label>
                                            </div>
                                            <div className="top-panel-streak" style={{scale:'1', flex:1}} onClick={()=>showFinalReport ? {} : toggleCalendar()}>
                                                <FontAwesomeIcon className="streak-icon" style={{color:`${colorPalette.steelBlue}dd`}} icon={showFinalReport ? faCalendarCheck : faCalendarDays}></FontAwesomeIcon>
                                                <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic', fontSize:'20px', color:`${colorPalette.steelBlue}dd`}}>{showFinalReport ? `ALL DONE` : `WEEKLY PLAN`}</label>
                                            </div>

                                        <div style={{display:'flex', flex:4, justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'column', width:'100%',}}>
                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                                    {/* <FontAwesomeIcon className="calendar-icon" style={{marginTop:'20px'}} icon={faCalendarDay}></FontAwesomeIcon> */}
                                                    <label className="streak-label" style={{fontWeight:'bold', fontStyle:'italic',fontSize:'1.5vw', color:'#2f3b4a', marginTop:'0'}}>{showFinalReport ? `NO FURTHER ACTIVITIES - PLAN COMPLETE!` : `OUR PLAN FOR YOU TODAY`}</label>
                                                </div>
                                                        <div style={{display:'flex', flexDirection:'row',justifyContent:'flex-start', alignItems:'center', marginTop:'10px', width:'100%'}}>
                                                            {next7Days[adjustedTodayIndex].activities.filter(a=>a.compulsory).length === 0 && 
                                                            <div className="activity-button" style={{backgroundColor:'fafafa', padding:'30px', marginLeft:'10px', marginTop:'5px', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}}>
                                                                    <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>No Activity Planned For Today!</label>    
                                                                </div>
                                                            }
                                                            {showFinalReport ?
                                                                <div style={{display:'flex', flexDirection:'column',justifyContent:'flex-start', alignItems:'center', marginTop:'10px', width:'100%'}} onClick={()=>toggleRightPanel('endreport')}>
                                                                    <div className="activity-button" style={{backgroundColor:'#fafafa', padding:'30px', marginLeft:'10px', marginTop:'5px', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}}>
                                                                        <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>{`VIEW YOUR END OF PLAN REPORT`}</label>    
                                                                        <label style={{color:colorPalette.steelBlue, fontSize:'15px', pointerEvents:'none'}}>{`Well Done ${currentUser.fullname}! We hope you've enjoyed your time using our platform!`}</label>    
                                                                    </div>
                                                                </div>
                                                            :
                                                            next7Days[adjustedTodayIndex].activities.filter(a=>a.compulsory).map((activity, index)=>(
                                                                <div className="activity-button" style={{backgroundColor:index%2===0?'#fafafa':'#f6f6f6', padding:'30px', marginLeft:'10px', marginTop:'5px', borderRadius:'5px', width:'94%', flexDirection:'column', display:'flex'}} onClick={()=>{setShowCalendar(true);setSelectedDay(next7Days[adjustedTodayIndex]); setSelectedActivityIndex(index)}}>
                                                                    <label style={{color:colorPalette.steelBlue, fontStyle:'italic', fontWeight:'bold', pointerEvents:'none'}}>{activity.title.toUpperCase()}</label>    
                                                                </div>
                                                            ))}
                                                        </div>
                                            </div>


                                        </div>
                                        <div style={{display:'flex',flex:3,paddingLeft:'20px',paddingRight:'20px', width:'100%',height:'auto',flexDirection:'column', backgroundColor:'#f1f1f1',justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <label style={{color:colorPalette.steelBlue, marginBottom: '10px', marginLeft:'5px',fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic'}}>INFORMED STRESS SCORE OVERVIEW</label>
                                            <ComparisonChartComponent mh={80} mw={100} 
                                            plannedData={currentUser.currentVitovaPlan.content.map((week:VitovaWeek)=>getWeekStressScore(week, currentUser, false))} 
                                            realData={getUserReviewsGroupedIntoWeeks().map((week:VitovaDay)=>getDayStressScore(week, currentUser, true))} 
                                            labelData={currentUser.currentVitovaPlan.content.map((week:VitovaWeek, index:number)=>`WEEK ${index+1}`)}
                                            user={currentUser}></ComparisonChartComponent>
                                            {/* <label style={{color:colorPalette.steelBlue, marginBottom: '10px', marginLeft:'5px',fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic'}}>ACTIVITY HISTORY</label>
                                            {currentUser.reviews.length <= 0 && (
                                                <div className="history-item" style={{marginBottom:'2vh', height:'10vh', width:'100%', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                <label style={{color:'#565656', margin: '10px 10px', fontSize:'1.25vw', fontWeight:'normal'}}>No Activity History Yet</label>
                                            </div>
                                            )} */}
                                            <div style={{maxHeight:'70vh', overflowY:'scroll', paddingBottom:'10vh', width:'100%'}}>
                                            {currentUser.reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((historyItem: Review, index: number) => (
                                                historyItem.results.effort >= 0 ?
                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:index % 2 === 0 ? '#ffffff':'#fafafa'}}>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                        <div style={{display:'flex', flex:1}}>
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type}</label>
                                                        </div>
                                                        <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                            <label style={{color:'#565656', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.duration}</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{secondsToPaceString(historyItem.results.pace)}</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.hr}</label> 
                                                        </div>
                                                        <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                        <div style={{display:'flex', flexDirection:'column'}}>
                                                            <label style={{color:'#787878', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                            <label style={{color:'#454545', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>{historyItem.results.effort} / 10</label> 
                                                        </div>

                                                    </div>
                                                </div>
                                                :                                                
                                                <div className="history-item" style={{marginBottom:'2vh', backgroundColor:colorPalette.softRed}}>
                                                <div style={{display:'flex', width:'100%', height:'auto',flex:1}}>
                                                    <div style={{display:'flex', flex:1}}>
                                                        <label style={{color:'#f1f1f1', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold'}}>{historyItem.activity.type} (Missed)</label>
                                                    </div>
                                                    <div style={{display:'flex', justifyContent:'flex-end', flex:1}}>
                                                        <label style={{color:'#f3f3f3', margin: '10px 15px', fontSize:'1vw', fontWeight:'bold'}}>{milliSecondsToDate(historyItem.date)}</label>
                                                    </div>
                                                </div>
                                                <div style={{display:'flex', width:'100%', height:'auto',flex:1, marginLeft:'1.5vw', marginRight:'1.5vw',alignItems:'center'}}>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Duration</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Pace</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>HR</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                    <div style={{height:'4vw', width:'2px', backgroundColor:'#aaaaaa', marginLeft:'1vw', marginRight:'1vw'}}></div>
                                                    <div style={{display:'flex', flexDirection:'column'}}>
                                                        <label style={{color:'#f1f1f1', fontSize:'0.75vw', fontWeight:'bold', textAlign:'center'}}>Effort</label>   
                                                        <label style={{color:'white', margin: '10px 15px', fontSize:'1.5vw', fontWeight:'bold', textAlign:'center'}}>n/a</label> 
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', 
                                        flex: 1, 
                                        flexDirection: 'column', 
                                        height: '90vh', 
                                        borderTopLeftRadius: '8px', 
                                        borderBottomLeftRadius:'8px',
                                        paddingBottom:'10px',
                                        paddingLeft:'10px',
                                        paddingRight:'10px',
                                        marginTop: '9vh',
                                        backgroundColor: 'white',
                                        justifyContent: 'flex-start', 
                                        overflowY:'scroll',
                                        overflowX:'hidden',
                                        alignItems: 'center',
                                    }}>
                                        <div className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:'5px', borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                        <FontAwesomeIcon onClick={()=>toggleRightPanel('checkin')} className="icon-button-icon" icon={faInbox}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>CHECK IN</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('alcohol')}  className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" style={{color:currentUser.drinker ? (hasSubmittedDrinkingDataToday() ? (getAverageAlcoholScoreColor(currentUser)) : '#34343466'):'#34343422'}} icon={faBeer}/>
                                            <label style={{
                                                fontSize:'10px'
                                                ,fontWeight:'bold'
                                                ,fontStyle:'italic'
                                                ,marginTop:'10px',
                                                color:currentUser.drinker ? (hasSubmittedDrinkingDataToday() ? (getAverageAlcoholScoreColor(currentUser)) : '#34343466'):'#34343422'                                                
                                        }}>ALCOHOL</label>                                        
                                        </div>

                                        <div onClick={()=>toggleRightPanel('smoking')}  className="icon-button" style={{borderRadius:0}}>
                                        <FontAwesomeIcon className="icon-button-icon" 
                                            style={{
                                                color:currentUser.smoker ? (hasSubmittedSmokingDataToday() ? (getAverageSmokingScoreColor(currentUser)) : '#34343466'):'#34343422'  
                                            }} 
                                            icon={faSmoking}
                                            />
                                            <label style={{
                                                fontSize:'10px'
                                                ,fontWeight:'bold'
                                                ,fontStyle:'italic'
                                                ,marginTop:'10px',
                                                color:currentUser.smoker ? (hasSubmittedSmokingDataToday() ? (getAverageSmokingScoreColor(currentUser)) : '#34343466'):'#34343422'  
                                                }}>SMOKING</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('sleep')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:hasSubmittedSleepingDataToday() ? (getAverageSleepQualityColor(currentUser)) : '#34343466'                                                 
                                            }}
                                             icon={faBed}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:hasSubmittedSleepingDataToday() ? (getAverageSleepQualityColor(currentUser)) : '#34343466'                                               
                                            }}>SLEEP</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('nutrition')}  className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" 
                                            style={{color:hasSubmittedNutritionDataToday() ? getAverageNutritionColor(currentUser) : '#34343466' }}
                                            icon={faBowlFood}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:hasSubmittedNutritionDataToday() ? getAverageNutritionColor(currentUser) : '#34343466'
                                            }}>NUTRITION</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('tests')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:'5px'}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:colorPalette.steelBlue                                               
                                            }}
                                             icon={faList}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:colorPalette.steelBlue                                              
                                            }}>TESTS</label>
                                        </div>
                                        <div onClick={()=>toggleRightPanel('goals')}  className="icon-button" style={{borderTopRightRadius:0, borderTopLeftRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon"
                                            style={{
                                                color:colorPalette.steelBlue                                          
                                            }}
                                             icon={faBullseye}/>
                                            <label style={{
                                                fontSize:'10px', 
                                                fontWeight:'bold', 
                                                fontStyle:'italic', 
                                                marginTop:'10px',
                                                color:colorPalette.steelBlue                                               
                                            }}>GOALS</label>
                                        </div>

                                        <div onClick={()=>toggleRightPanel('exercises')}  className="icon-button" style={{borderRadius:0}}>
                                        <FontAwesomeIcon className="icon-button-icon" style={{color:colorPalette.steelBlue}} icon={faDumbbell}/>
                                        <label style={{
                                                fontSize:'10px'
                                                ,fontWeight:'bold'
                                                ,fontStyle:'italic'
                                                ,marginTop:'10px',
                                                color:colorPalette.steelBlue
                                                }}>EXERCISES</label>
                                        </div>


                                        {/* <div onClick={()=>toggleRightPanel('tests')} className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faListCheck}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>TESTS</label>
                                        </div> */}
                                        {/* <div onClick={()=>toggleRightPanel('goals')}  className="icon-button" style={{borderRadius:0}}>
                                            <FontAwesomeIcon className="icon-button-icon" icon={faBullseye}/>
                                            <label style={{fontSize:'10px', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>GOALS</label>
                                        </div> */}
                                    {/* </div> */}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {/* CALENDAR */}
                    <div className={`calendar-container ${showCalendar ? 'show' : ''}`}>
                        {/* CALENDAR VIEW */}
                        {selectedDay ? (
                            !reviewingActivity ?
                                (
                                <div className='card-enlarged' style={{overflowY:'scroll',backgroundColor:'#ffffff66', minHeight:'75vh', width:'100%', display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                        <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                            <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={()=>{setSelectedDay(null)}} icon={faArrowLeft}></FontAwesomeIcon>
                                        </div>
                                        <div style={{backgroundColor:'transparent', display:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%',}}>
                                            {/* <label style={{fontSize:'1vw', marginTop:'5px', fontWeight:'bold', marginRight:'5px'}}>Review</label> */}
                                            <FontAwesomeIcon className="download-button" onClick={()=> isActivityReviewed(selectedDay.activities[selectedActivityIndex]) ? alert('Already Reviewed') : isDayInTheFuture(selectedDay) ? alert(`Please wait until the ${getCalendarDay(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(selectedDay),
                                                        userPlan.content[getWeekNumber(selectedDay)].days.indexOf(
                                                            selectedDay
                                                        )
                                                    )} to review this activity`) : 
                                                    // Open Reivew
                                                    getActivityReview(selectedDay.activities[selectedActivityIndex]).then(()=>{
                                                        setReviewingActivity(selectedDay.activities[selectedActivityIndex])
                                                    })} 
                                                    
                                                    style={{width:'2.5vw', height:'2.5vw', marginRight:'1vw', padding:'5px', color:isActivityReviewed(selectedDay.activities[selectedActivityIndex]) ? colorPalette.steelBlue : 'white'}}  icon={faRobot}></FontAwesomeIcon>
                                        </div>
                                    </div>

                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'row', backgroundColor:'transparent', width:'100%', height:'auto'}}>
                                        <div style={{display:'flex',flex:1,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'80%', display:'flex', flexDirection:'column',backgroundColor:selectedDay.activities[selectedActivityIndex].compulsory ? `${colorPalette.mediumSeaGreen}99`:`${colorPalette.darkOrange}99`, justifyContent:'center', alignItems:"center"}}>
                                                <label style={{fontSize:'0.75vw', fontWeight:'bold',width:'100%', maxLines:1, overflowY:'hidden', fontStyle:'italic'}}>{selectedDay.activities[selectedActivityIndex].compulsory ? `COMPULSORY ${selectedDay.activities[selectedActivityIndex].type === VitovaActivityType.TEST ?'TEST':'ACTIVITY'}`:`OPTIONAL ${selectedDay.activities[selectedActivityIndex].type === VitovaActivityType.TEST ?'TEST':'ACTIVITY'}`}</label>
                                            </div>
                                        </div>
                                        <div style={{display:'flex',flex:3,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'80%', backgroundColor:`${colorPalette.default}aa`, display:'flex', justifyContent:'center', alignItems:"center"}}>
                                                <label style={{fontSize:'1.25vw', fontWeight:'bold', padding:'2vw', maxLines:1, overflowY:'hidden', fontStyle:'italic'}}>{selectedDay.activities[selectedActivityIndex].title.toUpperCase()}</label>
                                            </div>
                                        </div>
                                        {selectedDay.activities[selectedActivityIndex].description &&
                                        <div style={{display:'flex',flex:8,backgroundColor:'transparent', justifyContent:'center', alignItems:'center'}}>
                                            <div style={{width:'100%', padding:'15px 5px',height:'80%', backgroundColor:`${colorPalette.default}dd`, display:'flex', textAlign:'start', justifyContent:'center', alignItems:'center'}}>
                                                <label style={{padding:'15px 5px', fontSize:'0.9em', textAlign:'justify', overflowY:'scroll', lineHeight:'20px'}}>{selectedDay.activities[selectedActivityIndex].description}</label>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    
                                    <div style={{display:'flex', justifyContent:'flex-start',justifyItems:'flex-start',flexDirection:'row', backgroundColor:'#f1f1f1', width:'100%', height:'53vh', overflow:'hidden'}}>                                        
                                        {viewportSize.width/viewportSize.height > 1.25 && 
                                            <div style={{display:'flex',flex:1,backgroundColor:'#f1f1f1', justifyContent:'center', alignItems:'center'}}>
                                                <div style={{width:'8vw', height:'100%', overflowX:'hidden',overflowY:'scroll',backgroundColor:`#f1f1f1`,  flexDirection:'column', display:"flex", justifyContent:'center', alignItems:'center'}}>
                                                    {selectedDay?.activities?.length > 0 && (selectedDay.activities ?? []).map((activity: VitovaActivity | undefined, index: number) => {
                                                        if (!activity) return null; // Ensure activity is defined before rendering
                                                        
                                                        return (
                                                            <div
                                                                key={activity.id} // Always add a unique key when mapping elements
                                                                className="section-buttons"
                                                                onClick={() => setSelectedActivityIndex(index)}
                                                                style={{
                                                                    backgroundColor: selectedActivityIndex === index ? getActivityColor(activity.type) : `${colorPalette.default}88`,
                                                                    opacity: index % 2 === 0 ? 1 : 0.9,
                                                                    borderStyle: 'solid',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    borderWidth: '0px',
                                                                    borderRightWidth: selectedActivityIndex === index ? '5px' : '3px',
                                                                    borderColor: selectedActivityIndex === index ? '#ffffff' : '#ffffff77',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="button-icon"
                                                                    icon={
                                                                        activity.steps.length > 0
                                                                            ? activity.steps[0].type === VitovaStepType.CARDIO && activity.steps[0].cardio?.exercise
                                                                                ? exerciseIcons[activity.steps[0].cardio.exercise.name]
                                                                                : activityIcons[activity.steps[0].type]
                                                                            : faEllipsis
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                    {/* <div className="section-buttons" 
                                                        onClick={()=>addActivity()} 
                                                        style={{
                                                            backgroundColor:`${colorPalette.default}aa`,
                                                            borderStyle:'solid',
                                                            borderWidth:'0px',
                                                            borderRightWidth:'3px',
                                                            borderColor:'#ffffff77'
                                                            }}>
                                                        <FontAwesomeIcon className="button-icon" 
                                                            icon={faPlus}
                                                        ></FontAwesomeIcon>

                                                    </div> */}
                                                </div>
                                            </div>
                                        }
                                        {/* CARDIO BREAKDOWN */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.length > 0 ?
                                            <div style={{width:'100%', height:'100%', maxWidth:'100%',backgroundColor:`${colorPalette.default}33`, display:'flex', paddingLeft:'1vw',paddingBottom:'1vw',justifyContent:'flex-start', alignItems:'flex-end'}}>
                                                <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                                                borderColor: '#ffffff',
                                                borderStyle: 'solid',
                                                borderTopWidth:'0',
                                                overflowX:'hidden',
                                                maxWidth:'80vw',
                                                // maxHeight:'45vh',
                                                minHeight:'45vh',
                                                marginTop:'10vh',
                                                borderRightWidth:'0',
                                                width:'95%',
                                                // maxWidth:'50vw',
                                                padding:'5px 5px 0px 0px'}}>
                                            <div style={{display:'flex', width:'100%', height:'100%',flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                                                <WorkoutStepsDiagram bg={'transparent'} floor={true} user={currentUser} steps={selectedDay.activities[selectedActivityIndex].steps} key={selectedDay.activities[selectedActivityIndex].id}/>
                                            </div>  
                                        </div>
                                            </div>
                                            :
                                            <div style={{width:'100%', height:'100%', backgroundColor:`#f1f1f1`, display:'flex',justifyContent:'center', alignItems:'center'}}>

                                            <div style={{ flexDirection: 'row', display:'flex',justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent'}}>
                                                <label style={{fontSize:'1.5vw', fontWeight:'bold', color:'#454545'}}>No Workout Available For This Activity</label>
                                            </div>
                                            </div>
                                            }
                                            <div style={{display:"flex", width:'100%', backgroundColor:`${colorPalette.default}33`, justifyContent:'flex-end'}}>
                                                <></>
                                            </div>

                                            </div>
                                        }
                                        {/* CHOICEDAY  */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                            {selectedDay.activities[selectedActivityIndex].steps.length > 0 ?
                                            <div style={{width:'100%', height:'100%', maxWidth:'100%',backgroundColor:`${colorPalette.default}33`, display:'flex', paddingLeft:'1vw',paddingBottom:'1vw',justifyContent:'flex-start', alignItems:'flex-end'}}>
                                                <div style={{ flexDirection: 'row', display:'flex', flex:3,justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent', borderWidth: '3px',
                                                borderColor: '#ffffff',
                                                borderStyle: 'solid',
                                                borderTopWidth:'0',
                                                overflowX:'hidden',
                                                maxWidth:'80vw',
                                                // maxHeight:'45vh',
                                                minHeight:'45vh',
                                                marginTop:'10vh',
                                                borderRightWidth:'0',
                                                width:'95%',
                                                // maxWidth:'50vw',
                                                padding:'5px 5px 0px 0px'}}>
                                            <div style={{display:'flex', flex:2,width:'100%', height:'100%',flexDirection:'row', alignItems:'flex-end', justifyContent:'center'}}>
                                                <WorkoutStepsDiagram bg={'transparent'} floor={true} user={currentUser} steps={selectedDay.activities[1].steps} key={selectedDay.activities[1].id}/>
                                            </div>  
                                            <div style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    height:'100%',
                                                    flex:1,
                                                    flexDirection:'column',
                                                    backgroundColor: 'transparent',
                                                    alignItems:'center',
                                                    justifyContent: 'center',
                                                }}>
                                                    {/* <label style={{color:'steelblue', padding:0, margin:0}}>Need Inspiration?</label> */}
                                                    <button style={{marginRight:'5px', marginTop:'0', alignSelf:'flex-start'}} className="review-button" onClick={() => setIsYoutubeWorkoutPopupOpen(true)}>
                                                        <span style={{ color: 'steelblue', fontStyle: 'italic', fontSize:'1vw', marginTop:0, paddingTop:0}}>
                                                            NEED INSPIRATION?
                                                        </span>
                                                    </button>
                                                </div>
                                            <Dialog open={isYoutubeWorkoutPopupOpen} onClose={()=>setIsYoutubeWorkoutPopupOpen(false)} maxWidth="md" fullWidth>
                                            <DialogTitle style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                                FOLLOW ONE OF OUR YOUTUBE WORKOUTS
                                            </DialogTitle>
                                            <DialogContent>
                                            <YouTubeWorkoutsTable workouts={youtubeWorkouts}/>

                                            </DialogContent>
                                            <DialogActions>
                                                
                                                <Button onClick={()=>setIsYoutubeWorkoutPopupOpen(false)} variant="contained" color="primary">
                                                Back
                                                </Button>
                                            </DialogActions>
                                            </Dialog>
                                        </div>
                                            </div>
                                            :
                                            <div style={{width:'100%', height:'100%', backgroundColor:`#f1f1f1`, display:'flex',justifyContent:'center', alignItems:'center'}}>

                                            <div style={{ flexDirection: 'row', display:'flex',justifyContent:'center', alignContent:'center', justifyItems:'center', alignItems:'flex-end', backgroundColor:'transparent'}}>
                                                <label style={{fontSize:'1.5vw', fontWeight:'bold', color:'#454545'}}>No Workout Available For This Activity</label>
                                            </div>
                                            </div>
                                            }
                                            <div style={{display:"flex", width:'100%', backgroundColor:`${colorPalette.default}33`, justifyContent:'flex-end'}}>
                                                <></>
                                            </div>

                                            </div>
                                        }
                                        {/* CUSTOM ACTIVITY */}
                                        {viewportSize.width/viewportSize.height > 1.25 && selectedDay.activities[selectedActivityIndex].id.includes('extra_activity') &&
                                            <div style={{display:'flex',flex:8,backgroundColor:`#f1f1f1`, justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div

                                                style={{ display: 'flex', width: '100%', height:'100%',justifyContent: 'flex-start', alignItems: 'flex-start' }}
                                            >
                                                    <button style={{marginRight:'5px', marginTop:'5px', alignSelf:'flex-start'}} className="review-button" 
                                                        onClick={(e) => {
                                                            e.stopPropagation(); // Prevent triggering parent onClick
                                                            setSelectedActivityIndex(selectedActivityIndex-1);
                                                            deleteActivity(selectedDay.activities[selectedActivityIndex].id);
                                                        }}
                                                    >
                                                        <span style={{ color: 'steelblue', fontStyle: 'italic', fontSize:'1vw', marginTop:0, paddingTop:0}}>
                                                            REMOVE ACTIVITY
                                                        </span>
                                                    </button>
  
                                            </div>
                                            <div style={{display:'flex', padding:'15px', gap:'10px'}}>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="Duration (minutes)"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.duration || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.duration', Number(e.target.value))} 
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                />
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="RPE (/10)"
                                                                type="text"
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.rpe || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.rpe', Number(e.target.value))}
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent" }}
                                                                />
                                                        </div>
                                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <TextField
                                                                label="Notes"
                                                                multiline
                                                                type="text"
                                                            
                                                                fullWidth
                                                                variant="outlined"
                                                                // value={step.choiceday?.notes || ''}
                                                                // onChange={(e) => updateStepField(step.id, 'choiceday.notes', e.target.value)} 
                                                                InputLabelProps={{ shrink: true }}
                                                                style={{ fontSize: "1.2em", backgroundColor: "transparent", width:'30vw'}}
                                                                />
                                                        </div>
                                                    </div>             

                                            </div>
                                        }
                                        <div style={{display:'flex',flex:(selectedDay.activities[selectedActivityIndex].steps.length > 0 && (selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO || selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE)) || selectedDay.activities[selectedActivityIndex].id.includes('extra_activity') ? 3:20,backgroundColor:`${colorPalette.default}44`, flexDirection:'column',justifyContent:'flex-start', alignItems:'center'}}>
                                            <div style={{width:'100%', height:'auto', backgroundColor:`${colorPalette.default}33`, flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                            <div style={{width:'100%', height:'auto', backgroundColor:'transparent', flexDirection:'row', display:"flex", justifyContent:'flex-start', alignItems:'flex-start'}}>
                                                    <div className="section-buttons" onClick={()=>setSelectedWorkoutPart(0)} 
                                                    style={{
                                                        backgroundColor:selectedWorkoutPart===0?`${colorPalette.steelBlue}ff`:`${colorPalette.steelBlue}aa`,
                                                        borderStyle:'solid',
                                                        borderWidth:'0px',
                                                        borderBottomWidth:selectedWorkoutPart===0?'2px':'0px',
                                                        borderColor:'white',
                                                        width:'auto'
                                                        }}>
                                                        <label style={{
                                                            padding:'5px 20px', 
                                                            fontSize:'1vw', 
                                                            pointerEvents:'none',
                                                            fontWeight:'bold',
                                                            fontStyle:'italic'
                                                            }}>WORKOUT</label>
                                                    </div>
                                            </div>

                                            </div>
                                            {selectedWorkoutPart === 0 &&
                                            (
                                                <div style={{height:'100%', overflowY:'scroll',width:'100%', display:'flex',justifyContent:'flex-start', alignItems:'center', flexDirection:'column'}}>
                                                        {selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CHOICE
                                                        ?
                                                        selectedDay.activities[selectedActivityIndex+1].steps.map((step:VitovaActivityStep, index:number)=>(
                                                            <div style={{display:'flex', width:'100%',padding:'5px',flexDirection:'column',opacity:index%2 === 0 ? 1 : 0.8,
                                                                backgroundColor: getRpeColourFromStep(step),
                                                                justifyContent:'center', alignItems:'flex-start'}}>
                                                                    <label style={{padding:'3px 6px',fontSize:'0.85vw', textAlign:'left', overflowY:'scroll',fontStyle:'italic', fontWeight:'bold'}}>{`${index+1} | ${(buildStringFromStep(currentUser, step)).toUpperCase()}`}</label>
                                                            </div>
                                                        ))
                                                        :
                                                        selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type === VitovaStepType.CARDIO
                                                        ?
                                                        selectedDay.activities[selectedActivityIndex].steps.map((step:VitovaActivityStep, index:number)=>(
                                                            <div style={{display:'flex', width:'100%',padding:'5px',flexDirection:'column',opacity:index%2 === 0 ? 1 : 0.8,
                                                                backgroundColor: getRpeColourFromStep(step),
                                                                justifyContent:'center', alignItems:'flex-start'}}>
                                                                    <label style={{padding:'3px 6px',fontSize:'0.85vw', textAlign:'left', overflowY:'scroll',fontStyle:'italic', fontWeight:'bold'}}>{`${index+1} | ${(buildStringFromStep(currentUser, step)).toUpperCase()}`}</label>
                                                            </div>
                                                        ))
                                                            :
                                                            <div style={{ width: '100%' }}>
                                                                
                                                                {selectedDay.activities[selectedActivityIndex].steps.length > 0 && selectedDay.activities[selectedActivityIndex].steps[0].type !== VitovaStepType.REST &&
                                                                <div style={{ display: 'flex', width: '100%', padding: '5px', backgroundColor: colorPalette.steelBlue, fontWeight: 'bold', borderBottom: '2px solid #f1f1f1' }}>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>EXERCISE</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>SETS</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>REPS / DURATION</span>
                                                                    <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>EFFORT</span>
                                                                </div>
                                                                }
                                                                {/* Data Rows */}
                                                                {selectedDay.activities[selectedActivityIndex].steps.map((step: VitovaActivityStep, index: number) => (
                                                                    step.type === VitovaStepType.STRENGTH || step.type === VitovaStepType.FLEXIBILITY ? (
                                                                        <div key={index} style={{ 
                                                                            display: 'flex', width: '100%', padding: '5px', 
                                                                            flexDirection: 'row', opacity: index % 2 === 0 ? 1 : 0.8, 
                                                                            backgroundColor: getRpeColourFromStep(step), 
                                                                            justifyContent: 'space-between', alignItems: 'center', 
                                                                            borderBottom: '1px solid #f1f1f1' 
                                                                        }}>
                                                                            {/* Exercise Name */}
                                                                            <span 
                                                                                style={{ flex: 1, padding: '5px', fontSize: '1.25vw', textAlign: 'center', fontWeight:'bold',fontStyle:'italic',cursor: 'pointer' }}
                                                                                onClick={() => { 
                                                                                    setPopupExercise(getExercise(
                                                                                        step.type === VitovaStepType.STRENGTH && step.strength?.exercise 
                                                                                        ? step.strength?.exercise 
                                                                                        : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.exercise 
                                                                                        ? step.flexibility?.exercise 
                                                                                        : undefined
                                                                                    )); 
                                                                                    setIsExercisePopupOpen(true);
                                                                                }}
                                                                            >
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.exercise 
                                                                                    ? step.strength?.exercise.name.toUpperCase() 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.exercise 
                                                                                    ? step.flexibility?.exercise.name.toUpperCase() 
                                                                                    : "N/A"}
                                                                            </span>

                                                                            {/* Sets */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.sets 
                                                                                    ? step.strength.sets 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.sets 
                                                                                    ? step.flexibility.sets 
                                                                                    : "-"}
                                                                            </span>

                                                                            {/* Reps / Hold Duration */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.reps 
                                                                                    ? step.strength.reps 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.holdDuration 
                                                                                    ? `${step.flexibility.holdDuration}s` 
                                                                                    : "-"}
                                                                            </span>

                                                                            {/* RPE */}
                                                                            <span style={{ flex: 1, padding: '5px', fontSize: '1vw', textAlign: 'center' }}>
                                                                                {step.type === VitovaStepType.STRENGTH && step.strength?.rpe 
                                                                                    ? `${step.strength.rpe}/10` 
                                                                                    : step.type === VitovaStepType.FLEXIBILITY && step.flexibility?.rpe 
                                                                                    ? `${step.flexibility.rpe}/10` 
                                                                                    : "-"}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div key={index} style={{ 
                                                                            display: 'flex', width: '100%', padding: '5px', 
                                                                            flexDirection: 'column', opacity: index % 2 === 0 ? 1 : 0.8, 
                                                                            backgroundColor: getRpeColourFromStep(step), 
                                                                            justifyContent: 'center', alignItems: 'flex-start' 
                                                                        }}>
                                                                            <label style={{ padding: '3px 6px', fontSize: '1.25vw', textAlign: 'left', overflowY: 'scroll', fontStyle: 'italic', fontWeight: 'bold' }}>
                                                                                {`${index + 1} | ${(buildStringFromStep(currentUser, step)).toUpperCase()}`}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                ))}

                                                            </div>
                                                            
                                                            }
                                                        <ExercisePopup setExercise={setPopupExercise} exercises={exercises} index={0} isOpen={isExercisePopupOpen} handleClose={()=>setIsExercisePopupOpen(false)} exercise={popupExercise} step={popupStep}/>

                                                </div>
                                            )
                                            }
                                            {selectedWorkoutPart === 1 &&
                                            (
                                                <></>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                                )
                                :
                                (
                                <div className='card-enlarged'
                                    style={{
                                        backgroundColor: '#ffffff55', 
                                        height: '75vh', 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center', 
                                        alignItems: 'center'
                                    }}>
                                        {(reviewStage === 0 || reviewStage===1) && 
                                        <FontAwesomeIcon className="back-button" style={{position:'absolute', top:'20px', left:'20px', color:'#454545'}}  onClick={()=>{setReviewingActivity(null); prevStage();}} icon={faArrowLeft}></FontAwesomeIcon>
                                        }
                                        {/* <h2 style={{fontSize:'40px'}} className="card-title" >Session Review</h2> */}
                                        <div className="session-review-form" >
                                            {reviewStage === 0 && (
                                                <div className="form-stage">
                                                    <h2 style={{ fontSize: '2.5vw', fontWeight: 'bold', fontStyle:'italic',color:colorPalette.steelBlue}}>WELL DONE!</h2>
                                                    <h1 style={{color:'#454545', fontSize:'1.75vw'}} className="single-line-text">Let's review your session and find out how it went!</h1>
            
                                                    <div style={{width:'100%', flexDirection:'column',display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'5px',}}>
                                                        <button className="review-button" onClick={()=>{
                                                            setReviewType('manual'); 
                                                            nextStage(); 
                                                            // handleDayClick(selectedDay);
                                                        }
                                                            }>REVIEW WITH <span style={{ color: 'steelblue', fontStyle: 'italic', marginLeft: '5px' }}>VITOVA</span></button>
                                                        <button
                                                        onClick={()=>{
                                                            // setReviewType('strava');nextStage();
                                                            alert('This Feature Is Still In Development')
                                                        }}
                                                        className="strava-button"
                                                        >
                                                            AUTO REVIEW WITH <span style={{ color: '#fc4c02', fontStyle: 'italic', marginLeft: '5px' }}>STRAVA</span>
                                                        </button> 
                                                        {/* <Button className="review-button" onClick={()=>{}}>I MISSED THIS ACTIVITY</Button> */}

                                                        </div>
                                                </div>
            
                                            )}
                                            {reviewStage === 1 && reviewType === 'strava' && (
                                                <div className="form-stage">
                                                    <h2>Select The Activities You Completed Today</h2>
                                                    <div className="strava-activities-grid">
                                                        {currentUser.stravaActivities
                                                            .filter((sa: StravaActivity) => {
                                                                const activityDate = new Date(sa.start_date);
                                                                const today = new Date();
                                                                const sevenDaysAgo = new Date();
                                                                sevenDaysAgo.setDate(today.getDate() - 14);
                                                                return activityDate >= sevenDaysAgo && activityDate <= today;
                                                            })
                                                            .map((sa: StravaActivity) => (
                                                                <div key={sa.id} className="strava-activity-card">
                                                                    <label style={{marginTop:'10px'}} className="activity-name">{sa.name}</label>
                                                                    <label className="activity-detail">Date: {sa.start_date}</label>
                                                                    <label className="activity-detail">Type: {sa.sport_type}</label>

                                                                    <label className="activity-detail">Distance: {(sa.distance / 1000).toFixed(2)} km</label>
                                                                    <label style={{marginBottom:'10px'}} className="activity-detail">Duration: {Math.floor(sa.moving_time / 60)} min</label>
                                                                </div>

                                                            ))}
                                                    </div>

                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', borderRadius: '5px', position: 'absolute', bottom: 0 }}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                                    </div>
                                                </div>

                                            )}

                                            {reviewStage === 1 && reviewType === 'manual' && (
                                                <div style={{ display: 'flex', justifyContent: 'center', width: '100vw'}}>
                                                <Container
                                                    style={{
                                                        color:"black",
                                                        textAlign:'left',
                                                        backgroundColor:'transparent',
                                                        // maxWidth: '80vw',  // Ensures it doesn't exceed 80% of viewport width
                                                        width: '100%',  // Allows flexibility without overflowing
                                                        maxHeight: '70vh',  // Limits height to prevent excessive stretching
                                                        overflowY: 'auto',  // Enables scrolling if needed
                                                        overflowX: 'hidden',  // Prevents horizontal overflow
                                                        alignItems:'center',
                                                        borderRadius: '10px',
                                                        padding: '1rem',
                                                    }}
                                                >
                                                    {/* Popup */}
                                                        {/* <Container maxWidth="md" fullWidth> */}
                                                            {/* Centered DialogTitle with white background */}
                                                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                                <Paper
                                                                    sx={{
                                                                        backgroundColor: 'white', 
                                                                        padding: '0.75rem 1rem', 
                                                                        borderRadius: '5px',
                                                                        width:'96%',
                                                                        boxShadow:0,
                                                                        border:'1px solid lightgray',
                                                                        // maxWidth: '100%',  // Adjust width as needed (e.g., 50% of parent)
                                                                        textAlign: 'center', 
                                                                    }}
                                                                >
                                                                    <DialogTitle
                                                                        sx={{
                                                                            fontStyle: 'italic',
                                                                            fontWeight: 'bold',
                                                                            margin: 0,  // Remove extra margin
                                                                            padding: 0, // Adjust to fit within Paper
                                                                        }}
                                                                    >
                                                                        {selectedDay
                                                                            ? `LET'S SEE HOW YOU GOT ON WITH ${getDayOfWeek(
                                                                                currentUser.userPlanStartDate.toString(),
                                                                                getWeekNumber(selectedDay),
                                                                                next7Days.indexOf(selectedDay)
                                                                            ).toUpperCase()}'S ACTIVITIES!`
                                                                            : 'DAY DETAILS'}
                                                                    </DialogTitle>
                                                                </Paper>
                                                            </Box>
                                                            <DialogContent>
                                                                {/* Activity Selector */}
                                                                <FormControl style={{backgroundColor:'white', borderRadius:'5px'}} fullWidth sx={{ mb: 2, mt:2 }}>
                                                                    <InputLabel>Select Activity</InputLabel>
                                                                    <Select
                                                                        label={'Select Activity'}
                                                                        value={selectedActivityIndex}
                                                                        onChange={(e) => {setSelectedActivityIndex(Number(e.target.value)); getActivityReview(selectedDay.activities[Number(e.target.value)])}}
                                                                    >
                                                                        {selectedDay?.activities.map((activity, index) => (
                                                                            <MenuItem key={index} value={index}>
                                                                                {activity.title}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>

                                                                {/* Fetch the selected activity dynamically */}
                                                                {selectedDay?.activities.length > 0 && (
                                                                    
                                                                    <Paper variant="outlined" sx={{ p: 2, mt: 0 }}>
                                                                        <WorkoutStepsDiagram user={currentUser} steps={selectedDay.activities[selectedActivityIndex].steps} key={selectedDay.activities[selectedActivityIndex].id}/>
                                                                        <Typography sx={{mt:2}} style={{ fontStyle: 'italic', color: colorPalette.steelBlue }} variant="h6">
                                                                            {selectedDay.activities[selectedActivityIndex].title.toUpperCase()}
                                                                        </Typography>
                                                                        <Grid container justifyContent="space-between" sx={{ mt: 1 }}>
                                                                            <Typography variant="body2">
                                                                                {minutesToTimeString(selectedDay.activities[selectedActivityIndex].plannedDuration)}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedDistance
                                                                                    ? `${selectedDay.activities[selectedActivityIndex].plannedDistance} KM`
                                                                                    : '-- KM'}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    ? selectedDay.activities[selectedActivityIndex].plannedRPE
                                                                                    : '-- '}{' '}
                                                                                / 10 RPE
                                                                            </Typography>
                                                                            <Typography variant="body2">-- TSS</Typography>
                                                                        </Grid>
                                                                    </Paper>
                                                                )}

                                                                {selectedDay.activities[selectedActivityIndex].steps.map((step:VitovaActivityStep)=>(
                                                                    step.type === VitovaStepType.STRENGTH ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25vw'}}>
                                                                            {step.strength?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Sets</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.sets
                                                                                            ? step.strength.sets
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.sets || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, sets: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                    />
                                                                                </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Reps</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.reps
                                                                                            ? step.strength.reps
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.reps || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, reps: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.strength?.rpe
                                                                                            ? step.strength.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.strength?.rpe || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, strength: { ...s.strength, rpe: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    step.type === VitovaStepType.CARDIO ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25vw'}}>
                                                                            {step.cardio?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.duration
                                                                                            ? minutesToTimeString(step.cardio.duration)
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.duration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, cardio: { ...s.cardio, duration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Distance</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.distance
                                                                                            ? step.cardio.distance
                                                                                            : '--'}{' '}
                                                                                        KM
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.distance || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, distance: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.rpe
                                                                                            ? step.cardio.rpe
                                                                                            : '--'}{' '}
                                                                                        / 10
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.rpe || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, rpe: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Heart Rate</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.heartRate
                                                                                            ? step.cardio.heartRate
                                                                                            : '--'}{' '}
                                                                                        BPM
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.heartRate || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, heartRate: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Pace (m/s)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.cardio?.pace
                                                                                            ? step.cardio.pace
                                                                                            : '--'}{' '}
                                                                                        M/S
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.pace || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, pace: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Power (watts)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                    {step.cardio?.power
                                                                                            ? step.cardio.power
                                                                                            : '--'}{' '}
                                                                                        W
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                        value={
                                                                                            selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.cardio?.power || ''
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                s.id === step.id ? { ...s, cardio: { ...s.cardio, power: e.target.value } } : s
                                                                                            );

                                                                                            updateReviewField(
                                                                                                selectedDay.activities[selectedActivityIndex],
                                                                                                'activityReviewData.steps',
                                                                                                updatedSteps
                                                                                            );
                                                                                        }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    step.type === VitovaStepType.FLEXIBILITY ?
                                                                    (
                                                                        <TableContainer style={{backgroundColor:step.isTestEffort?`${colorPalette.softRed}33`:'white'}} component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25vw'}}>
                                                                            {step.flexibility?.exercise.name.toUpperCase()}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Sets</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.sets
                                                                                            ? step.flexibility.sets
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.sets || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, sets: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Hold Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.holdDuration
                                                                                            ? step.flexibility.holdDuration
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.holdDuration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, holdDuration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.flexibility?.rpe
                                                                                            ? step.flexibility.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.flexibility?.rpe || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, flexibility: { ...s.flexibility, rpe: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                    :
                                                                    (
                                                                        <TableContainer component={Paper} sx={{ mt: 3 }}>
                                                                        <DialogTitle style={{ fontStyle: 'italic', fontSize:'1.25vw'}}>
                                                                            {'Daily Activity'}
                                                                        </DialogTitle>
                                                                        <Table>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell></TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Planned
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <Typography style={{ fontWeight: 'bold', fontStyle: 'italic' }} variant="subtitle1">
                                                                                            Completed
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Exercise</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.exercise
                                                                                            ? step.choiceday.exercise
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.exercise || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, exercise: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>Duration</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.duration
                                                                                            ? step.choiceday.duration
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.duration || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, duration: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontStyle: 'italic' }} align="center">
                                                                                        <Typography>RPE (/10)</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        {step.choiceday?.rpe
                                                                                            ? step.choiceday.rpe
                                                                                            : '--'}{' '}
                                                                                    </TableCell>
                                                                                    <TableCell align="center">
                                                                                        <TextField
                                                                                            value={
                                                                                                selectedReview?.activityReviewData.steps.find(s => s.id === step.id)?.choiceday?.rpe || ''
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                const updatedSteps = selectedReview?.activityReviewData.steps.map((s) =>
                                                                                                    s.id === step.id ? { ...s, choiceday: { ...s.choiceday, rpe: e.target.value } } : s
                                                                                                );

                                                                                                updateReviewField(
                                                                                                    selectedDay.activities[selectedActivityIndex],
                                                                                                    'activityReviewData.steps',
                                                                                                    updatedSteps
                                                                                                );
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                        </TableContainer>
                                                                    )
                                                                ))}

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Description
                                                                </Typography>

                                                                <Typography variant="body2" color="textSecondary">{selectedDay.activities[selectedActivityIndex].description}
                                                                </Typography>

                                                                <Typography variant="subtitle1" sx={{ mt: 3 }}>
                                                                    Post-activity comments
                                                                </Typography>
                                                                <TextField
                                                                    value={selectedReview?.notes}
                                                                    onChange={(e) => updateReviewField(selectedDay.activities[selectedActivityIndex], 'notes', e.target.value)}
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    placeholder="Enter a new comment"
                                                                    variant="outlined"
                                                                />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={() => onSaveReview(false)} variant="contained" color="primary">Save For Later</Button>
                                                                <Button onClick={() => onSaveReview(true)} color="primary">Save & Submit</Button>
                                                            </DialogActions>
                                                        {/* </Container> */}
                                                </Container>
                                                </div>
                                            )}


                                            {/* STAGE 5 */}
                                            {reviewStage === 5 && reviewType === 'manual' && (
                                                <div className="form-stage">
                                                    <h2 style={{color:'black'}}>Review Your Answers Before Submitting</h2>
                                                    <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                                        <thead>
                                                            <tr>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Duration</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Average Pace</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Average Heart Rate</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Percieved Effort</th>
                                                                <th style={{fontSize:'20px', padding:'10px', color:'#454545',borderBottom: '2px solid #000'}}>Sleep Quality</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={1}>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{duration}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{paceStringToSeconds(pace)}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{heartRate}</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{effort} / 10</td>
                                                                <td style={{fontSize:'18px', padding:'10px', color:'#454545',borderBottom: '1px solid #ddd'}}>{sleepQuality} / 10</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                                        <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                                        <Button className="form-next-button" onClick={()=>{}}>Submit Review</Button>
                                                    </div>
                                                </div>
                                            )}

                                            {/* STAGE 6 */}
                                            {reviewStage === 6 && (
                                                <div className="form-stage">
                                                    <h2 style={{ fontSize: '2.75vw', fontWeight: 'bold', fontStyle:'italic', color:colorPalette.steelBlue}}>SUBMITTED!</h2>
                                                    <h1 className="single-line-text" style={{fontSize:'1.75vw'}}>We'll Update Your Plan Accordingly!</h1>
                                                    {/* <p>{`Your TTS based on FTHR was ${getTTSFromFTHR()}`}</p> */}
                                                    {/* <p>{`Your optimal TTS was ${getTTSFromActivity(reviewingActivity)}`}</p> */}
                                                    <div>
            
                                                    <button className="form-next-button" onClick={()=>{}}>Back To Dashboard</button>
                                                    </div>
                                                </div>
            
                                            )}
                                        </div>
                                </div>
                                )
                        ) : (
                            <div style={{display:'flex', flexDirection:'column', width:'100%', height:'100%', gap:'2vh', marginTop:0}}>

                            <div style={{ flex: 1, width: '100%', maxWidth:'100vw',height: '90%' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable-grid" direction="horizontal">
                                {(provided) => (
                                <ul
                                    className="custom-grid-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {next7Days
                                    .map((day: VitovaDay, index: number) => ({ day, id: index.toString() }))
                                    .map((dayItem, index) => (
                                        <Draggable
                                        isDragDisabled={isDayInThePast(dayItem.day) || isDayComplete(dayItem.day)}
                                        key={dayItem.id}
                                        draggableId={dayItem.id}
                                        index={index}
                                        >
                                        {(provided, snapshot) => (
                                            <li
                                            onClick={() => {setSelectedActivityIndex(0);setSelectedDay(dayItem.day)}}
                                            className={`custom-grid-item ${snapshot.isDragging ? 'dragging' : ''} ${
                                                isDayInThePast(dayItem.day) || isDayComplete(dayItem.day)
                                                ? 'disabled'
                                                : ''
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                backgroundColor:
                                                snapshot.isDragging && !isDayInThePast(dayItem.day) && !isDayComplete(dayItem.day)
                                                    ? 'lightgrey'
                                                    : '',
                                            }}
                                            >
                                            <div className="custom-card">
                                                {/* Icon Section */}
                                                <div
                                                className="custom-card-icon-section"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                    height:'100%'
                                                }}
                                                >
                                                <div
                                                    style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    gap: '10px',
                                                    }}
                                                >
                                                    {dayItem.day.activities.filter(a=>a.compulsory).map((activity:VitovaActivity, idx:number) => (
                                                    <React.Fragment key={idx}>
                                                        {/* Icon */}
                                                        <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                        >
                                                        <FontAwesomeIcon
                                                            style={{
                                                            color: isDayInTheFuture(dayItem.day)
                                                                ? '#2f3b4a' :
                                                                isDayComplete(dayItem.day)? 
                                                                colorPalette.mediumSeaGreen
                                                                : colorPalette.softRed,
                                                            fontSize: dayItem.day.activities.filter(a=>a.compulsory).length <= 1 ? '3em' : '2em',
                                                            marginLeft:
                                                                dayItem.day.activities.filter(a=>a.compulsory).length > 0 && idx === 0 ? '5px' : '0px',
                                                            marginRight: idx === 1 ? '0px' : '5px',
                                                            }}
                                                            icon={activity.steps.length > 0 ? (activity.steps[0].type === VitovaStepType.CARDIO && activity.steps[0].cardio?.exercise ?
                                                                exerciseIcons[activity.steps[0].cardio.exercise.name]
                                                                : 
                                                                activityIcons[activity.steps[0].type]) : faEllipsis}
                                                        />
                                                        </div>

                                                        {/* Separator */}
                                                        {idx < dayItem.day.activities.filter(a=>a.compulsory).length - 1 && (
                                                        <div
                                                            style={{
                                                            width: '2px',
                                                            height: '4em',
                                                            backgroundColor: '#ccc',
                                                            }}
                                                        />
                                                        )}
                                                    </React.Fragment>
                                                    ))}
                                                </div>
                                                </div>

                                                {/* Content Section */}
                                                <div className="custom-card-content" style={{justifyContent:'flex-end', display:'flex', height:'100%'}}>
                                                <div style={{ color: '#2f3b4a' }}>
                                                    <div
                                                    style={{
                                                        fontSize: '1.2em',
                                                        fontWeight: 'bold',
                                                    }}
                                                    >
                                                    {getDayOfWeek(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(dayItem.day),
                                                        next7Days.indexOf(dayItem.day)
                                                    )}
                                                    </div>
                                                    <div
                                                    style={{
                                                        fontSize: '1em',
                                                    }}
                                                    >
                                                    {getCalendarDay(
                                                        currentUser.userPlanStartDate.toString(),
                                                        getWeekNumber(dayItem.day),
                                                        next7Days.indexOf(dayItem.day)
                                                    )}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </li>
                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                                )}
                            </Droppable>
                            </DragDropContext>
                            </div>
                            <div style={{flex:3,width:'100%'}}>

                            <div style={{display:'flex', width:'100%', height:'100%', flexDirection:'row', alignItems:'flex-start'}}>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:colorPalette.steelBlue, marginTop: '0px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic'}}>{`TRAINING LOAD FOR WEEK ${getWeekNumber(next7Days[0])+1}`}</label>
                                            {/* <GeneralChartComponent mh={80} mw={100} 
                                            chartData={next7Days.map(day=>getDayStressScore(day, currentUser))} 
                                            labelData={next7Days.map((d:VitovaDay)=>getCalendarDay(currentUser.userPlanStartDate.toString(), getWeekNumber(d), next7Days.indexOf(d)).toString())} 
                                            user={currentUser}></GeneralChartComponent> */}
                                            <ComparisonChartComponent mh={80} mw={100} 
                                            plannedData={next7Days.map(day=>getDayStressScore(day, currentUser, false))} 
                                            realData={getUserReviewsGroupedIntoDays().map(day=>getDayStressScore(day, currentUser, true))}
                                            labelData={next7Days.map((d:VitovaDay)=>getCalendarDay(currentUser.userPlanStartDate.toString(), getWeekNumber(d), next7Days.indexOf(d)).toString())} 
                                            user={currentUser}></ComparisonChartComponent>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#e7e7e7', flexDirection:'column', alignItems:'center'}}>
                                            <div style={{display:'flex', width:'100%'}}>
                                            <label style={{color:colorPalette.steelBlue, marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold', fontStyle:'italic'}}>{`WEEK ${getWeekNumber(next7Days[0])+1} BREAKDOWN`}</label>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Session Duration`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getAverageSessionDuration(next7Days)} Minutes`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Average Session Effort`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{`${getAverageSessionRPE(next7Days)}/10`}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Cardio Sessions`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{getNumberOfSessions(VitovaStepType.CARDIO, next7Days)}</label>
                                                </div>
                                            </div>
                                            <div style={{backgroundColor:`white`, width:'95%',margin:'8px 0px', borderRadius:'5px', flexDirection:'row', display:'flex'}}>
                                                <div style={{display:'flex',  width:'100%', flex:2}}>
                                                    <label style={{fontSize:'1vw', color:`#454545`, backgroundColor:'#d1d1d1',textAlign:'left', padding:'5px 15px', fontWeight:'bold'}}>{`Strength/Conditioning Sessions`}</label>
                                                </div>
                                                <div style={{display:'flex',  width:'100%', flex:1, justifyContent:'flex-end'}}>
                                                    <label
                                                        style={{fontSize:'1vw', color:`#454545`, textAlign:'right', marginRight:'6px', padding:'5px 5px', border:'none', outline:'none', marginTop:0}}
                                                        className="card-title">{getNumberOfSessions(VitovaStepType.STRENGTH, next7Days)}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            </div>

                        )}
                    </div>
                    {/* RIGHT PANEL */}
                    <div style={{overflowY:'scroll', overflowX:'hidden'}} className={`calendar-container ${showRightPanel ? 'show' : ''}`}>
                        {selectedTab === 'endreport' &&
                            // <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            //     <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>END OF PLAN REPORT</h2>
                            //     <button style={{backgroundColor:colorPalette.steelBlue}} onClick={() => generateOutcomeLetter(currentUser)}>
                            //         Download Document
                            //     </button>
                            // </div>
                            <EndOfPlanReport currentUser={currentUser}/>
                        }
                        {selectedTab === 'tests' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                                <div  style={{display:'flex', flexDirection:'row'}}>
                                    <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'TEST CENTRE'}</label>
                                        <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1vw'}}>{"It's best to work with your fitness professional in the planning stage to look at which tests are most important for you to track. But if you want to fill in more then more information is positive."}</label>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                textAlign: 'center',
                                                marginTop:'2vh'
                                            }}
                                            >
                                            <thead style={{marginTop:'2vh'}}>
                                                <tr style={{ borderBottom: '2px solid #ccc' }}>
                                                <th>Test</th>
                                                <th>Type</th>
                                                <th>Latest</th>
                                                <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {currentUser.tests.map((userTest: any, index: number) => ( */}
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Weight</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.weightData.length > 0 ? `${currentUser.weightData[currentUser.weightData.length-1].value.toString()}KG` :'Not Submitted'}</td>
                                                    <td>
                                                    <button
                                                        style={{
                                                            padding: '5px 10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: colorPalette.steelBlue,
                                                            color: '#fff',
                                                            width: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            transition: 'background-color 0.3s ease',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = 'steelblue')}
                                                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = colorPalette.steelBlue)}
                                                        onClick={() => setIsModalOpen(true)}
                                                    >
                                                        UPDATE
                                                    </button>

                                                        {isModalOpen && (
                                                            <Dialog open={isModalOpen} fullWidth maxWidth={'md'} onClose={() => setIsModalOpen(false)}>
                                                            <DialogTitle>Physical Measurements</DialogTitle>
                                                            <DialogContent>
                                                                <TextField 
                                                                    type="number" 
                                                                    value={weight} 
                                                                    onChange={(e) => setWeight(e.target.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest weight..."
                                                                    margin="dense"
                                                                />
                                                                <TextField 
                                                                    type="number" 
                                                                    value={waist} 
                                                                    onChange={(e) => setWaistCircumference(e.target.value)} 
                                                                    fullWidth 
                                                                    placeholder="Enter your latest waist circumference..."
                                                                    margin="dense"
                                                                />
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleSubmitModalValue} variant="contained" color="primary">Submit</Button>
                                                                <Button onClick={() => setIsModalOpen(false)} variant="outlined">Cancel</Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                        )}
                                                    </td>
                                                </tr>
                                                {/* {currentUser.tests.map((userTest: any, index: number) => ( */}
                                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                                    <td>Waist Circumference</td>
                                                    <td>Physical</td>
                                                    <td>{currentUser.waistData.length > 0 ? `${currentUser.waistData[currentUser.waistData.length-1].value.toString()}CM` :'Not Submitted'}</td>
                                                    <td>
                                                    {/* <button
                                                        style={{
                                                            padding: '5px 10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: colorPalette.steelBlue,
                                                            color: '#fff',
                                                            width: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            transition: 'background-color 0.3s ease',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = 'steelblue')}
                                                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = colorPalette.steelBlue)}
                                                        onClick={() => setIsModalOpen(true)}
                                                    >
                                                        Submit Another
                                                    </button> */}
                                                    </td>
                                                </tr>
                                                {/* ))} */}
                                            </tbody>
                                            </table>

                                    </div>
                                </div>
                            </div>
                        }
                        {selectedTab === 'checkin' &&
                            <Box sx={{ width: '100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif' }}>
                                <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '2vh' }}>
                                    SEND A CHECK-IN MESSAGE
                                </Typography>
                                <Box sx={{ marginBottom: '10px' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '5px' }}>SUBJECT</Typography>
                                    <TextField 
                                        fullWidth
                                        id="subject"
                                        placeholder="Enter subject here..."
                                        value={subject}
                                        onChange={(e) => setSubject(e.target.value)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '5px' }}>MESSAGE</Typography>
                                    <TextField 
                                        fullWidth
                                        id="message"
                                        placeholder="Write your message here..."
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                    />
                                </Box>
                                <Button 
                                    fullWidth
                                    variant="contained"
                                    sx={{ backgroundColor: colorPalette.steelBlue, color: '#fff' }}
                                    onClick={handleSend}
                                    disabled={sendingMessage}
                                >
                                    {sendingMessage ? `Sending...` : `Send Message`}
                                </Button>
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontStyle: 'italic', marginTop: '20px' }}>
                                    {currentUser.inbox.length > 0 ? `SENT MESSAGES` : `SENT MESSAGES WILL APPEAR HERE`}
                                </Typography>
                                {currentUser.inbox
                                    .sort((m, n) => new Date(n.dateSent).getTime() - new Date(m.dateSent).getTime())
                                    .filter(m => m.senderId === currentUser._id)
                                    .map((message, index) => (
                                        <Paper key={index} sx={{ padding: '10px', marginTop: '10px', position: 'relative', border: '1px solid gray', justifyContent:'flex-start', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                                            <Typography variant="caption">{message.dateSent.toString()}</Typography>
                                            <Typography variant="caption" sx={{ position: 'absolute', right: '5px' }}>
                                                {message.acknowledged ? 'Acknowledged By Your GP' : 'Waiting For GP Acknowledgement'}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{message.subject}</Typography>
                                            <Typography variant="body2">{message.content}</Typography>
                                        </Paper>
                                    ))}
                            </Box>
                        }                  
                        {selectedTab === 'smoking' &&
                            <div style={{width:'100%',maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                                <div  style={{display:'flex', flexDirection:'row'}}>
                                <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                    
                                    <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.smoker ? 'SMOKING TRACKER' : 'GREAT WORK'}</label>
                                    <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1vw'}}>{currentUser.smoker ? 'Track Your Smoking Habits Through Our Check-In System' : 'Nothing To See Here, Continue To Avoid Smoking'}</label>
                                    {currentUser.smoker &&
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px',}}>
                                            {!hasSubmittedSmokingDataToday() &&
                                                <label 
                                                    style={{ 
                                                    fontStyle: 'italic', 
                                                    fontWeight: 'bold', 
                                                    color: colorPalette.steelBlue, 
                                                    fontSize: '1vw' 
                                                    }}
                                                >
                                                    HOW MANY CIGARETTES DID YOU SMOKE TODAY?
                                                </label>
                                            }
                                            {!hasSubmittedSmokingDataToday() &&
                                            <input 
                                                type="number" 
                                                disabled={hasSubmittedSmokingDataToday()}
                                                min={0}
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1vw', 
                                                width: '5vw', 
                                                borderRadius: '5px', 
                                                border: 'none',
                                                textAlign:'center',
                                                outline:'none', 
                                                height: '40px', // Consistent height
                                                }}
                                                value={cigaretteInput}
                                                onChange={(e) => setCigaretteInput(Number(e.currentTarget.value))}
                                            />
                                            }
                                            <button 
                                                // type="submit" 
                                                style={{ 
                                                borderRadius: '5px', 
                                                height: '40px', // Same height as input
                                                padding: '0 10px', // Adjust padding for consistent size
                                                fontSize: '1vw',
                                                margin:0,
                                                width:'auto',
                                                fontStyle:'italic',
                                                fontWeight:'bold',
                                                backgroundColor:colorPalette.steelBlue
                                                }} 
                                                className="submit-button"
                                                onClick={() => submitSmokingValue()}
                                                disabled={hasSubmittedSmokingDataToday()}
                                                title={
                                                    hasSubmittedSmokingDataToday()
                                                    ? "You have already submitted smoking data for today."
                                                    : ""
                                                }
                                            >
                                                {hasSubmittedSmokingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                            </button>
                                            </div>
                                            <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                            <div style={{ height: '100%', display: 'flex', marginTop: '1vh' }}>
                                                {currentUser.smokingData && 
                                            <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                {/* Header with days of the week */}
                                                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px' }}>
                                                {currentUser.smokingData.slice(-7)
                                                    .sort((a, b) => {
                                                        const dateA = new Date(a.date);
                                                        const dateB = new Date(b.date);

                                                        // Ensure both dates are valid
                                                        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                                                        return 0; // In case the date is invalid, you can adjust how you handle the sorting
                                                        }

                                                        return dateA.getTime() - dateB.getTime();  // Use getTime() to compare timestamps
                                                    })
                                                    .map((o: DataObject, index) => {
                                                        return (
                                                            <span key={index} style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', minWidth: '40px', maxWidth:'40px',marginLeft:index===0?'165px':'0'}}>
                                                                {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                            </span>
                                                        );
                                                    })}
                                                </div>

                                                {/* Row for title and circles */}
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {/* Title for units drunk */}
                                                    <span style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>Cigarettes Smoked</span>

                                                    {/* Circles representing number of drinks */}
                                                    <div style={{ display: 'flex', gap: '20px' }}>
                                                    {currentUser.smokingData.slice(-7)
                                                        .sort((a, b) => {
                                                            const dateA = new Date(a.date);
                                                            const dateB = new Date(b.date);

                                                            // Ensure both dates are valid
                                                            if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
                                                            return 0; // In case the date is invalid, you can adjust how you handle the sorting
                                                            }

                                                            return dateA.getTime() - dateB.getTime();  // Use getTime() to compare timestamps
                                                        })
                                                        .map((o: DataObject, index) => {
                                                        let backgroundColor = getSmokingScoreColor(currentUser, Number(o.value)) // Default value

                                                        return (
                                                            <div
                                                            key={index}
                                                            onClick={() => {
                                                                if (Number(o.value) < 0) {
                                                                const value = prompt('Enter your retrospective value:');
                                                                if (value !== null) {
                                                                    submitRetrospectiveSmokingValue(value, o.date);
                                                                }
                                                                }
                                                            }}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: backgroundColor,
                                                                display: 'flex',
                                                                opacity: '0.7',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                cursor: Number(o.value) < 0 ? 'pointer' : 'default',
                                                            }}
                                                            >
                                                            </div>
                                                        );
                                                    })}

                                                    </div>

                                                </div>
                                                <Line 
                                                    data={{
                                                        labels: currentUser.smokingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                // label: "Your Data",
                                                                data: currentUser.smokingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Makes the line dashed (5px dash and 5px gap)
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },

                                                        ],
                                                    }}
                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: false, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '10px' }}
                                                />
                                                </div>

                                                }
                                            </div>

                                        </div>
                                    }

                                </div>
                                <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>PREFERENCES</label>
                                    <div style={{alignItems: 'center'}}>
                                        <label>I Don't Smoke</label>
                                        <Checkbox
                                        disabled={loadingSmokerChange}
                                        checked={!currentUser.smoker}
                                        onChange={()=>handleSmokerChange()}
                                        style={{color:'#454545', padding:0, marginLeft:'5px'}}/>

                                    </div>
                                    <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS RESOURCE LINKS</label>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/find-your-local-stop-smoking-service/">Find Your Local Smoking Service</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/">Quit Smoking</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.england.nhs.uk/2024/11/nhs-rolls-out-stop-smoking-pill-to-help-tens-of-thousands-quit/">Stop Smoking Pill</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://111.wales.nhs.uk/lifestylewellbeing/smoking/">Wellbeing Impact</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhsinform.scot/healthy-living/stopping-smoking/reasons-to-stop/benefits-of-stopping-smoking/">Reasons To Stop</a>
                                    <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS INFORMATION LINKS</label>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.livelifebetterderbyshire.org.uk/latest-news/quitting-vs-cutting-down.aspx ">Quitting VS Cutting</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.hdft.nhs.uk/wp-content/uploads/2022/06/47189-NHS-My-Quitting-2pp-A5_v2.pdf">What Happens When You Stop Smoking?</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/ready-to-quit-smoking/find-the-best-stop-smoking-products-for-you/">Stop Smoking Products</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhsinform.scot/healthy-living/stopping-smoking/reasons-to-stop/benefits-of-stopping-smoking/">Benefits Of <b>Stopping</b> Smoking</a>
                                    <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} href="https://www.nhs.uk/better-health/quit-smoking/why-quit-smoking/benefits-of-quitting-smoking/">Benefits Of <b>Quitting</b> Smoking</a>   
                                </div>
                                </div>
                            </div>
                        }
                        {selectedTab === 'alcohol' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{currentUser.drinker ? 'ALCOHOL TRACKER' : 'GREAT WORK'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1vw'}}>{currentUser.drinker ? 'Track Your Alcohol Consumption Habits Through Our Check-In System' : 'Nothing To See Here, Continue To Limit Your Alcohol Consumption'}</label>
                                {currentUser.drinker &&
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px',}}>
                                        {!hasSubmittedDrinkingDataToday() &&
                                            <label 
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1vw' 
                                                }}
                                            >
                                                HOW MANY UNITS DID YOU DRINK TODAY?
                                            </label>
                                        }
                                        {!hasSubmittedDrinkingDataToday() &&
                                        <input 
                                            type="number" 
                                            disabled={hasSubmittedDrinkingDataToday()}
                                            min={0}
                                            style={{ 
                                            fontStyle: 'italic', 
                                            fontWeight: 'bold', 
                                            color: colorPalette.steelBlue, 
                                            fontSize: '1vw', 
                                            width: '5vw', 
                                            borderRadius: '5px', 
                                            border: 'none',
                                            textAlign:'center',
                                            outline:'none', 
                                            height: '40px', // Consistent height
                                            }}
                                            value={alcoholInput}
                                            onChange={(e) => setAlcoholInput(Number(e.currentTarget.value))}
                                        />
                                        }
                                        <button 
                                            // type="submit" 
                                            style={{ 
                                            borderRadius: '5px', 
                                            height: '40px', // Same height as input
                                            padding: '0 10px', // Adjust padding for consistent size
                                            fontSize: '1vw',
                                            margin:0,
                                            width:'auto',
                                            fontStyle:'italic',
                                            fontWeight:'bold',
                                            backgroundColor:colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitAlcoholValue()}
                                            disabled={hasSubmittedDrinkingDataToday()}
                                            title={
                                                hasSubmittedDrinkingDataToday()
                                                ? "You have already submitted alcohol data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedDrinkingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                        </div>
                                        <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>

                                        <div style={{ height: '100%', display: 'flex', marginTop: '2vh' }}>
                                            {currentUser.drinkingData &&                                             
                                            <div style={{ width: '97%', height: '50vh', backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                                {/* PICTOGRAM */}
                                                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '10px' }}>
                                                    {currentUser.drinkingData.slice(-7).map((o: DataObject, index) => {
                                                        return (
                                                            <span key={index} style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', minWidth: '40px', maxWidth:'40px',marginLeft:index===0?'110px':'0'}}>
                                                                {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {/* Title for units drunk */}
                                                    <span style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>Units Drunk</span>

                                                    {/* Circles representing number of drinks */}
                                                    <div style={{ display: 'flex', gap: '20px' }}>
                                                    {currentUser.drinkingData.slice(-7).map((o: DataObject, index) => {
                                                        // Assuming the original value is 5 and the value should decrease by 10% each day
                                                        const originalValue = 5; // Example starting value
                                                        const expectedValue = originalValue * (1 - 0.1 * index); // 10% reduction per day

                                                        let backgroundColor = ''; // Default value

                                                        // Determine the background color based on the value comparison
                                                        if (Number(o.value) < 0){
                                                            backgroundColor = '#34343466'
                                                        } else if (Number(o.value) < expectedValue) {
                                                            // Green: Value is lower than expected
                                                            backgroundColor = colorPalette.seaGreen;
                                                        } else if (Number(o.value) <= expectedValue * 1.05) {
                                                            // Orange: Value is between expected and 1.05 times the expected
                                                            backgroundColor = colorPalette.orange;
                                                        } else {
                                                            // Red: Value is more than 1.05 times the expected
                                                            backgroundColor = colorPalette.softRed;
                                                        }

                                                        return (
                                                            <div
                                                            key={index}
                                                            onClick={() => {
                                                                if (Number(o.value) < 0) {
                                                                const value = prompt('Enter your retrospective value:');
                                                                if (value !== null) {
                                                                    submitRetrospectiveAlcoholValue(value, o.date);
                                                                }
                                                                }
                                                            }}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor: backgroundColor,
                                                                display: 'flex',
                                                                opacity: '0.7',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                cursor: Number(o.value) < 0 ? 'pointer' : 'default',
                                                            }}
                                                            >
                                                            </div>
                                                        );
                                                    })}

                                                    </div>
                                                </div>

                                                <Line 
                                                    data={{
                                                        labels: currentUser.drinkingData.map((o: DataObject) =>
                                                            new Intl.DateTimeFormat("en-GB", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "2-digit",
                                                            }).format(new Date(o.date))
                                                        ),
                                                        datasets: [
                                                            {
                                                                label: "Your Data",
                                                                data: currentUser.drinkingData.map((o: DataObject) => 
                                                                    o.value >= 0 ? o.value : null // Plot only values >= 0
                                                                ),
                                                                borderColor: "steelblue", // Blue line for user's data
                                                                borderWidth: 2,
                                                                fill: false,
                                                                borderDash: [], // Solid line
                                                                spanGaps: true, // Skip gaps in the user's data
                                                            },
                                                            {
                                                                label: "Recommendeded Decrease (10% Every 4 Days)",
                                                                data: currentUser.drinkingData.map((o: DataObject, index: number) => {
                                                                    // Starting value, e.g., 100 (you can adjust this as needed)
                                                                    let initialValue = currentUser.drinkingData.length > 0 ? Number(currentUser.drinkingData[0].value) : 0;
                                                                    let decreasedValue = initialValue * Math.pow((1-0.026), index);
                                                                    return decreasedValue > 2 ? decreasedValue : 2;
                                                                }),
                                                                borderColor: `${colorPalette.seaGreen}`,
                                                                borderWidth: 2,
                                                                pointRadius: 0,
                                                                borderDash: [5, 5], // Dashed line
                                                                fill: false,
                                                                spanGaps: true,
                                                            },
                                                        ],
                                                    }}

                                                    options={{
                                                        animation: {
                                                            duration: 200,
                                                        },
                                                        scales: {
                                                            y: {
                                                                type: 'linear',
                                                                beginAtZero: true,
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                            x: {
                                                                ticks: {
                                                                    display: true,
                                                                },
                                                                grid: {
                                                                    display: false,
                                                                },
                                                            },
                                                        },
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            legend: {
                                                                display: true, // Display legend to differentiate the two lines
                                                            },
                                                        },
                                                        layout: {
                                                            padding: {
                                                                top: 10,
                                                                bottom: 10,
                                                                left: 10,
                                                                right: 10,
                                                            },
                                                        },
                                                    }}
                                                    style={{ marginTop: '30px' }}
                                                />




                                            </div>

                                            }
                                        </div>

                                    </div>
                                }

                            </div>
                            
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>PREFERENCES</label>

                            <div style={{alignItems: 'center'}}>
                                    <label>I Don't Drink</label>
                                    <Checkbox
                                    disabled={loadingDrinkerChange}
                                    checked={!currentUser.drinker}
                                    onChange={()=>handleDrinkerChange()}
                                    style={{color:'#454545', padding:0, marginLeft:'5px'}}/>

                                </div>
                                <label style={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', marginTop: '10px' }}>
                                    RECOMMENDED DECREASE
                                </label>
                                <p style={{ textAlign: 'justify', fontSize: '0.95em' }}>
                                    {`It's important when cutting down your alcohol intake to do so in a slow, specific manner to minimise the risk of alcohol withdrawal. The risks of alcohol withdrawal can be severe, leading to shakes, seizures and even death in severe circumstances.`}
                                </p>
                                <p style={{ textAlign: 'justify', fontSize: '0.95em' }}>
                                    {`Our graph here shows you the recommended reduction in alcohol intake (a 10% reduction every 4 days). Getting you down to the maximum recommended alcohol intake of 14 units per week, or 2 units per day.`}
                                </p>

                                {/* Recommended Decrease Table */}
                                <div style={{  textAlign: 'center' }}>
                                    <table style={{ margin: '0 auto', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '10px', border: '1px solid #ccc' }}>Date</th>
                                                {/* Calculate next 5 days starting from today */}
                                                {Array.from({ length: 5 }).map((_, index) => {
                                                    const startDate = new Date(); // First date in data
                                                    startDate.setDate(startDate.getDate() + index); // Add index days to the start date
                                                    return (
                                                        <th key={index} style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                            {formatDateWithOrdinal(startDate)}
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '10px', border: '1px solid #ccc' }}>Units</td>
                                                {/* Calculate the units for each of the next 5 days */}
                                                {Array.from({ length: 5 }).map((_, index) => {
                                                    const startDate = new Date(currentUser.drinkingData.length > 0 ? currentUser.drinkingData[0].date : 0); // First date in data
                                                    const initialValue = Number(currentUser.drinkingData.length > 0 ? currentUser.drinkingData[0].value : 0);
                                                    
                                                    // Calculate the number of days since the start date
                                                    const currentDate = new Date();
                                                    const diffInTime = currentDate.getTime() - startDate.getTime();
                                                    const daysElapsed = Math.floor(diffInTime / (1000 * 3600 * 24));

                                                    // Calculate the decreased value based on 2.6% per day
                                                    const decreasedValue = initialValue * Math.pow(1 - 0.026, daysElapsed + index) < 2 ? 2 : initialValue * Math.pow(1 - 0.026, daysElapsed + index);

                                                    return (
                                                        <td key={index} style={{ padding: '10px', border: '1px solid #ccc' }}>
                                                            {Math.max(decreasedValue, 2).toFixed(2)} {/* Ensure value does not go below 2 */}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                               <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>NHS RESOURCE LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhs.uk/live-well/alcohol-advice/alcohol-support/">Alcohol Advice</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.england.nhs.uk/ourwork/prevention/alcohol-dependency-programme/">Alcohol Dependency Programme</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.gmmh.nhs.uk/advice-for-people-who-use-alcohol/ ">Advice For People Who Consume Alcohol</a>
                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>OTHER INFORMATION AND AA LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.alcoholics-anonymous.org.uk/">Alcoholics Anonymous</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.al-anonuk.org.uk/">Another Alcoholics Anonymous</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.smartrecovery.org.uk/">Smart Recovery</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.wearewithyou.org.uk/">We Are With You</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="https://alcoholchange.org.uk/help-and-support/get-help-now​">Get Help And Support</a>
                            </div>
                            </div>
                        </div>
                        }
                        {selectedTab === 'exercises' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{marginBottom: '10px', padding:'20px',display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                    <DatabaseComponentView 
                                        onExerciseSelected={(exercise) => setEditingExercise(exercise)} 
                                        user={currentUser} />
                                </div>
                                {editingExercise ?
                                    <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                        <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>{`PREFERENCES FOR ${editingExercise?.name}`}</label>
                                        <div style={{ width: '100%',display: 'flex', alignItems: 'center', padding:0, marginTop:'10px'}}>
                                            <Checkbox 
                                                style={{color:'#454545', padding:0, margin:0, width:'50px', height:'50px'}}/>
                                            <label style={{color:colorPalette.steelBlue, padding:0, marginLeft:'5px'}}>I Have Trouble Doing This Exercise</label>
                                        </div>
                                    </div>
                                :
                                    <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388',height:'70vh', flexDirection:'column',alignItems:'center', justifyContent:'center'}}>
                                        <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{`SELECT AN EXERCISE TO START`}</label>
                                    </div>
                                }

                            </div>
                        </div>
                        }
                        {selectedTab === 'sleep' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'SLEEP TRACKER'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1vw'}}>{'Track Your Sleeping Habits Through Our Check-In System'}</label>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>      
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px',}}>
                                        {!hasSubmittedSleepingDataToday() &&
                                            <label 
                                                style={{ 
                                                fontStyle: 'italic', 
                                                fontWeight: 'bold', 
                                                color: colorPalette.steelBlue, 
                                                fontSize: '1vw' 
                                                }}
                                            >
                                                HOW MANY HOURS OF SLEEP DID YOU GET LAST NIGHT?
                                            </label>
                                        }
                                        {!hasSubmittedSleepingDataToday() &&
                                        <input 
                                            type="number" 
                                            disabled={hasSubmittedSleepingDataToday()}
                                            min={0}
                                            style={{ 
                                            fontStyle: 'italic', 
                                            fontWeight: 'bold', 
                                            color: colorPalette.steelBlue, 
                                            fontSize: '1vw', 
                                            width: '5vw', 
                                            borderRadius: '5px', 
                                            border: 'none',
                                            textAlign:'center',
                                            outline:'none', 
                                            height: '40px', // Consistent height
                                            }}
                                            value={sleepingInput}
                                            onChange={(e) => setSleepingInput(Number(e.currentTarget.value))}
                                        />
                                        }
                                        <button 
                                            // type="submit" 
                                            style={{ 
                                            borderRadius: '5px', 
                                            height: '40px', // Same height as input
                                            padding: '0 10px', // Adjust padding for consistent size
                                            fontSize: '1vw',
                                            margin:0,
                                            width:'auto',
                                            fontStyle:'italic',
                                            fontWeight:'bold',
                                            backgroundColor:colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitSleepingValue()}
                                            disabled={hasSubmittedSleepingDataToday()}
                                            title={
                                                hasSubmittedSleepingDataToday()
                                                ? "You have already submitted sleep data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedSleepingDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                        </div>
                                        <div style={{ height: '100%', display: 'flex', marginTop: '6vh' }}>
                                            {currentUser.sleepingData && 
                                            <div style={{ width: '97%', height:'50vh', backgroundColor:'transparent'}}>
                                                <Bar
                                                data={{
                                                    labels: currentUser.sleepingData.map((o: DataObject) =>
                                                    new Intl.DateTimeFormat("en-GB", {
                                                        day: "2-digit",
                                                        month: "2-digit",
                                                        year: "2-digit",
                                                    }).format(new Date(o.date))
                                                    ),
                                                    datasets: [
                                                    {
                                                        label: "Hours Slept",
                                                        data: currentUser.sleepingData.map((o: DataObject) => o.value), // Use only the values for the data points
                                                        backgroundColor: currentUser.sleepingData.map((o: DataObject) =>
                                                        getSleepQualityColor(currentUser, Number(o.value))
                                                        ),
                                                        borderColor: currentUser.sleepingData.map((o: DataObject) =>
                                                        getSleepQualityColor(currentUser, Number(o.value))
                                                        ),
                                                        borderWidth: 1,
                                                    },
                                                    ],
                                                }}
                                                options={{
                                                    animation: {
                                                    duration: 200,
                                                    },
                                                    scales: {
                                                    y: {
                                                        type: "linear", // specify the scale type
                                                        beginAtZero: true,
                                                        ticks: {
                                                        display: true,
                                                        },
                                                        grid: {
                                                        display: false,
                                                        },
                                                    },
                                                    x: {
                                                        ticks: {
                                                        display: true, // hide the x-axis ticks
                                                        },
                                                        grid: {
                                                        display: false,
                                                        },
                                                    },
                                                    },
                                                    maintainAspectRatio: false, // prevent the chart from maintaining aspect ratio
                                                    responsive: true, // make the chart responsive
                                                    plugins: {
                                                    legend: {
                                                        display: false,
                                                        position: "top", // adjust legend position as needed
                                                    },
                                                    },
                                                    layout: {
                                                    padding: {
                                                        top: 10,
                                                        bottom: 10,
                                                        left: 10,
                                                        right: 10,
                                                    },
                                                    },
                                                    onClick: (event, elements) => {
                                                    if (elements.length > 0) {
                                                        const element = elements[0]; // The clicked element
                                                        const datasetIndex = element.datasetIndex; // Index of the dataset clicked
                                                        const dataIndex = element.index; // Index of the data point clicked

                                                        // Get the value and date of the clicked bar
                                                        const value = currentUser.sleepingData[dataIndex]?.value;
                                                        const date = currentUser.sleepingData[dataIndex]?.date;

                                                        if (value === -1) {
                                                        const newValue = prompt(
                                                            "Enter your retrospective sleeping value:"
                                                        );
                                                        if (newValue !== null) {
                                                            submitRetrospectiveSleepingValue(newValue, date);
                                                        }
                                                        }
                                                    }
                                                    },
                                                    onHover: (event: any, elements: Array<{ index: number }>) => {
                                                    if (event.native) {
                                                        if (elements.length > 0) {
                                                        const element = elements[0]; // The hovered element
                                                        const dataIndex = element.index; // Index of the data point hovered
                                                        const value = currentUser.sleepingData[dataIndex]?.value;

                                                        // Change cursor to pointer if value is -1
                                                        event.native.target.style.cursor = value === -1 ? "pointer" : "default";
                                                        } else {
                                                        event.native.target.style.cursor = "default";
                                                        }
                                                    }
                                                    },
                                                }}
                                                />



                                            </div>
                                            }
                                        </div>

                                </div>

                            </div>
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px',borderLeft:'1px solid #a3a3a388', paddingLeft:'15px',height:'70vh', flexDirection:'column',alignItems:'flex-start', justifyContent:'flex-start'}}>
                                <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>NHS RESOURCE LINKS</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.mysouthernhealth.com/wp-content/uploads/2015/09/09_15_I_PowerofSleep-copy.jpg">Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.nhs.uk/every-mind-matters/mental-health-issues/sleep">Sleep and Mental Health</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.gosh.nhs.uk/conditions-and-treatments/procedures-and-treatments/sleep-hygiene-children/">Children and Young People Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhs.uk/every-mind-matters/mental-wellbeing-tips/how-to-fall-asleep-faster-and-sleep-better/">Children and Young People Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.nhsborders.scot.nhs.uk/media/213568/sleep.pdf ">Tips For Better Sleep</a>

                                <label style={{ textAlign: 'left',  fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>FURTHER READING</label>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.ageuk.org.uk/information-advice/health-wellbeing/mind-body/getting-a-good-nights-sleep">Getting A Good Night Sleep</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.mentalhealth.org.uk/explore-mental-health/publications/how-sleep-better">Mental Health And Sleep</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.headspace.com/sleep/sleep-hygiene">Sleep Hygiene</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.aasm.org/resources/pdf/products/howtosleepbetter_web.pdf">How To Sleep better</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="http://www.sleepeducation.org/healthy-sleep/healthy-sleep-habits​">Healthy Sleep Habits</a>
                                <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left',color:'steelblue',fontStyle:'italic'}} 
                                    href="https://www.sleep.com/sleep-health/how-many-hours-of-sleep ​">How Many Hours Of Sleep?</a>
                            </div>
                            </div>
                        </div>
                        }
                        {selectedTab === 'nutrition' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                            {/* <h2 style={{ textAlign: 'center', marginBottom: '2vh', fontWeight:'bold', fontStyle:'italic'}}>SMOKING</h2> */}
                            <div  style={{display:'flex', flexDirection:'row'}}>
                            <div style={{marginBottom: '10px', display:'flex',flex:3,alignItems:'flex-start', justifyContent:'flex-start', flexDirection:'column'}}>                                
                                <label style={{ textAlign: 'center', fontWeight:'bold', fontStyle:'italic'}}>{'DIET TRACKER'}</label>
                                <label style={{ textAlign: 'center', marginBottom: '5px', fontStyle:'italic', fontSize:'1vw'}}>{'Track Your Dietary Habits Through Our Check-In System'}</label>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
                                    <label style={{ textAlign: 'left', fontWeight: 'bold', fontStyle: 'italic', marginTop:'10px'}}>{hasSubmittedNutritionDataToday() ? `SUBMITTED, SEE YOU TOMORROW!` : `DAILY NUTRITION CHECKLIST`}</label>
                                    {!hasSubmittedNutritionDataToday() && 
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    {nutritionInput.map((q, index) => (
                                        <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center', padding: 0 }}>
                                            <Checkbox 
                                                checked={q.checked} 
                                                disabled={hasSubmittedNutritionDataToday()}
                                                onChange={() => handleCheckboxChange(index)}
                                                style={{ color: '#454545', padding: 0, margin: 0 }}
                                            />
                                            <label style={{ color: '#4682B4', padding: 0, marginLeft: '5px' }}>{q.label}</label>
                                        </div>
                                    ))}
                                    </div>
                                    }
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px', marginTop: '20px' }}>
                                        <button 
                                            style={{ 
                                                borderRadius: '5px', 
                                                height: '40px', 
                                                padding: '0 10px', 
                                                fontSize: '1vw',
                                                margin: 0,
                                                width: 'auto',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                backgroundColor: colorPalette.steelBlue
                                            }} 
                                            className="submit-button"
                                            onClick={() => submitNutritionData()}
                                            disabled={hasSubmittedNutritionDataToday()}
                                            title={
                                                hasSubmittedNutritionDataToday()
                                                ? "You have already submitted diet data for today."
                                                : ""
                                            }
                                        >
                                            {hasSubmittedNutritionDataToday() ? 'SUBMITTED FOR TODAY' : 'SUBMIT FOR TODAY'}
                                        </button>
                                    </div>
                                </div>
                                <div style={{height:'1px', backgroundColor:'gray',opacity:'0.4', width:'97%', marginTop:'10px', marginBottom:'10px'}}></div>
                                <div style={{ width: '97%', height: '50vh', marginTop:'1vh',backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'flex-start'}}>
                                {/* PICTOGRAM */}
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', width: '100%' }}>
                                    {/* Title for checklist score */}
                                    {/* <span style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>Checklist Score</span> */}

                                    {/* Table structure */}
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        {/* Header row: Days across the top */}
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                                            <div style={{ width: '40.5%', textAlign: 'left' }}></div> {/* Empty cell for labels */}
                                            {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        width: '50px', // Ensure alignment with circle widths
                                                        display: 'inline-block', // Prevent stretching
                                                    }}
                                                >
                                                    {`${formatAsShortDayName(o.date)} ${formatDateWithOrdinal(o.date)}`}
                                                </span>
                                            ))}
                                        </div>


                                        {/* Body rows: Labels on the left, circles in columns */}
                                        {currentUser.nutritionData[0]?.value.map((checkbox, labelIndex) => (
                                            <div key={labelIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                                                {/* Label */}
                                                <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>{checkbox.label}</div>

                                                {/* Circles for each day */}
                                                {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, dayIndex) => {
                                                    const isChecked = o.value[labelIndex]?.checked;

                                                    return (
                                                        <div
                                                            key={dayIndex}
                                                            style={{
                                                                width: '40px',
                                                                height: '40px',
                                                                borderRadius: '50%',
                                                                backgroundColor:o.value.length <= 0 ? '#34343466' : (isChecked
                                                                    ? colorPalette.seaGreen
                                                                    : colorPalette.softRed),
                                                                marginLeft: '10px',
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ))}

                                        {/* Average row */}
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                                            <div style={{ width: '40%', fontSize: '14px', fontWeight: 'bold', textAlign:'left'}}>Average</div>
                                            {currentUser.nutritionData.slice(-7).map((o: ChecklistDataObject, index) => {
                                                const checkedCount = o.value.filter(v => v.checked).length;

                                                let backgroundColor = '';
                                                if (o.value.length <= 0){
                                                    backgroundColor = '#34343466';
                                                } else if (checkedCount > 3) {
                                                    backgroundColor = colorPalette.seaGreen;
                                                } else if (checkedCount > 1) {
                                                    backgroundColor = colorPalette.orange;
                                                } else {
                                                    backgroundColor = colorPalette.softRed;
                                                }

                                                return (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: '40px',
                                                            height: '40px',
                                                            borderRadius: '50%',
                                                            backgroundColor: backgroundColor,
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>



                            </div>


                            
                            </div>

   
                            
                            <div style={{display:'flex', flex:1, borderLeftWidth:'1px', borderLeft:'1px solid #a3a3a388', paddingLeft:'15px', height:'70vh', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start'}}>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic'}}>NHS RESOURCE LINKS</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.nhs.uk/better-health/lose-weight">NHS services for weight management</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.nhs.uk/live-well/eat-well/food-guidelines-and-food-labels/the-eatwell-guide/">Dietary advice regarding healthy eating</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.england.nhs.uk/digital-weight-management">NHS Digital Weight Management Programme</a>
                            <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>WEIGHT MANAGEMENT SERVICES IN DEVON</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://myhealth-devon.nhs.uk/index.php/my-condition/obesity">Weight management services in Devon</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.best-you.org/">BestYou App (provided by ICE Creates)</a>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.preventing-diabetes.co.uk/locations/devon/">Healthier You NHS Diabetes Prevention Programme</a>
                           <label style={{ textAlign: 'left', fontWeight:'bold', fontStyle:'italic', marginTop:'10px'}}>WIDER READING</label>
                            <a target='_blank' rel="noreferrer" style={{textDecoration:'underline', textAlign:'left', color:'steelblue', fontStyle:'italic'}} 
                                href="https://www.optifast.co.uk/">Low Calorie Diet Programmes (e.g., Optifast)</a>
                            </div>

                            </div>
                        </div>
                        }
                        {selectedTab === 'goals' &&
                            <div style={{width:'100%', maxWidth: '100%', fontFamily: 'Arial, sans-serif'}}>
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                    >
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                        fontStyle: 'italic',
                                        fontSize: '1.5vw',
                                        marginBottom: '10px',
                                        }}
                                    >
                                        {'GOALS TRACKER'}
                                    </label>
                                    <label
                                        style={{
                                        textAlign: 'left',
                                        marginBottom: '20px',
                                        fontStyle: 'italic',
                                        fontSize: '1vw',
                                        color: '#555',
                                        }}
                                    >
                                        {'Track Your Goals Through Our Check-In System'}
                                    </label>
                                    <table
                                        style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        textAlign: 'center',
                                        }}
                                    >
                                        <thead>
                                        <tr style={{ borderBottom: '2px solid #ccc' }}>
                                            <th>#</th>
                                            <th>Specific</th>
                                            <th>Measurable</th>
                                            <th>Achievable</th>
                                            <th>Relevant</th>
                                            <th>Time-Bound</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentUser.goals &&
                                            currentUser.goals.map((goal, index) => (
                                            <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                                <td>{index+1}</td>
                                                <td>{goal.content.specific}</td>
                                                <td>{goal.content.measurable}</td>
                                                <td>{goal.content.achievable}</td>
                                                <td>{goal.content.relevant}</td>
                                                <td>{goal.content.timeBound}</td>
                                                <td>
                                                {loadingRemoveGoal === goal ? 
                                                    <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        margin: 'auto',
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        border: '3px solid #ccc',
                                                        borderTop: `3px solid ${colorPalette.softRed}`,
                                                        borderRadius: '50%',
                                                        animation: 'spin 1s linear infinite',
                                                        }}
                                                    ></div>
                                                    </div>
                                                    :
                                                    <FontAwesomeIcon icon={faMinus}
                                                        onClick={()=>loadingRemoveGoal !== undefined ? {} : handleRemoveGoal(goal)}
                                                        style={{
                                                            padding: '5px 10px',
                                                            cursor: 'pointer',
                                                            backgroundColor: colorPalette.softRed,
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            marginTop:'5px',
                                                            width:'15px',
                                                            height:'15px',
                                                            transition: 'background-color 0.3s ease',
                                                            fontWeight: 'bold',
                                                        }}
                                                        onMouseOver={(e) =>
                                                            (e.currentTarget.style.backgroundColor = colorPalette.vibrantRed)
                                                        }
                                                        onMouseOut={(e) =>
                                                            (e.currentTarget.style.backgroundColor = colorPalette.softRed)
                                                        }
                                                    ></FontAwesomeIcon>
                                                }

                                            </td>
                                            </tr>
                                            ))}
                                        <tr style={{ backgroundColor: '#f9f9f9' }}>
                                        <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%'}}>
                                                <input
                                                type="text"
                                                value={currentUser.goals.length+1}
                                                onChange={(e) => handleInputChange('specific', e.target.value)}
                                                placeholder="Specific"
                                                disabled
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onMouseOver={(e) =>
                                                    (e.currentTarget.style.opacity = '1')
                                                }
                                                onMouseOut={(e) =>
                                                    (e.currentTarget.style.opacity = '0.9')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.specific}
                                                onChange={(e) => handleInputChange('specific', e.target.value)}
                                                placeholder="Specific"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.measurable}
                                                onChange={(e) => handleInputChange('measurable', e.target.value)}
                                                placeholder="Measurable"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.achievable}
                                                onChange={(e) => handleInputChange('achievable', e.target.value)}
                                                placeholder="Achievable"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.relevant}
                                                onChange={(e) => handleInputChange('relevant', e.target.value)}
                                                placeholder="Relevant"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div style={{ position: 'relative', textAlign: 'center', width: '100%' }}>
                                                <input
                                                type="text"
                                                value={newGoal.timeBound}
                                                onChange={(e) => handleInputChange('timeBound', e.target.value)}
                                                placeholder="Time-Bound"
                                                style={{
                                                    width: '90%',
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: 'transparent',
                                                    color: colorPalette.steelBlue,
                                                    fontWeight: 'bold',
                                                    fontStyle: 'italic',
                                                    textAlign: 'center',
                                                    borderBottom: '1px solid #ccc',
                                                }}
                                                onFocus={(e) =>
                                                    (e.currentTarget.style.borderBottom = `2px solid ${colorPalette.steelBlue}`)
                                                }
                                                onBlur={(e) =>
                                                    (e.currentTarget.style.borderBottom = '1px solid #ccc')
                                                }
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            {loadingAddGoal ? 
                                                    <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        margin: 'auto',
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        border: '3px solid #ccc',
                                                        borderTop: `3px solid ${colorPalette.mediumSeaGreen}`,
                                                        borderRadius: '50%',
                                                        animation: 'spin 1s linear infinite',
                                                        }}
                                                    ></div>
                                                    </div>
                                                    :
                                                <FontAwesomeIcon icon={faPlus}
                                                    onClick={()=>loadingAddGoal ? {} :handleSubmitModalValue()}
                                                    style={{
                                                        padding: '5px 10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: colorPalette.mediumSeaGreen,
                                                        color: '#fff',
                                                        border: 'none',
                                                        borderRadius: '5px',
                                                        marginTop:'5px',
                                                        width:'15px',
                                                        height:'15px',
                                                        transition: 'background-color 0.3s ease',
                                                        fontWeight: 'bold',
                                                    }}
                                                    onMouseOver={(e) =>
                                                        (e.currentTarget.style.backgroundColor = colorPalette.seaGreen)
                                                    }
                                                    onMouseOut={(e) =>
                                                        (e.currentTarget.style.backgroundColor = colorPalette.mediumSeaGreen)
                                                    }
                                                ></FontAwesomeIcon>
                                            }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>

            </div>
        );
        }else{
            return(
                <div className="dashboard-page">
                    <div className="main-content">
                        <Spinner text="Adapting Your Plan!"/>
                    </div>
                </div>
            )

        }
    }else{
        return (
            <div className="dashboard-page">
                <div className="main-content">
                    <h1 style={{fontSize:'1.5em', marginBottom:'20px', fontWeight:'bold', color:'#454545'}}>You haven't set a plan yet</h1>
                    <Link to={'/plans'} className="review-button" style={{fontSize:'1.5vw', width:'auto'}}>Go To Plans</Link>
                </div>
            </div>
        )
    }

}

export default VitovaDashboardPageLoggedInView;