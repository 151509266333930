import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import profileImage from '../svg-icons/profile.svg'; // Import the profile image
import '../styles/Header.css';
import { User } from '../models/user';
import * as Api from "../network/api";
import colorPalette from '../utils/colors';

interface HeaderProps {
    user: User | null,
    onLogoutSuccessful: () => void,
}

const Header = ({user, onLogoutSuccessful}: HeaderProps) => {
    const [dropdowns, setDropdowns] = useState({
        plans: false,
        more: false,
        profile: false,
    });

    const location = useLocation();  // Get the current URL

    const handleMouseEnter = (menu: string) => {
        setDropdowns(prevState => ({
            ...prevState,
            [menu]: true,
        }));
    };

    const handleMouseLeave = (menu: string) => {
        setDropdowns(prevState => ({
            ...prevState,
            [menu]: false,
        }));
    };

    const closeAllDropdowns = () => {
        setDropdowns({
            plans: false,
            more: false,
            profile: false,
        });
    };

    async function logout() {
        closeAllDropdowns();
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    async function resetUserData(){
        if (user){
            let newUser:User = user;
            newUser.info.complete = false;
            // newUser.currentPlan = null
            await Api.updateUser(user._id, newUser);

        }
    }

    const isHomePage = location.pathname === "/";  // Check if the current URL is "/"
    
    return (
        <header className="header" style={{backgroundColor:isHomePage && !user ? 'transparent':'white', boxShadow:isHomePage && !user ?'none':'0 4px 8px rgba(0, 0, 0, 0.1)'}}>
            <div className="left-section">
            <a 
                href={user ? "/home" : "/"} 
                style={{
                display: 'inline-block', // Ensures the image is properly clickable
                textDecoration: 'none', // Removes any default text decorations for the link
                }}
            >
                <img 
                style={{
                    height: '15px',
                    marginLeft: '10px',
                    objectFit: 'cover',
                    marginBottom: '5px',
                    filter: isHomePage && !user ? 'brightness(0) saturate(100%) invert(100%)' : '',

                }}
                src={"/png/headerlogo.png"}
                alt="Logo" 
                />
            </a>
                {/* <Link 
                    className='home-logo' 
                    to={user ? "/home" : "/"} 
                    style={{ 
                        fontStyle: 'italic', 
                        color: isHomePage && !user ? 'white'  : 'steelblue'  // Set text color to white if on home page
                    }}
                >
                    VITOVA
                </Link> */}
            </div>

            <nav className="nav">
                <ul>
                    {user && user.info &&
                        <li className="nav-item">
                            <Link 
                                to={user.info ? "/dashboard" : "/home"} 
                                style={{ color: isHomePage && !user ? 'white' : 'inherit' }}  // Conditionally set the color
                            >
                                Dashboard
                            </Link>
                        </li>
                    }
                    {user && user.info && user.email.includes('@vitovaltd.com') &&
                    <li className="nav-item">
                        <Link 
                            to={"/plans"} 
                            style={{ color: isHomePage && !user ? 'white' : 'inherit' }}  // Conditionally set the color
                        >
                            Plans
                        </Link>
                    </li>
                    // <li className="nav-item">
                    //     <div
                    //         className="dropdown-trigger"
                    //         onMouseEnter={() => handleMouseEnter('plans')}
                    //         onMouseLeave={() => handleMouseLeave('plans')}
                    //     >
                    //         <Link 
                    //             to="/plans" 
                    //             style={{ 
                    //                 paddingLeft: `${dropdowns.plans ? '5px' : '7px'}`,
                    //                 color: isHomePage && !user ? 'white' : 'inherit'  // Conditionally set the color
                    //             }} 
                    //             className={`${dropdowns.plans ? `dropdown-option-active`:``}`}
                    //         >
                    //             Plans
                    //         </Link>

                    //         <FontAwesomeIcon 
                    //             style={{color: `${dropdowns.plans ? 'steelBlue':'#555555'}`}} 
                    //             className='dropdown-icon' 
                    //             icon={faChevronDown} 
                    //         />
                    //         {dropdowns.plans && (
                    //             <ul className="dropdown-menu">
                    //                 <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Obesity</Link></li>
                    //                 <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Anxiety</Link></li>
                    //                 <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Depression</Link></li>
                    //             </ul>
                    //         )}
                    //     </div>
                    // </li>
                    }
                    {!user &&
                        <li className="nav-item">
                            <Link to="/about" style={{ color: isHomePage && !user ? 'white' : 'inherit' }}>Our Story</Link>
                        </li>
                    }
                    {!user && 
                        <li className="nav-item">
                            <Link to="/blog" style={{ color: isHomePage && !user ? 'white' : 'inherit' }}>Blog</Link>
                        </li>
                    }
                    {!user && 
                        <li className="nav-item">
                            <Link to="/contact" style={{ color: isHomePage && !user ? 'white' : 'inherit' }}>Investment</Link>
                        </li>
                    }
                    {user && user.info && user.email.includes('@vitovaltd.com') &&
                    <li className="nav-item">
                        <Link to="/workoutbuilder" style={{ color: isHomePage && !user ? 'white' : 'inherit' }}>Plan Builder</Link>
                    </li>
                    }
                    {user && user.info && user.email.includes('@vitovaltd.com') &&
                    <li className="nav-item">
                        <Link to="/database" style={{ color: isHomePage && !user ? 'white' : 'inherit' }}>VitovaDB</Link>
                    </li>
                    }
                </ul>
            </nav>
            <div className="right-section">
                <div className="profile-menu">
                    {user && user.info &&
                        <div
                        className="dropdown-trigger"
                        onMouseEnter={() => handleMouseEnter('profile')}
                        onMouseLeave={() => handleMouseLeave('profile')}
                        >
                        <img src={profileImage} alt="Profile" className="profile-icon" />
                        <FontAwesomeIcon className='dropdown-icon' icon={faChevronDown} />
                        {dropdowns.profile && (
                            <ul className="dropdown-menu profile-dropdown">
                                <li><Link className='dropdown-option' to={`/profile/${user._id}`} onClick={closeAllDropdowns}>Profile</Link></li>
                                <li><Link className='dropdown-option' to="/preferences" onClick={closeAllDropdowns}>Manage Profile</Link></li>
                                {/* <li><Link className='dropdown-option' to="/connect" onClick={closeAllDropdowns}>Connect To Strava</Link></li> */}
                                {/* <li><Link className='dropdown-option' to="/dashboard" onClick={resetUserData}>Reset User Data</Link></li> */}
                                <li><Link className='dropdown-option' onClick={logout} to={'/'}>Logout</Link></li>
                            </ul>
                        )}
                    </div>
                    }
                    {user && user.GPAccount &&
                        <div
                        className="dropdown-trigger"
                        onMouseEnter={() => handleMouseEnter('profile')}
                        onMouseLeave={() => handleMouseLeave('profile')}
                        >
                        <img src={profileImage} alt="Profile" className="profile-icon"/>
                        <FontAwesomeIcon className='dropdown-icon' icon={faChevronDown}/>
                        {dropdowns.profile && (
                            <ul className="dropdown-menu profile-dropdown">
                                <li><Link className='dropdown-option' onClick={logout} to={'/'}>Logout</Link></li>
                            </ul>
                        )}
                    </div>
                    }
                </div>
            </div>
        </header>
    );
}

export default Header;
